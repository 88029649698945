import React, { forwardRef, ForwardRefRenderFunction, useState } from 'react'
import { Box, Input, Menu, MenuButton, MenuList, MenuItem, Button, SelectProps, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react'

interface OptionsProps {
  value: string | number
  label: string
}

interface CustomProps extends SelectProps {
  name: string
  label?: string
  errors?: any
  options: OptionsProps[]
  labelStyle?: any
  title: string
  extraFunction: (data?: any) => any
}

const BaseSelect: ForwardRefRenderFunction<HTMLSelectElement, CustomProps> = (
  { name, label, title, errors = null, extraFunction, options, labelStyle, ...rest },
  ref
) => {
  const [searchValue, setSearchValue] = useState('')
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase())
  )

  return (
    <FormControl id={name} isInvalid={!(errors == null)}>
    {!!label && <FormLabel color="wine.primary" style={labelStyle}>{label}</FormLabel>}

    <Box width="100%">
      <Menu>
        <MenuButton as={Box}>
          <Button as="button">
            {title}
          </Button>
        </MenuButton>
        <MenuList width="100%">

          <Input
            size="lg"
            bg="white"
            variant="filled"
            border="1px"
            borderColor="wine.500"
            _hover={{ bgColor: 'white' }}
            _focus={{ bgColor: 'white' }}
            _placeholder={{ color: 'gray.300' }}
            focusBorderColor="wine.500"
            color="wine.primary"
            placeholder="Pesquisar..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            mb={4}
          />
          {filteredOptions.map((option) => (
            <MenuItem key={option.value} onClick={() => extraFunction(option.value)}>
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
    {!(errors == null) && <FormErrorMessage>{errors.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const SelectWithSearch = forwardRef(BaseSelect)
