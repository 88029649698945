/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Spinner,
  useToast,
  VStack,
  Text
} from '@chakra-ui/react'
import { v4 as uuid } from 'uuid'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TextArea } from '../../Components/Form/TextArea'
import { DragAndDrop } from '../../Components/Form/DragAndDrop'
import { RiAddLine } from 'react-icons/ri'
import { TiDeleteOutline } from 'react-icons/ti'
import { SelectWithSearch } from '../../Components/Form/SelectWithSearch'
import { MoneyInput } from '../../Components/Form/MoneyInput'
import { getCurrencyValue } from '../../utils/getCurrencyValue'

interface createUserProps {
  index?: number
  name: string
  email: string
  born: string
  phone: string
  gender: string
  gym_id: string
  password?: string
  zip_code?: string
  street?: string
  number?: string
  complement?: string
  neighborhood?: string
  city?: string
  state?: string
  cpf?: string
}

export interface OptionsProps {
  label: string
  value: string
}

interface ItemsSelected {
  id: string
  label: string
  value: string
}

interface ItemsForPaymentsOptions {
  id: string
  options: OptionsProps[]

}

const createUserFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string()
    .email('Preencha um e-mail válido')
    .required('E-mail é obrigatório'),
  born: Yup.string().required('Data de nascimento é obrigatório'),
  phone: Yup.string().required('Telefone é obrigatório'),

  password: Yup.string(),
  password_confirmation: Yup.string().oneOf(
    [null, Yup.ref('password')],
    'As senhas devem ser iguais'
  ),
  gym_id: Yup.string().required('Selecione uma unidade'),
  zip_code: Yup.string(),
  street: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a Rua')
    }),
  number: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha o número')
    }),
  neighborhood: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a bairro')
    }),
  city: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a cidade')
    }),
  state: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha o Estado')
    })
})

const updateUserFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  login: Yup.string().required('Login é obrigatório'),
  password: Yup.string(),
  password_confirmation: Yup.string().oneOf(
    [null, Yup.ref('password')],
    'As senhas devem ser iguais'
  ),
  gym_id: Yup.string().required('Selecione uma unidade')
})

const optionsUnits = [
  {
    value: 'subway',
    label: 'Subway'
  },
  {
    value: 'mcdonalds',
    label: 'MC Donalds'
  },
  {
    value: 'madero',
    label: 'Madero'
  }
]

interface ItemProposalProps {
  id: string
  executor_value: string
  executor_id: string
  service_id: string
  installments: string
  ticket_value: string
  expenses_value: string
}

interface ServicesProps {
  id: string
  cod: string
  name: string
  service: string
  value: string
  installments: number
}

const PreProposalCreate: FC = () => {
  const [loading, setLoading] = useState(false)

  const [showAddressComponent, setShowAddressComponent] = useState(false)
  // const [items, setItems] = useState<ItemProps[]>([])

  const params = useParams()
  const location = useLocation()
  const toast = useToast()
  const navigation = useNavigate()
  const [collaborators, setCollaborators] = useState<OptionsProps[]>([])
  const [technicalManager, setTechnicalManagerSelected] = useState<OptionsProps>()
  const [financialManager, setFinancialManagerSelected] = useState<OptionsProps>()
  const [nets, setNets] = useState<OptionsProps[]>([])
  const [netSelected, setNetSelected] = useState<OptionsProps>()

  const [units, setUnits] = useState<OptionsProps[]>([])
  const [unitSelected, setUnitSelected] = useState<OptionsProps>()

  const [providers, setProviders] = useState<OptionsProps[]>([])
  const [providerSelected, setProviderSelected] = useState<OptionsProps>()

  const [services, setServices] = useState<OptionsProps[]>([])
  const [serviceSelected, setServiceSelected] = useState<OptionsProps>()

  const [payments, setPayments] = useState<OptionsProps[]>([])
  const [servicesWithPayments, setServicesWithPayments] = useState<ServicesProps[]>([])

  const [paymentsOptions, setPaymentsOptions] = useState<ItemsForPaymentsOptions[]>([])

  // juncao de todos os dados
  const [providersSelected, setProvidersSelected] = useState<ItemsSelected[]>([])
  const [servicesSelected, setServicesSelected] = useState<ItemsSelected[]>([])
  const [paymentsSelected, setPaymentsSelected] = useState<ItemsSelected[]>([])

  const [items, setItems] = useState<ItemProposalProps[]>([])

  const { register, handleSubmit, formState, setValue, unregister } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      console.log('values: ', values)
      console.log('items: ', items)

      if (!technicalManager?.value) {
        toast({
          title: 'Selecione o responsável técnico',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })

        return
      }

      if (!financialManager?.value) {
        toast({
          title: 'Selecione o responsável financeiro',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })

        return
      }

      if (!netSelected?.value) {
        toast({
          title: 'Selecione a rede',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })

        return
      }

      if (!unitSelected?.value) {
        toast({
          title: 'Selecione a unidade',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })

        return
      }

      if (!items?.length) {
        toast({
          title: 'Preencha pelo menos 1 item de proposta',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })

        return
      }

      const payload = {
        technical_manager: technicalManager?.value,
        financial_manager: financialManager?.value,
        net_id: netSelected?.value,
        unit_id: unitSelected?.value,
        items: items.map(i => {
          const existsValue = values[`name-item-executor_value-${i.id}`]
          const existsTicketValue = values[`name-item-ticket_value-${i.id}`]
          const existsExpensesValue = values[`name-item-expenses_value-${i.id}`]
          return {
            executor_value: existsValue ? getCurrencyValue(values[`name-item-executor_value-${i.id}`]) : '',
            executor_id: providersSelected?.find(item => item.id === i.id)?.value ?? '',
            service_id: servicesSelected?.find(item => item.id === i.id)?.value ?? '',
            installments: paymentsSelected?.find(item => item.id === i.id)?.value ?? '',
            ticket_value: existsTicketValue ? getCurrencyValue(values[`name-item-ticket_value-${i.id}`]) : '',
            expenses_value: existsExpensesValue ? getCurrencyValue(values[`name-item-expenses_value-${i.id}`]) : ''
          }
        })
      }

      console.log('payload: ', payload)

      const { id } = params

      try {
        id
          ? await api.put(`/proposal/${id}`, payload)
          : await api.post('/proposal', payload)

        toast({
          title: `${id ? 'Editado' : 'Cadastrado'} com sucesso`,
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
        navigation('/pre-proposal')
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    },
    [toast, items, technicalManager, financialManager, netSelected, unitSelected, paymentsSelected, servicesSelected, providersSelected, navigation, params]
  )

  // const handleDeleteOption = useCallback((option: OptionProps) => {
  //   const item = items.findIndex(i => i.id === option.item_id)

  //   const newArry = [...items]

  //   const currentItems = newArry[item].items

  //   newArry[item].items = currentItems !== null ? currentItems?.filter(i => i.id !== option.id) : []

  //   setItems([...newArry])
  // }, [items])

  const loadCollaborators = useCallback(async () => {
    try {
      const { data } = await api.get('/collaborators', {
        params: {
          pag: 1,
          all: true
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setCollaborators(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const loadNets = useCallback(async () => {
    try {
      const { data } = await api.get('/nets', {
        params: {
          pag: 1,
          all: true
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setNets(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const loadProviders = useCallback(async () => {
    try {
      const { data } = await api.get('/providers', {
        params: {
          pag: 1,
          all: true,
          status: 0
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setProviders(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const loadServices = useCallback(async () => {
    try {
      const { data } = await api.get('/services', {
        params: {
          pag: 1,
          all: true
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setServices(normalize)
      setServicesWithPayments(data?.data)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSelectTechnicalManager = useCallback((collaborator_id: string) => {
    const collaborator = collaborators.find(i => i.value === collaborator_id)

    if (collaborator) {
      setTechnicalManagerSelected(collaborator)
    }
  }, [collaborators])

  const handleSelectFinancialManager = useCallback((collaborator_id: string) => {
    const collaborator = collaborators.find(i => i.value === collaborator_id)

    if (collaborator) {
      setFinancialManagerSelected(collaborator)
    }
  }, [collaborators])

  const loadUnits = useCallback(async (net_id: string) => {
    try {
      const { data } = await api.get('/units', {
        params: {
          pag: 1,
          all: true,
          net_id
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setUnits(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSelectNet = useCallback((net_id: string) => {
    const net = nets.find(i => i.value === net_id)
    setUnitSelected({} as OptionsProps)
    if (net) {
      setNetSelected(net)
    }
  }, [nets])

  const handleSelectUnit = useCallback((unit_id: string) => {
    const unit = units.find(i => i.value === unit_id)

    if (unit) {
      setUnitSelected(unit)
    }
  }, [units])

  useEffect(() => {
    if (netSelected?.value) {
      loadUnits(netSelected?.value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netSelected])

  const handleSelectProvider = useCallback((provider_id: string, item_id: string) => {
    const provider = providers.find(i => i.value === provider_id)

    if (provider) {
      // setProviderSelected(provider)

      const filtered = providersSelected.filter(p => p.id !== item_id)

      const payload = {
        id: item_id,
        label: provider.label,
        value: provider.value

      }
      setProvidersSelected([...filtered, payload])
    }
  }, [providers, providersSelected])

  const handleSelectService = useCallback((service_id: string, item_id: string) => {
    const service = services.find(i => i.value === service_id)

    if (service) {
      // setServiceSelected(service)
      const filtered = servicesSelected.filter(p => p.id !== item_id)

      const payload = {
        id: item_id,
        label: service.label,
        value: service.value

      }
      setServicesSelected([...filtered, payload])
    }

    // alterando as opções de pagamentos

    // pegando todas as opcoes deste servico
    const serviceWithAllPayments = servicesWithPayments.find(i => i.id === service_id)

    if (serviceWithAllPayments) {
      // normatizando os dados para o select
      const normalize = []

      for (let i = 1; i <= serviceWithAllPayments.installments; i++) {
        normalize.push({
          value: String(i),
          label: String(i)
        })
      }

      console.log('normalize: ', normalize)
      console.log('service_id: ', service_id)

      const payload = {
        id: item_id,
        options: normalize
      }

      const filtered = paymentsOptions.filter(p => p.id !== item_id)
      console.log('filtered: ', filtered)
      setPaymentsOptions([...filtered, payload])
    }
  }, [services, servicesWithPayments, servicesSelected, paymentsOptions])

  const handleSelectPayment = useCallback((payment_id: string, item_id: string) => {
    const getPayments = paymentsOptions.find(i => i.id === item_id)

    if (getPayments) {
      // setProviderSelected(provider)
      const payment = getPayments.options.find(i => i.value === payment_id)

      if (payment) {
        const filtered = paymentsSelected.filter(p => p.id !== item_id)

        const payload = {
          id: item_id,
          label: payment.label,
          value: payment.value

        }
        setPaymentsSelected([...filtered, payload])
      }
    }
  }, [paymentsSelected, paymentsOptions])

  const handleNewItem = useCallback(() => {
    const payload = {
      id: uuid(),
      executor_value: '',
      executor_id: '',
      service_id: '',
      installments: '',
      ticket_value: '',
      expenses_value: ''
    }
    setItems(prev => [...prev, payload])
  }, [])

  const handleDeleteItem = useCallback((id: string) => {
    const newArray = items.filter(i => i.id !== id)
    unregister(`name-item-${id}`)
    setItems([...newArray])
  }, [items, unregister])

  const loadItem = useCallback(async (proposal_id: string) => {
    try {
      const { data } = await api.get(`/proposal/${proposal_id}`)
      console.log('proposal: ', data)
      // toast({
      //   title: `${proposal_id ? 'Editado' : 'Cadastrado'} com sucesso`,
      //   position: 'top',
      //   isClosable: true,
      //   status: 'success',
      //   variant: 'solid'
      // })
      handleSelectTechnicalManager(data?.technical_manager)
      handleSelectFinancialManager(data?.financial_manager)
      handleSelectNet(data?.net_id)
      handleSelectUnit(data?.unit_id)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, handleSelectTechnicalManager, handleSelectUnit, handleSelectFinancialManager, handleSelectNet])

  const loadAllContent = useCallback(async () => {
    await loadCollaborators()
    await loadNets()
    await loadProviders()
    await loadServices()

    if (params.proposal_id) {
      loadItem(params?.proposal_id)
    }
  }, [loadCollaborators, loadNets, loadProviders, loadServices, loadItem, params])

  useEffect(() => {
    loadAllContent()
  }, [params?.proposal_id])

  // useEffect(() => {
  //   if (params.proposal_id) {
  //     loadItem(params?.proposal_id)
  //   }
  // }, [params])

  return (
    <Box>
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
            onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="wine.primary">
            Criar nova Pré-Proposta
          </Heading>
          <Divider my="6" borderColor="wine.primary" />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Dados Cadastrais
          </Heading>

          <VStack spacing={['6', '8']}>

            {/* <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Nº Proposta</Text>
                  <Text align="center">2023-0005</Text>
                </Box>

            </SimpleGrid> */}

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                <SelectWithSearch
                  label="Responsável Técnico"
                  title={technicalManager?.value ? 'Trocar Responsável' : 'Selecionar Responsável'}
                  options={collaborators}
                  extraFunction={handleSelectTechnicalManager}
                  {...register('technical_manager')}

                />

                {technicalManager?.value && (
                  <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Responsável Técnico</Text>
                  <Text align="center">{technicalManager.label}</Text>
                </Box>
                )}

            </SimpleGrid>

            <Divider />

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                <SelectWithSearch
                  label="Responsável Financeiro"
                  title={financialManager?.value ? 'Trocar Responsável' : 'Selecionar Responsável'}
                  options={collaborators}
                  extraFunction={handleSelectFinancialManager}
                  {...register('financial_manager')}

                />

                {financialManager?.value && (
                  <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Responsável Financeiro</Text>
                  <Text align="center">{financialManager.label}</Text>
                </Box>
                )}

            </SimpleGrid>

            <Divider />

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                <SelectWithSearch
                  label="Rede"
                  title={netSelected?.value ? 'Trocar Rede' : 'Selecionar Rede'}
                  options={nets}
                  extraFunction={handleSelectNet}
                  {...register('net_id')}

                />

                {netSelected?.value && (
                  <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Rede</Text>
                  <Text align="center">{netSelected.label}</Text>
                </Box>
                )}

            </SimpleGrid>

            <Divider />

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                <SelectWithSearch
                  label="Unidade"
                  title={unitSelected?.value ? 'Trocar Unidade' : 'Selecionar Unidade'}
                  options={units}
                  extraFunction={handleSelectUnit}
                  {...register('unit_id')}

                />

                {unitSelected?.value && (
                  <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Unidade</Text>
                  <Text align="center">{unitSelected.label}</Text>
                </Box>
                )}

            </SimpleGrid>

            <Divider />

          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
          Items da Proposta
          </Heading>

          {!items?.length && <Text mb="10" color="wine.primary">Você ainda não possui nenhum item de proposta cadastrado.</Text>}

          {items.map((item, indexItem: number) => (
              <VStack spacing={['9', '9']} mb="5" bg="wine.primary" p="10" key={item.id} borderRadius="8px">
                  <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                      <SelectWithSearch
                        label="Executante"
                        title={providersSelected?.find(i => i.id === item.id) ? 'Trocar Executante' : 'Selecionar Executante'}
                        options={providers}
                        extraFunction={(e: any) => handleSelectProvider(e, item.id)}
                        labelStyle={{ color: 'white' }}
                        {...register(`name-item-executor_id-${item.id}`)}

                      />

                      {providersSelected?.find(i => i.id === item.id) && (
                        <Box>
                        <Text color="white.primary" fontWeight={500} align="center">Executante</Text>
                        <Text align="center" color="white.primary">{providersSelected?.find(i => i.id === item.id)?.label}</Text>
                      </Box>
                      )}

                  </SimpleGrid>

                  <Divider color="white" />

                  <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                      <SelectWithSearch
                        label="Serviço"
                        title={servicesSelected?.find(i => i.id === item.id) ? 'Trocar Serviço' : 'Selecionar Serviço'}
                        options={services}
                        extraFunction={(e: any) => handleSelectService(e, item.id)}
                        labelStyle={{ color: 'white' }}
                        {...register(`name-item-service_id-${item.id}`)}

                      />

                      {servicesSelected?.find(i => i.id === item.id) && (
                        <Box>
                        <Text color="white.primary" fontWeight={500} align="center">Serviço</Text>
                        <Text align="center" color="white.primary">{servicesSelected?.find(i => i.id === item.id)?.label}</Text>
                      </Box>
                      )}

                  </SimpleGrid>

                  <Divider color="white" />

                  <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                      <SelectWithSearch
                        label="Parcelamento do Serviço"
                        title={paymentsSelected?.find(i => i.id === item.id) ? 'Troce Parcelamento do Serviço' : 'Selecionar Parcelamento do Serviço'}
                        options={paymentsOptions?.find(i => i.id ===
                           item.id)?.options ?? []}
                        extraFunction={(e: any) => handleSelectPayment(e, item.id)}
                        labelStyle={{ color: 'white' }}
                        {...register(`name-item-installments-${item.id}`)}

                      />

                      {paymentsSelected?.find(i => i.id === item.id) && (
                        <Box>
                        <Text color="white.primary" fontWeight={500} align="center">Parcelamento</Text>
                        <Text align="center" color="white.primary">{paymentsSelected?.find(i => i.id === item.id)?.label}x</Text>
                      </Box>
                      )}

                  </SimpleGrid>

                  <Divider color="white" />

                  <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

                  <MoneyInput
                  labelStyle={{ color: '#fff' }}
                  label="Valor do Prestador"
                  placeholder="Preencha o valor"
                  // errors={errors.name}
                  {...register(`name-item-executor_value-${item.id}`)}
                  />

<MoneyInput
                  labelStyle={{ color: '#fff' }}
                  label="Locomoção/Passagem"
                  placeholder="Preencha o valor"
                  // errors={errors.name}
                  {...register(`name-item-ticket_value-${item.id}`)}
                  />

<MoneyInput
                  labelStyle={{ color: '#fff' }}
                  label="Despesas"
                  placeholder="Preencha o valor"
                  // errors={errors.name}
                  {...register(`name-item-expenses_value-${item.id}`)}
                  />

                </SimpleGrid>

                {/* <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
                <Input
                  labelStyle={{ color: '#fff' }}
                    label="Item Proposta"
                    placeholder="Preencha o Item"
                    // errors={errors.name}
                    {...register(`name-item-${item.id}`)}
                  />

                    <Input
                    labelStyle={{ color: '#fff' }}
                      label="Valor Prestador"
                      placeholder="Preencha o valor"
                      // errors={errors.name}
                      {...register(`value-item-${item.id}`)}
                    />

                    <Select
                  labelStyle={{ color: '#fff' }}
                    label="Forma de pagamento"
                    placeholder="Selecionar forma"
                    // errors={errors.gender}
                    {...register(`executante-item-${item.id}`)}
                    options={optionsUnits}
                    />
                </SimpleGrid> */}

                {/* <Button
                  width="100%"
                  as="button"
                  size="lg"
                  fontSize="sm"
                  bg="wine.200"
                  mb="10"
                  _hover={{ bgColor: 'gray.500' }}
                  color="white.primary"
                  leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                  onClick={() => handleNewOption(item.id)}
                  >
                  Adicionar Opção
                </Button> */}

                {/* <SimpleGrid spacing={['12', '12']} w="100%" >

                  {item?.items?.map((option) => (
                    <Flex key={option.id} alignItems="flex-end" w="100%">
                       <Input
                        label="Opção"
                        placeholder="Preencha a opção (Ex: 50kg)"
                        // errors={errors.name}
                        {...register(`name-option-${option.id}-item-${option.item_id}`)}

                      />
                      <Button
                          ml="5"
                          as="button"
                          size="sm"
                          fontSize="md"
                          bg="black.primary"
                          height="47px"
                          _hover={{ bgColor: 'gray.500' }}
                          color="white"
                          leftIcon={<Icon as={TiDeleteOutline} fontSize="30" color="white" margin="0" marginInlineEnd={0} />}
                          onClick={() => handleDeleteOption(option)}
                          >
                            Excluir
                        </Button>
                      </Flex>
                  ))}
                </SimpleGrid> */}
              </VStack>
          ))}

          <Button
            as="button"
            size="lg"
            fontSize="sm"
            bg="wine.primary"
            mb="10"
            _hover={{ bgColor: 'wine.200' }}
            color="white"
            leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            onClick={handleNewItem}
            >
            Adicionar Item
          </Button>

          {/* <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
                <Text color="wine.primary" fontSize="19px" fontWeight="bold">Total: R$ 32.500,00</Text>
            </HStack>
          </Flex> */}

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="pink.500"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="wine.primary"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export { PreProposalCreate }
