import { Box, Button, Text, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Flex } from '@chakra-ui/react'
import React, { FC, useCallback, useState } from 'react'

interface OptionsProps {
  label: string
  value: string
}

interface Props {

  title: string
  data: OptionsProps[]
  searchFunction?: (data: string) => Promise<void>
  placeholderButton: string
  extraFunction?: (data?: any) => Promise<void> | void
  labelStyle?: any
  btnStyle?: any

}

const InputModalSearch: FC<Props> = ({ title, data, searchFunction, placeholderButton, extraFunction, labelStyle, btnStyle }) => {
  const [value, setValue] = useState('')
  const [openModal, setOpenModal] = useState(false)

  const onClick = useCallback((e: string) => {
    setValue(e)

    if (extraFunction != null) {
      extraFunction(e)
      setOpenModal(false)
    }
  }, [extraFunction])

  return (
    <>
        <Modal isOpen={openModal} size="6xl" onClose={() => setOpenModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {(searchFunction != null) && (
                <Input
                size="lg"
                bg="white"
                variant="filled"
                border="1px"
                borderColor="wine.500"
                color="wine.primary"
                _hover={{ bgColor: 'white' }}
                _focus={{ bgColor: 'white' }}
                // _hover={{ bgColor: "wine.100"}}
                _placeholder={{ color: 'gray.300' }}
                focusBorderColor="wine.500"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onChange={ async e => await searchFunction(e?.target?.value)}
                placeholder='Digite para buscar...'

            />
            )}

            <Box>
                {data?.map(item => (
                    <Flex _hover={{ background: 'green.teal', color: 'white' }} onClick={() => onClick(item.value)} key={item?.value} paddingX="20px" width="100%" height="60px" alignItems="center" borderBottom="solid 1px #999" cursor="pointer"><Text>{item?.label}</Text></Flex>
                ))}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => setOpenModal(false)}>
              Fechar
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box>
        <Text color="green.dark" fontWeight={500} style={labelStyle}>{title}</Text>

        <Button
                width="300px"
              as="button"
              marginTop="10px"
                onClick={() => setOpenModal(true)}
              size="sm"
              fontSize="sm"
              bg="green.dark"
              _hover={{ bgColor: 'green.light' }}
              color="white"
              style={btnStyle}
            //   leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            >
              { placeholderButton}
        </Button>
      </Box>

      </>
  )
}

export { InputModalSearch }
