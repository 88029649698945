import { PROPOSAL_STATUS } from '../Services/contants'
import { getKeyObjectByValue } from './getKeyObjectByValue'

const getProposalStatus = (status: number): string => {
  let result

  switch (status) {
    case 0:
      result = getKeyObjectByValue(
        PROPOSAL_STATUS,
        PROPOSAL_STATUS.CREATED
      )
      break
    case 1:
      result = getKeyObjectByValue(
        PROPOSAL_STATUS,
        PROPOSAL_STATUS.APPROVED_BY_COMPANY
      )
      break
    case 2:
      result = getKeyObjectByValue(
        PROPOSAL_STATUS,
        PROPOSAL_STATUS.APPROVED_BY_CUSTOMER
      )
      break
    case 3:
      result = getKeyObjectByValue(
        PROPOSAL_STATUS,
        PROPOSAL_STATUS.REFUSED
      )
      break
    case 4:
      result = getKeyObjectByValue(
        PROPOSAL_STATUS,
        PROPOSAL_STATUS.PROCESSED
      )
      break
    default:
      result = getKeyObjectByValue(
        PROPOSAL_STATUS,
        PROPOSAL_STATUS.CREATED
      )
      break
  }

  return result
}

export { getProposalStatus }
