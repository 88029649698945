/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  VStack,
  useToast
} from '@chakra-ui/react'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import {
  IHouseProps,
  IItemProps,
  IRulesOptions,
  useConcierge
} from '../../../Hooks/Concierge'
import { ITEMS_MULTI, ITEMS_TYPE } from '../../../Services/contants'
import { Input } from '../../Form/Input'
import { TextArea } from '../../Form/TextArea'

interface Props {
  onClose: (data?: any) => void

  data?: IRulesOptions
}

const ConciergeRulesModal: FC<Props> = ({ onClose, data }) => {
  console.log('data: ', data)
  const [loading, setLoading] = useState(false)

  const [item, setItem] = useState<IRulesOptions>({
    id: data?.id ?? uuid(),
    description: ''
  })
  const toast = useToast()
  const { concierge, updateConcierge, tempItem, updateTempItem } =
    useConcierge()

  const { register, handleSubmit, formState, setValue, watch, unregister } =
    useForm({
      // resolver: yupResolver(createFormSchema)
    })

  const handeCreateNewData: SubmitHandler<any> = useCallback(
    async (values) => {
      setLoading(true)
      try {
        const normalizeId: string = values?.id || item.id

        const payload: IRulesOptions = {
          id: normalizeId,
          description: values.description

        }

        if (!payload?.description) {
          toast({
            title: 'Preencha a Regra',
            position: 'top',
            isClosable: true,
            status: 'error',
            variant: 'solid'
          })
          setLoading(false)
          return
        }

        const current = { ...concierge }

        if (data?.id) {
          // edicao
          if (current?.rules?.length) {
            const filtered = current?.rules?.filter(i => i.id !== data?.id)
            current.rules = [...filtered, payload]
          } else {
            current.rules = [payload]
          }
        } else {
          current.rules = current?.rules?.length
            ? [...current.rules, payload]
            : [payload]
        }

        updateConcierge(current)

        onClose()
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
      }
    },
    [
      toast,
      item,
      concierge,
      updateConcierge,
      onClose,
      data
    ]
  )

  const handleChangeSelectType = useCallback(
    (value: string, itemId: string) => {
      unregister(`multi-item-${itemId}`)

      // const normalizeTempItem = {
      //   ...tempItem,
      //   options: []
      // }

      // console.log('normalizeTempItem: ', normalizeTempItem)

      // updateTempItem({ ...normalizeTempItem })
    },
    [unregister, tempItem, updateTempItem]
  )

  // const loadItem = useCallback(() => {
  //   if (data?.type && data?.id) {
  //     handleChangeSelectType(data.type, data.id)
  //     setValue(`type-item-${data.id}`, data.type)

  //     const normalizeOptions = data?.options?.map(i => {
  //       return {
  //         ...i,
  //         type: i?.from ?? i?.to ? 'RANGE' : 'DESCRIPTION'

  //       }
  //     })

  //     const payload: any = {
  //       ...data,
  //       options: normalizeOptions
  //     }

  //     updateTempItem(payload)
  //   }
  // }, [data, setValue, handleChangeSelectType, updateTempItem])

  // useEffect(() => {
  //   if (data?.id) {
  //     loadItem()
  //   }
  // }, [data])

  return (
    <>
      <Modal isOpen onClose={onClose} size="6xl">
        <ModalOverlay />
        <Box
          as="form"
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
          onSubmit={handleSubmit(handeCreateNewData)}
        >
          <ModalContent>
            <ModalHeader mb="0" pb="0">
              Nova Regra para serviço de concierge
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody>
              <VStack spacing={['12', '12']} mb="5">
                <SimpleGrid
                  spacing={['12', '12']}
                  w="100%"
                  minChildWidth="240px"
                >

                  <TextArea
                    label="Descrição"
                    placeholder="Ex: Limpeza diária (8 horas de serviço)"
                    {...register('description')}
                    // {...register('description-item')}
                    defaultValue={data?.description}
                  />
                </SimpleGrid>

              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                bg="green.dark"
                _hover={{ bgColor: 'green.teal' }}
                color="green.light"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>

            </ModalFooter>
          </ModalContent>
        </Box>
      </Modal>
    </>
  )
}

export { ConciergeRulesModal }
