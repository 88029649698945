
export type NotificationsType = 'NEW_APPOINTMENT' | 'CONCIERGE_PAYMENT_CONFIRMATION_BY_ADMIN' | 'CONCIERGE_CONFIRMATION' | 'NEW_USER' | 'CONCIERGE_PAYMENT_CONFIRMATION_BY_USER' | 'NEW_CONCIERGE_REQUESTED'

export interface NotificationsProps {

  'id': string
  'title': string
  'description': string
  'role': 'COMPANY' | 'USER'
  'data': any[]
  'user_id'?: string
  'type': NotificationsType
  'viewed': 'NO' | 'YES'
  created_at: string
}

export const NOTIFICATIONS_TYPE_LABEL: any = {
  NEW_APPOINTMENT: 'Nova Reserva',
  CONCIERGE_PAYMENT_CONFIRMATION_BY_ADMIN: 'Serviço de concierge aprovado pela Equipe MyDoor',
  CONCIERGE_CONFIRMATION: 'Serviço de concierge confirmado',
  NEW_USER: 'Novo usuário cadastrado na plataforma',
  CONCIERGE_PAYMENT_CONFIRMATION_BY_USER: 'Pagamento de serviço de concierge confirmado pelo usuário',
  NEW_CONCIERGE_REQUESTED: 'Novo serviço de concierge solicitado'
}
