import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'

interface Props {
  name: string
  closeFunction: (data?: any) => void
}

const Pill: FC<Props> = ({ name, closeFunction }) => {
  return (

         <Flex alignItems="center" paddingY="2" paddingX="4" borderRadius="20" backgroundColor="green.teal" width="max-content" cursor="pointer" onClick={closeFunction} _hover={{
           opacity: 0.8
         }} >

            <Text color="white" mr="2">{name}</Text>
            <Icon as={AiFillCloseCircle} fontSize="16" color="white" />

        </Flex>

  )
}

export { Pill }
