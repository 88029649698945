export const SCHEDULES_STATUS = {
  CREATED: 0,
  USED: 1,
  CANCELED: 2
}

export const SCHEDULE_CONCIERE_ITEM_STATUS = {
  CREATED: 'CREATED',
  CONFIRMED: 'CONFIRMED',
  WAITING_PAYMENT: 'WAITING_PAYMENT',
  PAID: 'PAID',
  CANCELED: 'CANCELED'
}

export const INFLATION_INDEX = {
  IPCA: 'IPCA',
  IGPM: 'IGPM'
}

export const ITEMS_TYPE = {
  DESCRIPTION: 'DESCRIPTION',
  NUMERIC: 'NUMERIC',
  RANGE: 'RANGE'
}

export type ITEMS_TYPE_VALUES = keyof typeof ITEMS_TYPE

export const ITEMS_MULTI = {
  NO: 'NO',
  YES: 'YES'
}
export type ITEMS_MULTI_VALUES = keyof typeof ITEMS_MULTI

export const PROPERTIES_STATUS = {
  ACTIVE: 0,
  INACTIVE: 1
}

export const USERS_STATUS = {
  ACTIVE: 0,
  INACTIVE: 1
}

export const PROPOSAL_STATUS = {
  CREATED: 0,
  APPROVED_BY_COMPANY: 1, // projecto inseriu seus valores e salvou a proposta
  APPROVED_BY_CUSTOMER: 2, // projeto enviado e aprovado pelo cliente, pode gerar o processo
  REFUSED: 3,
  PROCESSED: 4, // usando quando a ordem de serviço é criada
  FINISHED: 4 // usado quando o processo termina e não há mais nada para fazer no projeto
}

export const COLLABORATOR_STATUS = {
  ACTIVE: 0,
  INACTIVE: 1
}

export const PROVIDERS_STATUS = {
  ACTIVE: 0,
  INACTIVE: 1
}

export const NOTIFICATIONS_TYPE = {
  'pre-proposal-approvement-boss-sector': 'pre-proposal-approvement-boss-sector',
  'provider-create-boss-sector': 'provider-create-boss-sector',
  'pre-proposal-updated-boss-sector': 'pre-proposal-updated-boss-sector',
  info: 'info'
}

export const NOTIFICATIONS_STATUS = {
  not_viewed: 'NO',
  viewed: 'YES'
}
