/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Spinner,
  useToast,
  VStack,
  Text
} from '@chakra-ui/react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
// import { CKEditor } from '@ckeditor/ckeditor5-react'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TextArea } from '../../Components/Form/TextArea'
import { DragAndDrop, DragAndDropExportedProps } from '../../Components/Form/DragAndDrop'
import { AiOutlineUser } from 'react-icons/ai'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { MdPassword } from 'react-icons/md'
import { BsLock } from 'react-icons/bs'
import { removeNonNumeric } from '../../utils/removeNonNumeric'
import { CepProps } from '../Providers/create'
import { Loader } from '../../Components/Loader'
import { Pill } from '../../Components/Pill'
import IFilesProps from '../../interfaces/IFilesProps'

interface OptionsProps {
  label: string
  value: string
}

interface TagsProps {
  id: string
  tag_id: string
  name: string
}

const createFormSchema = Yup.object().shape({
  title: Yup.string().required('Título é obrigatório.'),
  category: Yup.string().required('Selecione a categoria.'),
  short_description: Yup.string().required('Descrição curta é obrigatória.'),
  type_post: Yup.string().required('Tipo de post é obrigatório.')
})

const PostsBlogCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [optionsGym, setOptionsGym] = useState<OptionsProps[]>([])
  const [showAddressComponent, setShowAddressComponent] = useState(false)
  const [selectedItem, setSelectedItem] = useState(false)
  const params = useParams()
  const location = useLocation()
  const toast = useToast()
  const navigate = useNavigate()
  const [files, setFiles] = useState<IFilesProps[]>()
  const [initialCNHCover, setInitialCNHCover] = useState<undefined | string>(undefined)
  const [initialWorkWalletCover, setInitialWorkWalletCover] = useState<undefined | string>(undefined)
  const [postDescription, setPostDescription] = useState('')
  const [tags, setTags] = useState<OptionsProps[]>([])
  const [categoryOptions, setCategoryOptions] = useState<OptionsProps[]>([])
  const [tagsOptions, setTagsOptions] = useState<OptionsProps[]>([])
  const [categorySelected, setCategorySelected] = useState<OptionsProps>({} as OptionsProps)
  const [typePost, setTypePost] = useState<OptionsProps>({} as OptionsProps)

  const typePostOptions: OptionsProps[] = [
    {
      label: 'Post',
      value: 'post'
    },
    {
      label: 'Material para download',
      value: 'download'
    }
  ]

  const CoverRef = useRef<DragAndDropExportedProps>(null)
  const CardCoverRef = useRef<DragAndDropExportedProps>(null)
  const MaterialDownloadRef = useRef<DragAndDropExportedProps>(null)
  const [postCover, setPostCover] = useState<undefined | string>(undefined)
  const [cardPostCover, setCardPostCover] = useState<undefined | string>(undefined)
  const [materialCover, setMaterialCover] = useState<undefined | string>(undefined)

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      setLoading(true)
      const {
        title,
        category,
        short_description,
        type_post
      } = values

      console.log('values: ', values)

      if (!postDescription && typePost?.value === 'post') {
        toast({
          title: 'Preencha o conteúdo do Post.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
        return
      }

      if (!short_description && typePost?.value === 'post') {
        toast({
          title: 'Preencha a descrição curta do Post.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
        return
      }

      const payload = {
        title,
        description: postDescription,
        tags: tags.map(tag => tag.value),
        category,
        short_description,
        type_post
      }

      try {
        let id = params?.post_id

        if (id) {
          await api.put(`/posts/${id}`, payload)
        } else {
          const { data } = await api.post('/posts', payload)
          id = data?.post?.id
        }

        const allFiles: Array<RefObject<DragAndDropExportedProps>> = [CoverRef, CardCoverRef, MaterialDownloadRef]

        // console.log('allFiles:', allFiles)

        const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

        console.log('normalizeInputsWithContent:', normalizeInputsWithContent)

        await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
          const cb = index === normalizeInputsWithContent?.length - 1
            ? () => {
                toast({
                  title: `${params?.post_id ? 'Editado' : 'Cadastrado'} com sucesso`,
                  position: 'top',
                  isClosable: true,
                  status: 'success',
                  variant: 'solid'
                })
                navigate('/blog/posts')
                setLoading(false)
              }
            : () => {}
          inputDrag?.current?.execute(`/files/${String(id)}`, cb)
        }))

        if (!normalizeInputsWithContent?.length) {
          toast({
            title: `${params?.post_id ? 'Editado' : 'Cadastrado'} com sucesso`,
            position: 'top',
            isClosable: true,
            status: 'success',
            variant: 'solid'
          })
          navigate('/blog/posts')
          setLoading(false)
        }
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        setLoading(false)
      }
    },
    [toast, postDescription, tags, navigate, params, typePost]
  )

  const loadItem = useCallback(async () => {
    try {
      const resp = await api.get(`/posts/${params?.post_id}`)
      const data = resp.data
      console.log('data: ', data)

      setValue('title', data.title)

      const getCategory = {
        label: data.category.name,
        value: data.category.id
      }

      const getTypePost = typePostOptions?.find(i => i.value === data.type_post)
      console.log('getTypePost: ', getTypePost)
      if (getTypePost?.value) {
        setTypePost(getTypePost)
        setValue('type_post', getTypePost.value)
      }

      setCategorySelected(getCategory)

      setValue('category', getCategory.value)
      setValue('short_description', data.short_description)
      setPostDescription(data.description)

      const normalizeTags = data?.tags?.map((i: any) => ({
        label: i.name,
        value: i.id
      }))

      console.log('normalizeTags: ', normalizeTags)

      setTags(normalizeTags)

      setPostCover(data.files.find((i: any) => i.type === 'post-cover')?.link)
      setCardPostCover(data.files.find((i: any) => i.type === 'card-post-cover')?.link)
      setMaterialCover(data.files.find((i: any) => i.type === 'material-download')?.link)
      setFiles(data.files)

      // setInitialCNHCover(data.files.find(i => i.type === 'cnh-ou-identidade')?.link)
      // setInitialWorkWalletCover(data.files.find(i => i.type === 'carteira-de-trabalho')?.link)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue, params, typePostOptions])

  const loadCategories = useCallback(async () => {
    try {
      const resp = await api.get('/categories', {
        params: {
          pag: 1,
          all: true
        }
      })
      const data = resp.data
      const normalize = data.data.map((i: any) => {
        return {
          label: i.name,
          value: i.id
        }
      })

      setCategoryOptions(normalize)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const loadTags = useCallback(async () => {
    try {
      const resp = await api.get('/tags', {
        params: {
          pag: 1,
          all: true
        }
      })
      const data = resp.data
      const normalize = data.data.map((i: any) => {
        return {
          label: i.name,
          value: i.id
        }
      })

      setTagsOptions(normalize)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  useEffect(() => {
    if (params?.post_id) {
      loadItem()
    }
  }, [params])

  useEffect(() => {
    loadCategories()
    loadTags()
  }, [])

  const handleSelectTag = useCallback((tag_id: string) => {
    const newTag = tagsOptions.find(tag => tag.value === tag_id)

    if (newTag) {
      const exists = tags.find(tag => tag.value === tag_id)

      if (!exists) {
        setTags(prev => [...prev, newTag])
      }
    }
  }, [tagsOptions, tags])

  const handleDeleteTag = useCallback((tag_id: string) => {
    const filtered = tags.filter(tag => tag.value !== tag_id)
    setTags(filtered)
  }, [tags])

  const handleChangeType = useCallback((e: any) => {
    const find = typePostOptions?.find(i => i.value === e?.target?.value)

    if (find) {
      setTypePost(find)
    } else {
      setTypePost({} as OptionsProps)
    }
  }, [typePostOptions])

  return (
    <Box>
      <Header />

       {loading && <Loader />}
      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="green.dark">
            Criar novo Post
          </Heading>
          <Divider my="6" borderColor="green.dark" />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Dados Cadastrais
          </Heading>

          <VStack spacing={['6', '8']}>

          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

          {typePostOptions?.length && (
              <Select
              label="Tipo de postagem"
              placeholder="Selecione o tipo"
              errors={errors.type_post}
              options={typePostOptions}
              defaultValue={typePost.value}

              {...register('type_post')}
              onChange={handleChangeType}
              />
          )}

          {categoryOptions?.length && (
              <Select
              label="Categoria"
              placeholder="Selecione a Categoria"
              errors={errors.category}
              options={categoryOptions}
              defaultValue={categorySelected.value}
              {...register('category')}

              />
          )}

          </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <Input
                    label="Título"
                    placeholder="Preencha o Título do Post"
                    errors={errors.title}
                    {...register('title')}
                />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <TextArea

                    label="Descrição Curta"
                    placeholder="Preencha a Descrição (chamada curta)"
                    errors={errors.short_description}
                    {...register('short_description')}
            />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <DragAndDrop
                ref={CoverRef}
                label="Capa do Post (horizontal) 1220px X 350px "
                containerStyle={{ width: '100%' }}
                payload={{
                  name: 'cover',
                  type: 'post-cover'
                }}
                defaultCover={postCover}
                id={files?.length ? files.find(i => i.type === 'post-cover')?.id : null}
              />

              <DragAndDrop
                ref={CardCoverRef}
                label="Capa do CARD do Post"
                containerStyle={{ width: '100%' }}
                payload={{
                  name: 'card cover',
                  type: 'card-post-cover'
                }}
                defaultCover={cardPostCover}
                id={files?.length ? files.find(i => i.type === 'post-cover')?.id : null}
              />

              {typePost?.value !== 'post' && (
                  <DragAndDrop
                  ref={MaterialDownloadRef}
                  label= 'Arquivo para download'
                  containerStyle={{ width: '100%' }}
                  payload={{
                    name: 'material-download',
                    type: 'material-download'
                  }}
                  defaultCover={materialCover}
                  id={files?.length ? files.find(i => i.type === 'material-download')?.id : null}
                />
              )}

            </SimpleGrid>

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                  <Select

                  label="Tags"
                  placeholder="Selecione as Tags"
                  errors={errors.tags}
                  value="Selecionar Tag"
                  {...register('tags')}
                  options={tagsOptions}
                  onChange={e => handleSelectTag(e?.target?.value) }

                  />

                  <Box>
                    <Text mb="2" color="green.primary">Tags Selecionadas</Text>

                  {tags?.length
                    ? (
                    <Flex direction="row" alignItems="center" gap="5" flexWrap="wrap">
                    {tags?.map(i => <Pill name={i.label} key={i.value} closeFunction={() => handleDeleteTag(i.value)}/>)}
                    </Flex>
                      )
                    : <Text fontWeight="500" color="green.primary">Você ainda não possui nenhuma tag selecionada.</Text>}

                  </Box>

              </SimpleGrid>

              <SimpleGrid spacing={['6', '9']} w="100%" >
              <ReactQuill
                theme="snow"
                value={postDescription}
                onChange={setPostDescription}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['link', 'image'],
                    ['clean']
                  ]
                }}
                formats={[
                  'header',
                  'bold', 'italic', 'underline', 'strike', 'blockquote',
                  'list', 'bullet', 'indent',
                  'link', 'image'
                ]}

                style={{ minHeight: 300 }} />

              </SimpleGrid>

          </VStack>

          <Flex mt={['20', '20']} justify="flex-end" >
            <HStack>
              <Button
                as="a"
                bg="green.teal"
                _hover={{ bgColor: 'gray.500' }}
                color="green.dark"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="green.dark"
                _hover={{ bgColor: 'gray.500' }}
                color="green.light"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export { PostsBlogCreate }
