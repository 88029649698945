import { addDays, format, parseISO } from 'date-fns'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import * as Yup from 'yup'

import { background, Box, Flex, Heading, Spinner, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { Header } from '../Header'
import { Sidebar } from '../Sidebar'

import api from '../../Services/api'
import { AlertDialogCustom } from '../AlertDialog'
import { useNavigate } from 'react-router-dom'
import ISchedulesProps from '../../interfaces/ISchedulesProps'
import { ModalAppointmentDetails } from '../ModalAppointmentDetails'
import { IPropertiesProps } from '../../interfaces/IPropertiesProps'
interface ClassEvent {
  title: string
  date: string
}

interface DaySelected {
  day: string
  formatted: string
}

const EventScheme = Yup.object().shape({
  name: Yup.string().required('Nome do evento é obrigatório'),
  teacher: Yup.string().required('Nome do professor é obrigatório'),
  hour: Yup.string().required('Horário de liberação é obrigatório'),
  link: Yup.string().required('Link do evento é obrigatório')
})

interface Props {
  schedule: ISchedulesProps[]
  reloadFunction: (data?: any) => void

}

interface ItemSelectedProps {
  show: boolean
  id: string
}
const CalendarSchedule: FC<Props> = ({ schedule, reloadFunction }) => {
  const [selectedItem, setSelectedItem] = useState<ItemSelectedProps>({ show: false, id: '' })

  const appointmentsData = useMemo(() => {
    return schedule.map(item => {
      const adjustedStartDate = new Date(item.date_initial)
      adjustedStartDate.setDate(adjustedStartDate.getDate() + 1) // Adiciona um dia

      const adjustedEndDate = new Date(item.date_final)
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 1) // Adiciona um dia

      return {
        title: `${item.property.name} - ${item.user.name}`,
        start: adjustedStartDate,
        end: adjustedEndDate, // Usa o objeto de data atualizado
        id: item.id,
        backgroundColor: item.property.color,
        borderWidth: 0
      }
    })
  }, [schedule])

  const handleUpdateEvent = useCallback((e: any) => {
    // setValue('name', 'value', { shouldValidate: true })
    console.log('update: ', e.event)
    setSelectedItem({
      id: e.event._def.publicId,
      show: true
    })
  }, [])

  const renderEventContent = (eventInfo: any) => {
    return (
      <div style={{ padding: '5px', paddingLeft: '10px' }}>
        <b>{String(eventInfo.event.title).toUpperCase()}</b>
      </div>
    )
  }

  return (
    <Box>
      {selectedItem.show && <ModalAppointmentDetails appointment_id={selectedItem?.id} onClose={() => setSelectedItem({ show: false, id: '' })} reloadFunction={reloadFunction} />}

      {((schedule?.length) !== 0) ? (
        <FullCalendar
          // innerRef={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          weekends
          selectable
          displayEventTime={false}
          events={appointmentsData}
          eventContent={renderEventContent}
          // dateClick={handleSelect}
          eventClick={handleUpdateEvent}
          locale="pt"
        />
      ) : <Text fontSize='19px' fontWeight='500'>Não foram encontrados agendamentos</Text>}
    </Box>

  )
}

export { CalendarSchedule }
