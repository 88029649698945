/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/react'
import React, { FC, useCallback, useState } from 'react'
import IScheduleConciergeProps from '../../../interfaces/IScheduleConciergeProps'
import { normalizeDate } from '../../../utils/normalizeDate'
import { Select } from '../../Form/Select'
import { DatePicker } from '../../Form/Datepicker'
import { format, isBefore } from 'date-fns'

interface Props {

  onClose: () => void
  onSubmit: (data?: any) => void
}
const IntervalDatesModal: FC<Props> = ({ onSubmit, onClose }) => {
  const [selectedInitialDate, setSelectedInitialDate] = useState<Date | null>(null)
  const [selectedFinalDate, setSelectedFinalDate] = useState<Date | null>(null)

  const toast = useToast()

  const onClick = useCallback(() => {
    if (!selectedInitialDate || !selectedFinalDate) {
      toast({
        title: 'Informe a data inicial e final',
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      return
    }

    if (isBefore(selectedFinalDate, selectedInitialDate)) {
      toast({
        title: 'A data final não pode ser menor que a data inicial',
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      return
    }

    const initialDate = format(selectedInitialDate, 'dd/MM/yyyy')
    const finalDate = format(selectedFinalDate, 'dd/MM/yyyy')

    onSubmit({ from: initialDate, to: finalDate })
    onClose()
  }, [selectedInitialDate, selectedFinalDate, toast, onSubmit, onClose])

  //   const handleSelectInitialDate = useCallback((date: Date) => {
  //     console.log('handleSelectInitialDate date: ', date)
  //     const initialDate = format(date, 'dd/MM/yyyy')

  //     setSelectedInitialDate(initialDate)
  //   }, [])

  //   const handleSelectFinalDate = useCallback((date: Date) => {
  //     const finalDate = format(date, 'dd/MM/yyyy')

  //     setSelectedFinalDate(finalDate)
  //   }, [])

  return (
        <Modal isOpen onClose={onClose} size="4xl" isCentered>
            <ModalOverlay backgroundColor="rgba(0,0,0,0.8)" />
            <ModalContent>
            <ModalHeader>Intervalo de Datas Especiais</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Flex>
                    <DatePicker
                        name="initialDate"
                        label="Date Inicial"
                        selected={selectedInitialDate}
                        onChange={setSelectedInitialDate}
                        helperText="Selecione a data inicial do intervalo"

                    />

                    <DatePicker
                        name="initialDate"
                        label="Date Inicial"
                        selected={selectedFinalDate}
                        onChange={setSelectedFinalDate}
                        helperText="Selecione a data final do intervalo"

                    />
                </Flex>

            </ModalBody>

            <ModalFooter>
                <Button backgroundColor="green.teal" mr={3} onClick={onClose}>
                Fechar
                </Button>
                <Button variant='ghost' onClick={onClick}>Salvar</Button>
            </ModalFooter>
            </ModalContent>
      </Modal>
  )
}

export { IntervalDatesModal }
