interface YearOption {
  label: string
  value: string
}

export function generateYearOptions (startYear: number = 2023): YearOption[] {
  const currentYear = new Date().getFullYear()
  const yearsOptions: YearOption[] = []

  for (let year = startYear; year <= currentYear + 1; year++) {
    yearsOptions.push({
      label: year.toString(),
      value: year.toString()
    })
  }

  return yearsOptions
}
