/* eslint-disable @typescript-eslint/semi */
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { BsEye } from 'react-icons/bs'

interface Unity {
  id: string
  name: string
  net: {
    name: string
  }
}

const fakeData = [
  {
    id: '1',
    company_name: 'Mc Donalds',
    unity_name: 'Porto Alegre',
    unity_cod: '523',
    process: '---',
    responsible: 'Lucas Garcia'
  },
  {
    id: '2',
    company_name: 'Subway',
    unity_name: 'Caxias do Sul',
    unity_cod: '723',
    process: '---',
    responsible: 'Ana Paula do Santos'
  },
  {
    id: '3',
    company_name: 'Madero',
    unity_name: 'Novo Hamburgo',
    unity_cod: '999',
    process: '---',
    responsible: 'Douglas Batista'
  }
]

const UnitiesList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<Unity[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState<Unity>({} as Unity)
  const toast = useToast()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/units', {
        params: {
          pag
        }
      })

      setPage(pag)
      setData(data.data)
      setTotal(data.data.total)
    } catch (error) {
      console.log('erro ao pegar unidades: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
    // setData(fakeData)
  }, [])

  useEffect(() => {
    load(page)
  }, [page])

  const hanleDelete = useCallback(async () => {
    const { id } = selectedItem
    try {
      await api.delete(`/units/${id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as Unity)
      load(page);
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as Unity)
    }
  }, [selectedItem, toast, page, load])

  const closeModal = useCallback(() => {
    setSelectedItem({} as Unity)
  }, [])

  return (
    <Box>
      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar Unidade"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModal}
          nextFunction={hanleDelete}
        />
      )}
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="wine.primary">
              Unidades
              {/* { !loading && isRefetching && <Spinner size="sm" ml="4" color="pink.400" />} */}
            </Heading>
            <SearchBox placeholder="Buscar por nome" />

            <Button
              as="a"
              href="/unities/create"
              size="sm"
              fontSize="sm"
              bg="wine.primary"
              _hover={{ bgColor: 'wine.200' }}
              color="white"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            >
              Criar nova unidade
            </Button>

            {/* <NextLink href="/users/create" passHref>
                            <Button as="a" size="sm" fontSize="sm" colorScheme="blue" leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
                                Criar Usuário
                            </Button>
                        </NextLink> */}
          </Flex>

          {loading ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
          ) : error ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista de unidades</Text>
            </Flex>
          ) : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr>
                    {/* <Th px={["4","4","6"]} color="gray.300" w="8">
                                        <Checkbox colorScheme="pink" />
                                    </Th> */}
                    <Th>Empresa/Rede</Th>
                    <Th textAlign="center">Unidade</Th>

                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((i) => (
                    <Tr key={i.id}>

                      <Td>
                          <Link color="wine.500">
                            <Text fontWeight="bold">{i.name}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold">{i.net.name}</Text>
                          </Link>
                      </Td>

                      {/* <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold">{i.unity_cod}</Text>
                          </Link>
                      </Td> */}

                      {/* <Td>
                          <Link color="wine.500">
                            <Text fontWeight="bold" textAlign="center">{i.process}</Text>
                          </Link>
                      </Td> */}

                      <Td>
                        <Flex>
                          {isWideScreen
                            ? (
                            <Button
                            href={`/process?unit=${i.id}`}
                            as="a"
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              colorScheme="teal"
                              leftIcon={
                                <Icon as={BsEye} fontSize="16" />
                              }
                            >
                              Processos
                            </Button>
                              )
                            : (
                            <IconButton
                              mr="3"
                              colorScheme="teal"
                              aria-label="process"
                              icon={<Icon as={BsEye} size="30" />}
                            />
                              )}

{isWideScreen
  ? (
                            <Button
                            href={`/unities/create/${i.id}`}
                              as="a"
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              bg="wine.200"
                              colorScheme="wine"
                              leftIcon={
                                <Icon as={RiPencilLine} fontSize="16" />
                              }
                            >
                              Editar
                            </Button>
    )
  : (
                            <IconButton
                              mr="3"
                              colorScheme="green"
                              aria-label="Edit Content"
                              icon={<Icon as={RiPencilLine} size="30" />}
                            />
    )}

{isWideScreen
  ? (
                            <Button
                              as="button"
                              onClick={() => setSelectedItem(i)}
                              size="sm"
                              fontSize="sm"
                              colorScheme="red"
                              leftIcon={
                                <Icon as={AiOutlineDelete} fontSize="16" />
                              }
                            >
                              Excluir
                            </Button>
    )
  : (
                            <IconButton
                              colorScheme="red"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineDelete} size="30" />}
                              onClick={() => setSelectedItem(i)}
                            />
    )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
            </>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export { UnitiesList }
