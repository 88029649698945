import React, { FC, useState, useEffect } from 'react'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import { Flex, SimpleGrid, Box, Text, theme } from '@chakra-ui/react'

import { useAuth } from '../../Hooks/Auth'
import { TilesBox } from '../../Components/Tiles/TilesBox'
import { FiUsers } from 'react-icons/fi'
import { MdVideoCall, MdAttachMoney, MdRemoveRedEye, MdOutlineBusinessCenter } from 'react-icons/md'
import api from '../../Services/api'
import { BsNewspaper } from 'react-icons/bs'

interface DataProps {
  users: number
  videos: number
}

const Dashboard: FC = () => {
  const [data, setData] = useState({} as DataProps)

  useEffect(() => {
    api.get('/overview').then(resp => {
      const dados = resp.data

      setData(dados)
    }).catch(error => console.log('error useEffect AuthProvider: ', error))
  }, [])

  return (
    <Flex direction="column" h="100vh" bg="green.dark">
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <SimpleGrid
          flex="1"
          gap="4"
          minChildWidth="320px"
          alignItems="flex-start"
        >

{/*
          <TilesBox title="Posts" description="Total de Posts na plataforma." icon={BsNewspaper} value="45" />

          <TilesBox
            title="Serviços de Hospitalidade"
            description="Total de serviços de hospitalidade."
            icon={MdOutlineBusinessCenter}
            value="12"
          />

          <TilesBox
            title="Colaboradores"
            description="Total de colaboradores na plataforma."
            icon={FiUsers}
            value="78"
          />

<TilesBox
            title="Clientes"
            description="Total de clientes cadastrados."
            icon={FiUsers}
            value="2590"
          />

<TilesBox
            title="Hoje"
            description="Total de faturamento Hoje."
            icon={MdAttachMoney}
            value="980,00"
          />

<TilesBox
            title="Mês"
            description="Total de faturamento este mês."
            icon={MdAttachMoney}
            value="11.500,00"
          /> */}

        </SimpleGrid>
      </Flex>
    </Flex>
  )
}

export { Dashboard }
