/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack, SimpleGrid, useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { FC, useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { Header } from '../../Components/Header'
import { Loader } from '../../Components/Loader'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'

const createFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório')
})

const CategoriesBlogCreate: FC = () => {
  const [loading, setLoading] = useState(false)

  const [selectedItem, setSelectedItem] = useState(false)
  const params = useParams()
  const location = useLocation()
  const toast = useToast()
  const navigate = useNavigate()

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      setLoading(true)
      const {
        name
      } = values

      try {
        let id = params?.category_id

        if (id) {
          await api.put(`/categories/${id}`, { name })
        } else {
          const { data } = await api.post('/categories', { name })
          id = data?.provider?.id
        }

        navigate('/blog/categories')

        toast({
          title: `${params?.category_id ? 'Editado' : 'Cadastrado'} com sucesso`,
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        setLoading(false)
      }
    },
    [toast, navigate, params]
  )

  const loadItem = useCallback(async () => {
    try {
      const resp = await api.get(`/categories/${params?.category_id}`)
      const data = resp.data
      console.log('data: ', data)

      setValue('name', data.name)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue, params])

  useEffect(() => {
    if (params?.category_id) {
      loadItem()
    }
  }, [params])

  return (
    <Box>
      <Header />

       {loading && <Loader />}
      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="green.primary">
            Criar nova Categoria
          </Heading>
          <Divider my="6" borderColor="wine.primary" />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Dados Cadastrais
          </Heading>

          <VStack spacing={['6', '8']}>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <Input
                    label="Nome"
                    placeholder="Preencha o nome da Categoria"
                    errors={errors.name}
                    {...register('name')}
                />

            </SimpleGrid>

          </VStack>

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="green.teal"
                _hover={{ bgColor: 'gray.500' }}
                color="green.dark"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="green.dark"
                _hover={{ bgColor: 'gray.500' }}
                color="green.light"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export { CategoriesBlogCreate }
