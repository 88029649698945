import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
  FormHelperText
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import InputMask from 'react-input-mask'

interface Props extends ChakraInputProps {
  name: string
  label?: string
  errors?: any
  helperText?: string
  mask: string
}

const BaseInput: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { name, label, errors = null, helperText, mask, ...rest },
  ref
) => (
  <FormControl id={name} isInvalid={!(errors == null)}>
    {!!label && <FormLabel color="green.dark">{label}</FormLabel>}
    <ChakraInput
      as={InputMask}
      mask={mask}
      name={name}
      id={name}
      size="lg"
      bg="white"
      variant="filled"
      border="1px"
      borderColor="blue.500"
      _hover={{ bgColor: 'white' }}
      _focus={{ bgColor: 'white' }}
      _placeholder={{ color: 'gray.300' }}
      focusBorderColor="blue.500"
      ref={ref}
      {...rest}
    />

    {helperText && (
      <FormHelperText color="gray.500">{helperText}</FormHelperText>
    )}
    {!(errors == null) && <FormErrorMessage>{errors.message}</FormErrorMessage>}
  </FormControl>
)

export const InputMaskCustom = forwardRef(BaseInput)
