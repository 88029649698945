import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { id } from 'date-fns/locale'

interface createUserProps {
  index?: number
  name: string
  email: string
  document: string
  phone: string

}

interface User {
  name: string
  email: string
  born: string
  phone: string
  gender: string
  gym_id: string
  cpf?: string
  address?: {
    zip_code?: string
    street?: string
    number?: string
    complement?: string
    neighborhood?: string
    city?: string
    state?: string
  }
}

interface Gym {
  id: string
  name: string
}

interface OptionsProps {
  label: string
  value: string
}

interface CepProps {
  cep: string
  logradouro: string
  complemento?: string
  bairro: string
  localidade?: string
  uf: string
}

const createUserFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string()
    .email('Preencha um e-mail válido')
    .required('E-mail é obrigatório'),
  phone: Yup.string().required('Telefone é obrigatório'),
  document: Yup.string().required('CPF/CNPJ é obrigatório')
})

const UsersCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [optionsGym, setOptionsGym] = useState<OptionsProps[]>([])
  const [showAddressComponent, setShowAddressComponent] = useState(false)
  const params = useParams()
  const location = useLocation()
  const toast = useToast()
  const navigate = useNavigate()

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: any = useCallback(
    async (values: any) => {
      const {
        name, email, phone, document
      } = values

      const normalizePhone = String(`${phone}`.replace(/([^0-9]+)/g, ''))

      if (normalizePhone.length < 11) {
        toast({
          title: 'Telefone inválido',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      const payload = {
        name,
        email,
        password: '123123',
        cpf: document,
        phone

      }

      try {
        params?.id
          ? await api.put(`/users/${params?.id}`, payload)
          : await api.post('/users', payload)

        toast({
          title: `${params?.id ? 'Editado' : 'Cadastrado'} com sucesso`,
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })

        navigate('/users')
        // router.push("/users");
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    },
    [toast, params, navigate]
  )

  const loadItem = useCallback(async (id: string) => {
    try {
      //   const { user_id } = router.query;
      //   const resp = await api.get(`/user/${user_id}`);
      //   const data: User = resp.data;
      //   const born = parseISO(data.born);
      //   console.log("born: ", born);
      //   const bornNormalized = format(born, "dd/MM/yyyy");
      //   setValue("name", data.name);
      //   setValue("email", data.email);
      //   setValue("born", data.born ? bornNormalized : "");
      //   setValue(
      //     "phone",
      //     data.phone ? String(`${data.phone}`.replace(/([^0-9]+)/g, "")) : ""
      //   );
      //   // setValue('born', '01081989')
      //   setValue("cpf", data.cpf || "");
      //   // setValue('gender', data.gender)
      //   // setValue('password', data.password)
      //   setValue("gym_id", data.gym_id);
      //   if (data?.address?.zip_code) {
      //     setShowAddressComponent(true);
      //     setValue(
      //       "zip_code",
      //       String(`${data.address.zip_code}`.replace(/([^0-9]+)/g, ""))
      //     );
      //     setValue("street", data.address.street);
      //     setValue("number", data.address.number);
      //     if (data?.address?.complement) {
      //       setValue("complement", data.address.complement);
      //     }
      //     setValue("neighborhood", data.address.neighborhood);
      //     setValue("city", data.address.city);
      //     setValue("state", data.address.state);
      //   }
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue])

  useEffect(() => {
    if (params?.id) {
      loadItem(params?.id)
    }
  }, [params])

  return (
    <Box>
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}
          // onSubmit={() => {}}
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="blue.500">
            Criar novo usuário
          </Heading>
          <Divider my="6" borderColor="blue.700" />

          <Heading size="md" fontWeight="normal" color="blue.500" my="6">
            Dados Cadastrais
          </Heading>

          <VStack spacing={['6', '8']}>
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <Input
                label="Nome"
                placeholder="Preencha o nome"
                // errors={errors.name}
                {...register('name')}
              />
              <Input
                label="E-mail"
                placeholder="Preencha o e-mail do usuário"
                // errors={errors.email}
                {...register('email')}
              />
            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <InputMaskCustom
                type="text"
                label="CPF"
                // errors={errors.cpf}
                {...register('document')}
                placeholder="Digite o cpf"
                mask="999.999.999-99"
              />

              <InputMaskCustom
                type="text"
                label="Telefone"
                // errors={errors.phone}
                {...register('phone')}
                placeholder="Digite o telefone"
                mask="(99)99999-9999"
              />
            </SimpleGrid>

          </VStack>

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="green.teal"
                _hover={{ bgColor: 'gray.500' }}
                color="green.dark"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="green.dark"
                _hover={{ bgColor: 'gray.500' }}
                color="green.light"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export { UsersCreate }
