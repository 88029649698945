/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Box, Button, Checkbox, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/react'
import React, { FC, useCallback, useEffect, useState } from 'react'
import IScheduleConciergeProps from '../../../interfaces/IScheduleConciergeProps'
import { normalizeDate } from '../../../utils/normalizeDate'
import { Select } from '../../Form/Select'
import { DatePicker } from '../../Form/Datepicker'
import { format, isBefore } from 'date-fns'
import api from '../../../Services/api'
import { PROPERTIES_STATUS } from '../../../Services/contants'
import { IHouseProps, IItemProps, IPriceHouseProps } from '../../../Hooks/Concierge'
import { IPropertiesProps } from '../../../interfaces/IPropertiesProps'

interface Props {
  alreadySelected?: string[]
  onClose: () => void
  onSubmit: (data?: any) => Promise<void>
}
const ModalSelectHouseGroupSpecialDates: FC<Props> = ({ onSubmit, onClose, alreadySelected }) => {
  const [loading, setLoading] = useState(false)
  const [selectedInitialDate, setSelectedInitialDate] = useState<Date | null>(null)
  const [selectedFinalDate, setSelectedFinalDate] = useState<Date | null>(null)
  const [houses, setHouses] = useState<IHouseProps[]>([])
  const [tempItem, setTempItem] = useState<string[]>([])

  const toast = useToast()

  const onClick = useCallback(async () => {
    await onSubmit(tempItem)
    onClose()
  }, [onSubmit, onClose, tempItem])

  const loadHouses = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get('/properties', {
        params: {
          pag: 1,
          active: PROPERTIES_STATUS.ACTIVE,
          all: true
        }
      })

      const normalize: IHouseProps[] = data.data.map(
        (house: IPropertiesProps) => {
          return {
            id: house.id,
            name: house.name
          }
        }
      )

      setHouses(normalize?.filter(i => !alreadySelected?.includes(i.id)))
    } catch (error) {
      console.log('erro ao pegar lista: ', error)
    } finally {
      setLoading(false)
    }
  }, [alreadySelected])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadHouses()
  }, [])

  const handleSelectHouse = useCallback(
    (house: string) => {
      const exists = tempItem.find(i => i === house)

      if (exists) {
        setTempItem(prev => prev?.filter(i => i !== house))
        return
      }

      setTempItem(prev => [...prev, house])
    },
    [tempItem]
  )

  return (
        <Modal isOpen onClose={onClose} size="4xl" isCentered>
            <ModalOverlay backgroundColor="rgba(0,0,0,0.8)" />
            <ModalContent>
            <ModalHeader>Casas:</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Flex>
                {houses.map((house) => (
                <Checkbox
                  key={house.id}
                  color="green.dark"
                  value={house.id}
                  name={`availability-house-${house.id}`}

                  style={{ padding: 5, color: '#000' }}
                  onChange={(e) =>
                    handleSelectHouse(house.id)
                  }
                >
                  {house.name}
                </Checkbox>
                ))}
                </Flex>

            </ModalBody>

            <ModalFooter>
                <Button backgroundColor="green.teal" mr={3} onClick={onClose}>
                Fechar
                </Button>
                <Button variant='ghost' onClick={onClick}>Salvar</Button>
            </ModalFooter>
            </ModalContent>
      </Modal>
  )
}

export { ModalSelectHouseGroupSpecialDates }
