import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  TextareaProps as ChackraTextAreaProps,
  FormErrorMessage,
  FormHelperText,
  InputLeftAddon,
  InputGroup,
  Text,
  Textarea as ChackraTextArea
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'
import { forwardRef, ForwardRefRenderFunction } from 'react'

interface Props extends ChackraTextAreaProps {
  name: string
  label?: string
  errors?: any
  helperText?: string
  leftAddon?: boolean
  leftAddonText?: string
  IconCustom?: any
}

const BaseTextArea: ForwardRefRenderFunction<any, Props> = (
  {
    name,
    label,
    errors = null,
    helperText,
    leftAddon,
    leftAddonText,
    IconCustom,
    ...rest
  },
  ref
) => (
  <FormControl id={name} isInvalid={!!errors}>
    {!!label && <FormLabel>{label}</FormLabel>}

    <ChackraTextArea
      name={name}
      id={name}
      size="lg"
      bg="white"
      variant="filled"
      border="1px"
      borderColor="green.dark"
      // _hover={{ bgColor: "blue.100"}}
      _placeholder={{ color: 'gray.300' }}
      focusBorderColor="green.light"
      ref={ref}
      {...rest}
    />

    {helperText && (
      <FormHelperText color="gray.500">{helperText}</FormHelperText>
    )}
    {!!errors && <FormErrorMessage>{errors.message}</FormErrorMessage>}
  </FormControl>
)

export const TextArea = forwardRef(BaseTextArea)
