/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox
} from '@chakra-ui/react'
import { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import * as Yup from 'yup'
import {
  DragAndDrop,
  DragAndDropExportedProps
} from '../../Components/Form/DragAndDrop'
import { Input } from '../../Components/Form/Input'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'

import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { TiDeleteOutline } from 'react-icons/ti'
import { Select } from '../../Components/Form/Select'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { TextArea } from '../../Components/Form/TextArea'
import {
  ITEMS_MULTI,
  ITEMS_TYPE,
  PROPERTIES_STATUS
} from '../../Services/contants'
import IFilesProps from '../../interfaces/IFilesProps'
import { Loader } from '../../Components/Loader'
import { AiOutlineDelete } from 'react-icons/ai'
import { ConciergeItemModal } from '../../Components/Modals/ConciergeItemModal'
import {
  IConciergeProps,
  IHouseProps,
  IItemProps,
  IPriceHouseProps,
  useConcierge
} from '../../Hooks/Concierge'
import { IPropertiesProps } from '../../interfaces/IPropertiesProps'
import { TablePlaceholder } from '../../Components/TablePlaceholder'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { MoneyInput } from '../../Components/Form/MoneyInput'
import { getCurrencyValue } from '../../utils/getCurrencyValue'
import { SelectWithSearch } from '../../Components/Form/SelectWithSearch'
import { ALL } from 'dns'
import { InputModalSearch } from '../../Components/InputModalSearch'

interface OptionsProps {
  label: string
  value: string
}

interface OptionProps {
  id: string
  name?: string
  help?: string
}

interface RulesOptions {
  id: string
  description: string
}

interface ItemProps {
  id: string
  name?: string
  multi?: 'YES' | 'NO' | undefined // utilizado quando o tipo for descritivo
  type?: 'NUMERIC' | 'DESCRIPTION' | 'RANGE' | undefined
  description?: string
  min?: number | undefined // utilizado quando o tipo for numérico
  max?: number | undefined // utilizado quando o tipo for numérico
  options?: OptionProps[]
  availability: IHouseProps[]
}

const typeOptions = [
  {
    label: 'Selecione',
    value: ''
  },
  {
    label: 'Para todos os usuários da plataforma',
    value: 'ALL'
  },
  {
    label: 'Para um usuário em específico',
    value: 'USER'
  },
  {
    label: 'Para sócios de uma casa',
    value: 'HOUSE'
  }
]

export enum TDocumentType {
  ALL = 'ALL',
  USER = 'USER',
  HOUSE = 'HOUSE'
}

const DocumentsCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [tmpName, setTmpName] = useState('')
  const [globalLoading, setGlobalLoading] = useState(true)
  const params = useParams()

  const [cover, setCover] = useState<undefined | string>(undefined)
  const [files, setFiles] = useState<IFilesProps[]>()
  const toast = useToast()
  const CoverRef = useRef<DragAndDropExportedProps>(null)
  const [showConciergeItemModal, setShowConciergeItemModal] = useState({
    show: false,
    item: ''
  })
  const [houses, setHouses] = useState<IHouseProps[]>([])
  const { updateConcierge, concierge } = useConcierge()

  const [typeDocument, setTypeDocument] = useState<TDocumentType | undefined>(undefined)
  const [optionsUsers, setOptionsUsers] = useState<OptionsProps[]>([])
  const [selectedUser, setSelectedUser] = useState<any>({} as any)
  const [allUsers, setAllUsers] = useState<any[]>([])
  const [allHouses, setAllHouses] = useState<any[]>([])
  const [optionsHouses, setOptionsHouses] = useState<OptionsProps[]>([])
  const [selectedHouse, setSelectedHouse] = useState<any>({} as any)
  const DragRef = useRef<DragAndDropExportedProps>(null)

  const navigate = useNavigate()

  const { register, handleSubmit, formState, setValue, watch, unregister, getValues } =
    useForm({
      // resolver: yupResolver(createFormSchema)
    })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      const fields = getValues()

      if (!watch('name')) {
        toast({
          title: 'Por favor, preencha o nome do documento.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      if (!DragRef?.current?.selectedFile) {
        toast({
          title: 'Por favor, selecione um documento.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      if (!typeDocument) {
        toast({
          title: 'Por favor, selecione o tipo de documento',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      if (typeDocument === 'USER' && !selectedUser?.id) {
        toast({
          title: 'Por favor, selecione o usuário.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      if (typeDocument === 'HOUSE' && !selectedHouse?.id) {
        toast({
          title: 'Por favor, selecione a casa.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      try {
        setLoading(true)

        const allFiles: Array<RefObject<DragAndDropExportedProps>> = [DragRef]

        const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => !!i.current && i.current?.selectedFile)

        console.log('normalizeInputsWithContent:', normalizeInputsWithContent)

        await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
          const cb = index === normalizeInputsWithContent?.length - 1
            ? () => {
                toast({
                  title: 'Documento cadastrado com sucesso',
                  position: 'top',
                  isClosable: true,
                  status: 'success',
                  variant: 'solid'
                })
                navigate('/documents')
                setLoading(false)
                // setImageName('')
                DragRef?.current?.cleanInput()
                // setValue('name', '')
                // load(1)
              }
            : () => {}
          inputDrag?.current?.execute(params?.id ? `/documents/${params?.id}` : '/documents', cb, params?.id ? 'PUT' : 'POST')
        }))

        if (!normalizeInputsWithContent[0]?.current) {
          // garantir que o backend irá mudar os dados mesmo sem arquivos
          await api.put(`/documents/${params?.id}`, {
            name: watch('name'),
            user_id: selectedUser?.id || null,
            house_id: selectedHouse?.id || null,
            type: typeDocument
          })
        }
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        setLoading(false)
      }
    },
    [toast, getValues, typeDocument, selectedHouse, selectedUser, navigate, watch, params]
  )

  const loadHouses = useCallback(async (id?: string) => {
    setLoading(true)
    try {
      const { data } = await api.get('/properties', {
        params: {
          pag: 1,
          active: PROPERTIES_STATUS.ACTIVE,
          all: true
        }
      })
      console.log('houses: ', data)

      const normalize: OptionsProps[] = data.data.map(
        (house: IPropertiesProps) => {
          return {
            value: house.id,
            label: house.name
          }
        }
      )

      setAllHouses(data.data)
      setOptionsHouses(normalize)
    } catch (error) {
      console.log('erro ao pegar lista: ', error)
    } finally {
      setLoading(false)
      if (!id) {
        setGlobalLoading(false)
      }
    }
  }, [])

  const handleInitialHouses = useCallback(() => {
    const currentConcierge: IConciergeProps = {
      info: {
        availability: houses,
        need_time: 'YES',
        prices: houses.map((i: IHouseProps) => {
          return {
            house: {
              id: i.id,
              name: i.name
            },
            price: 0
          }
        })
      }
    }

    updateConcierge(currentConcierge)
  }, [updateConcierge, houses])

  useEffect(() => {
    if (houses.length) {
      handleInitialHouses()
    }
  }, [houses])

  const handleChangeName = useCallback(() => {
    const current = { ...concierge }

    if (current.info) {
      current.info.name = tmpName // Removed optional chaining here

      updateConcierge(current)
    }
  }, [updateConcierge, concierge, tmpName])

  useEffect(() => {
    handleChangeName()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpName])

  const handleChangeSelectType = (value: any, fromLoad?: boolean): void => {
    const val = fromLoad ? value : value?.target?.value
    setTypeDocument(val)

    if (val === 'ALL') {
      setSelectedHouse(undefined)
      setSelectedUser(undefined)
    }
  }

  const handleSearchUsers = useCallback(async (e: string) => {
    try {
      const resp = await api.get('/users', {
        params: {
          pag: 1,
          all: true,
          name: e
        }
      })
      const data: any[] = resp.data.data

      const options = data.map((i: any) => ({
        label: i.name,
        value: i.id
      }))

      setOptionsUsers(options)
    } catch (error) {
      console.log('error gymm: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSelecUsers = useCallback(async (user_id: string) => {
    const findUsers = allUsers.find(i => i.id === user_id)
    console.log('findUsers: ', findUsers)
    if (findUsers != null) {
      setSelectedUser(findUsers)
      setValue('user_id', findUsers?.cnpj || '')
      setSelectedHouse(undefined)
    }
  }, [allUsers, setValue])

  const loadUsers = useCallback(async () => {
    try {
      const resp = await api.get('/users', {
        params: {
          pag: 1,
          all: true
        }
      })
      const data: any[] = resp.data.data

      const options = data.map((i: any) => ({
        label: i.name,
        value: i.id
      }))

      setAllUsers(resp.data.data)
      setOptionsUsers(options)
    } catch (error) {
      console.log('error gymm: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSearchHouses = useCallback(async (e: string) => {
    try {
      const resp = await api.get('/properties', {
        params: {
          pag: 1,
          all: true,
          name: e
        }
      })
      const data: any[] = resp.data.data

      const options = data.map((i: any) => ({
        label: i.name,
        value: i.id
      }))

      setOptionsHouses(options)
    } catch (error) {
      console.log('error gymm: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSelecHouses = useCallback(async (house_id: string) => {
    const findHouses = allHouses.find(i => i.id === house_id)
    console.log('findHouses: ', findHouses)
    if (findHouses != null) {
      setSelectedHouse(findHouses)
      setValue('house_id', findHouses?.cnpj || '')
      setSelectedUser(undefined)
    }
  }, [allHouses, setValue, setSelectedHouse])

  useEffect(() => {
    const initialLoad = async () => {
      await loadUsers()
      await loadHouses(params?.id)

      if (params?.id) {
        await loadItem()
      }
    }

    initialLoad()
  }, [params])

  const loadItem = useCallback(async () => {
    try {
      const { data } = await api.get(`/documents/${params?.id}`)

      setValue('name', data.name)

      handleChangeSelectType(data?.type, true)

      if (data?.type) {
        setValue('type', data?.type)
      }

      if (data?.link) {
        setCover(data?.link)
      }

      if (data?.type === 'HOUSE') {
        setSelectedHouse({
          id: data?.property?.id,
          name: data?.property?.name
        })
      }

      if (data?.type === 'USER') {
        setSelectedUser({
          id: data?.user?.id,
          name: data?.user?.name
        })
      }
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setGlobalLoading(false)
    }
  }, [toast, setValue, params])

  console.log('typeDocument: ', typeDocument)
  console.log('selectedHouse: ', selectedHouse)

  return (
    <Box>
      <Header />

      {loading && !globalLoading && <Loader />}

      {globalLoading ? <Loader /> : (
         <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
         <Sidebar />

         {showConciergeItemModal.show && (
           <ConciergeItemModal
             houses={concierge?.info?.availability ?? []}
             onClose={() => setShowConciergeItemModal({ item: '', show: false })}
           />
         )}

         <Box
           as="form"
           flex="1"
           borderRadius={8}
           bg="white"
           p="8"
           onSubmit={handleSubmit(handleSubmitForm)}
         >
           <Heading size="lg" fontWeight="normal" color="green.dark">
             Criar novo documento
           </Heading>

           <Box mt='30px'>

             <SimpleGrid
               spacing={['6', '9']}
               w="100%"
               minChildWidth="240px"
             >
               <Input label='Nome do documento' {...register('name')} />

                <Select
                 options={typeOptions}
                 label='Tipo de documento'
                 {...register('type')}
                 style={{ backgroundColor: '#fff' }}
                 onChange={handleChangeSelectType}
                 // extraFunction={handleChangeSelectType}
                 // defaultValue={item?.type}
               />

             </SimpleGrid>

             {typeDocument === 'USER' && (
                  <SimpleGrid
                  spacing={['6', '9']}
                  w="100%"
                  minChildWidth="240px"
                  mt='30px'
                >

               <InputModalSearch title="Usuário" searchFunction={handleSearchUsers} data={optionsUsers} extraFunction={handleSelecUsers} placeholderButton={selectedUser?.id ? 'Trocar Usuário' : 'Selecionar Usuário'} />

               <Input {...register('user_id')} value={selectedUser?.id} isReadOnly visibility="hidden" />

               {selectedUser?.id && (
                 <>
                 <Heading size="md" fontWeight="normal" color="green.dark">
                   Cliente:
                   <Text size="sm" fontWeight="bold" fontSize="16" color="green.dark">{selectedUser?.name}</Text>
                 </Heading>

                 </>
               )}
               </SimpleGrid>
             )}

             {typeDocument === 'HOUSE' && (
               <SimpleGrid
                 spacing={['6', '9']}
                 w="100%"
                 minChildWidth="240px"
                 mt='30px'
               >

                 <InputModalSearch title="Casa" searchFunction={handleSearchHouses} data={optionsHouses} extraFunction={handleSelecHouses} placeholderButton={selectedHouse?.id ? 'Trocar Casa' : 'Selecionar Casa'} />

                 <Input {...register('house_id')} value={selectedHouse?.id} isReadOnly visibility="hidden" />

                 {selectedHouse?.id && (
                   <>
                   <Heading size="md" fontWeight="normal" color="green.dark">
                     Casa:
                     <Text size="sm" fontWeight="bold" fontSize="16" color="green.dark">{selectedHouse?.name}</Text>
                   </Heading>

                   </>
                 )}
             </SimpleGrid>
             )}

             <SimpleGrid
               spacing={['6', '9']}
               w="100%"
               minChildWidth="240px"
               mt='50px'
             >
               <DragAndDrop
                 ref={DragRef}
                 label="Documento"
                 containerStyle={{ width: '100%' }}
                 payload={{
                   name: watch('name'),
                   user_id: selectedUser?.id || null,
                   house_id: selectedHouse?.id || null,
                   type: typeDocument
                 }}
                 defaultCover={cover}
                 id={params?.id}
                 cleanUri={`/documents-file/${String(params?.id)}`}
               />

               <Box />

             </SimpleGrid>
           </Box>

           <Flex mt={['6', '8']} justify="flex-end">
             <HStack>
               {loading
                 ? (
                 <Spinner />
                   )
                 : (
                 <>
                   <Button
                     as="a"
                     href="/concierge"
                     bg="green.teal"
                     _hover={{ bgColor: 'gray.500' }}
                     color="white"
                     cursor="pointer"
                   >
                     Cancelar
                   </Button>

                   <Button
                     type="submit"
                     bg="green.dark"
                     _hover={{ bgColor: 'green.teal' }}
                     color="green.light"
                     isLoading={formState.isSubmitting}
                   >
                     Salvar
                   </Button>
                 </>
                   )}
             </HStack>
           </Flex>
         </Box>
       </Flex>
      )}

    </Box>
  )
}

export { DocumentsCreate }
