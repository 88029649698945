import { Button } from '@chakra-ui/react'

interface PaginationItemProps {
  isCurrent?: boolean
  number: number
  onPageChange: (page: number) => void
}

export function PaginationItem ({
  isCurrent = false,
  onPageChange,
  number
}: PaginationItemProps) {
  if (isCurrent) {
    return (
      <Button
        size="sm"
        fontSize="xs"
        w="4"
        bg="green.light"
        color="gray.50"
        disabled
        _disabled={{
          bgColor: 'green.teal',
          cursor: 'default'
        }}
      >
        {number}
      </Button>
    )
  }

  return (
    <Button
      size="sm"
      fontSize="xs"
      w="4"
      // colorScheme="pink"
      bg="green.dark"
      _hover={{
        bg: 'green.light'
      }}
      color="gray.50"
      onClick={() => onPageChange(number)}
    >
      {number}
    </Button>
  )
}
