import {
  Box,
  useBreakpointValue,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody
} from '@chakra-ui/react'
import { NavSidebar } from './NavSidebar'
import { useSideBar } from '../../Hooks/SideBarContext'

export function Sidebar () {
  const { isOpen, onClose } = useSideBar()
  const showDrawer = useBreakpointValue({
    base: true,
    lg: false
  })

  if (showDrawer) {
    return (
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent bg="blue.500">
            <DrawerHeader>Navegação</DrawerHeader>
            <DrawerCloseButton mt="2" />

            <DrawerBody>
              <NavSidebar />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    )
  }

  return (
    <Box as="aside" w="64" mr="4">
      <NavSidebar />
    </Box>
  )
}
