/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Spinner,
  useToast,
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useTimeout
} from '@chakra-ui/react'
import { v4 as uuid } from 'uuid'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TextArea } from '../../Components/Form/TextArea'
import { DragAndDrop } from '../../Components/Form/DragAndDrop'
import { RiAddLine } from 'react-icons/ri'
import { TiDeleteOutline } from 'react-icons/ti'
import { SelectWithSearch } from '../../Components/Form/SelectWithSearch'
import { MoneyInput } from '../../Components/Form/MoneyInput'
import { FindKeyWithPrefix } from '../../utils/findKeyWithPrefix'
import { getCurrencyValue } from '../../utils/getCurrencyValue'
import { PROPOSAL_STATUS } from '../../Services/contants'
import { getProposalStatus } from '../../utils/getProposalStatus'
import { FormatMoney } from '../../utils/formatMoney'
import { Checkbox } from '../../Components/Form/Checkbox'

interface ItemProposalProps {
  id: string
  installments: string | number
  ticket_value: string
  expenses_value: string
  company_value: string
  executor_value: string
  executor_value_formatted?: string
  ticket_value_formatted?: string
  expenses_value_formatted?: string
  service: {
    id: string
    name: string
  }
  executor: {
    id: string
    name: string
  }
}

interface ProposalProps {
  id: string
  number: string
  status: string
  created_at: string
  technical: {
    id: string
    name: string
  }
  financial: {
    id: string
    name: string
  }
  net: {
    id: string
    name: string
  }
  unit: {
    id: string
    name: string
  }
  items: ItemProposalProps[]
}

interface OptionsProps {
  label: string
  value: string
}

interface ItemsSelected {
  id: string
  label: string
  value: string
}

interface ItemsForPaymentsOptions {
  id: string
  options: OptionsProps[]

}

const optionsProposalStatus = [
  {
    value: '0',
    label: 'Aguardando aprovação da empresa'
  },
  {
    value: '1',
    label: 'Aguardando aprovação do cliente'
  },
  {
    value: 'aprovado',
    label: 'Aprovado pelo cliente'
  }
]

interface PaymentsProps {
  id: string
  name: string
}

interface ServicesProps {
  id: string
  cod: string
  name: string
  service: string
  value: string
  payments: PaymentsProps[]
}

const ProposalCreate: FC = () => {
  const [loading, setLoading] = useState(true)
  const [proposalData, setProposalData] = useState<ProposalProps>({} as ProposalProps)
  const [bdiColors, setBdiColors] = useState<any>({} as any)
  const params = useParams()
  const toast = useToast()
  const [totalProposal, setTotal] = useState('R$ 0,00')
  const navigation = useNavigate()
  const [showModalProcess, setShowModalProcess] = useState(false)
  const [allServicesCheckedToProcess, setServicesAllCheckedToProcess] = useState<string[]>([])
  const [allSelected, setAllSelected] = useState(false)

  const { register, handleSubmit, formState, setValue, getValues } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      try {
        console.log('values: ', values)

        const dados = FindKeyWithPrefix(values, 'name-item-total_value')
        console.log('dados: ', dados)
        const isValid = dados.every((i: any) => i.value)

        if (!isValid) {
          toast({
            title: 'Preencha todos os valores',
            position: 'top',
            isClosable: true,
            status: 'warning',
            variant: 'solid'
          })

          return
        }

        const items = dados.map((i: any) => {
          const getId = i.key.split('name-item-total_value-')
          return {
            item_id: getId[1],
            company_value: getCurrencyValue(i.value)
          }
        })

        const payload = {
          status: 1,
          items
        }

        const { id } = params

        await api.patch(`/proposal/${id}`, payload)

        toast({
          title: 'Valores inseridos com sucesso',
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
        navigation('/proposal')
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    },
    [toast, navigation, params]
  )

  const CalculateTotal = useCallback(() => {
    const values = getValues()
    console.log('values: ', values)
    const dados: any[] = FindKeyWithPrefix(values, 'name-item-total_value')
    console.log('dados: ', dados)
    const total = dados.reduce((acc, curr) => {
      if (!curr.value) return acc

      const currentValue = getCurrencyValue(curr.value)
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return acc += Number(currentValue)
    }, 0)

    console.log('total: ', total)
    const formattedTotal = FormatMoney(total)
    console.log('formattedTotal: ', formattedTotal)

    setTotal(formattedTotal)
  }, [getValues])

  const CalculateBDI = useCallback((proposal_item: ItemProposalProps, value: string) => {
    const totalValue = getCurrencyValue(value)
    const executorValue = Number(proposal_item.executor_value)
    const expensesValue = Number(proposal_item.expenses_value)
    const ticketValue = Number(proposal_item.ticket_value)

    const serviceTotalValue = executorValue + expensesValue + ticketValue

    const bdi = (((totalValue * 0.83) - serviceTotalValue) / totalValue) * 100

    let bdiResult: any

    if (bdi >= 40) {
      bdiResult = {
        value: `(${bdi.toFixed(0)}%) - OK`,
        color: '#90c904'
      }
    } else if (bdi >= 30) {
      bdiResult = {
        value: `(${bdi.toFixed(0)}%) - BOM`,
        color: '#ffff00'
      }
    } else if (bdi >= 20) {
      bdiResult = {
        value: `(${bdi.toFixed(0)}%) - RUIM`,
        color: '#D3000'
      }
    } else {
      bdiResult = {
        value: `(${bdi.toFixed(0)}%) - MUITO RUIM`,
        color: '#FF2400'
      }
    }

    setValue(`name-item-bdi-${proposal_item.id}`, bdiResult.value)

    setBdiColors((prev: any) => {
      const key = `name-item-bdi-${proposal_item.id}`
      const payload = {
        ...prev
      }

      payload[key] = bdiResult.color

      return payload
    })

    CalculateTotal()
  }, [setValue, CalculateTotal])

  const loadProposal = useCallback(async (id: string) => {
    try {
      setLoading(true)
      const { data } = await api.get(`/proposal/${id}`)

      setProposalData(data)

      data?.items?.forEach((i: any) => {
        if (!i.company_value) return

        CalculateBDI(i, i.company_value)

        // console.log('item name-item-total_value-id: ', `name-item-total_value-${i.id}`)
        // console.log('item name-item-total_value-id: ', Number(i.company_value))
        // setValue(`name-item-total_value-${i.id}`, Number(i.company_value))
      })
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [toast])

  useEffect(() => {
    if (params?.id) {
      loadProposal(params?.id)
    }
  }, [params])

  const ProposalStatus = useMemo(() => {
    if (!proposalData?.status) return PROPOSAL_STATUS.CREATED

    return getProposalStatus(Number(proposalData.status))
  }, [proposalData])

  const handleCreateProcess = useCallback(async (values: any) => {
    try {
      const dados: any[] = FindKeyWithPrefix(values, 'name-item-service_selected')
      console.log('dados: ', dados)

      const filtered = dados.filter(item => item.value === true)

      const items = filtered.map((i: any) => {
        const getId = i.key.split('name-item-service_selected-')
        return getId[1]
      })

      console.log('items: ', items)

      if (!items?.length) {
        toast({
          title: 'Selecione pelo menos 1 item da proposta para gerar o processo',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid',
          duration: 7000
        })
        return
      }

      const payload = {
        proposal_id: proposalData?.id,
        net_id: proposalData?.net?.id,
        unit_id: proposalData?.unit?.id,
        items
      }

      await api.post('/process', payload)

      toast({
        title: 'Processo gerado',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      navigation('/process')
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [proposalData, toast, navigation])

  const handleSelecetAllServices = useCallback((e: any) => {
    const checked = e?.target?.checked

    const fields = getValues()
    const dados = FindKeyWithPrefix(fields, 'name-item-service_selected')

    if (checked) {
      const selected = dados.map((i: any) => {
        setValue(i.key, true)
        return i.key
      })
      setAllSelected(true)

      setServicesAllCheckedToProcess(selected)
    } else {
      dados.forEach((i: any) => {
        setValue(i.key, false)
      })
      setValue('name-item-all_service_selected', false)
      setAllSelected(false)
      setServicesAllCheckedToProcess([])
    }
  }, [getValues, setValue])

  const handleSelecetOneService = useCallback((e: any, id: string) => {
    const checked = e?.target?.checked
    if (checked) {
      setValue(id, true)
      setServicesAllCheckedToProcess(prev => [...prev, id])
    } else {
      const filtered = allServicesCheckedToProcess.filter((i: string) => i !== id)
      setValue(id, false)
      setServicesAllCheckedToProcess(filtered)
      setValue('name-item-all_service_selected', false)
      setAllSelected(false)
    }
  }, [setValue, allServicesCheckedToProcess])

  console.log('allSelected: ', allSelected)

  return (
    <Box>

      <Modal isOpen={showModalProcess} onClose={() => setShowModalProcess(false)} size="2xl">
      <Box
           as="form"
           onSubmit={handleSubmit(handleCreateProcess)}
           flex="1"
           bg="white"
           p="8"
           w="100%"
         >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Selecione os itens para a Ordem de Serviço</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Table colorScheme="blue">
                <Thead>
                  <Tr>

                    <Th px={['4', '4', '6']} color="gray.300" w="8">
                      {allSelected ? <Checkbox key={Math.random()} colorScheme="pink" {...register('name-item-all_service_selected')} defaultChecked={true} isChecked={true} onChange={(e: any) => handleSelecetAllServices(e)} /> : <Checkbox key={Math.random()} colorScheme="pink" {...register('name-item-all_service_selected')} onChange={(e: any) => handleSelecetAllServices(e)} />}

                      </Th>
                    <Th>Serviços da Proposta</Th>

                    <Th>Valor Total</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {proposalData?.items?.map((i) => (
                    <Tr key={i.id}>
                      <Td px={['4', '4', '6']}>
                        {allServicesCheckedToProcess.includes(`name-item-service_selected-${i.id}`) ? <Checkbox key={Math.random()} colorScheme="pink" {...register(`name-item-service_selected-${i.id}`)} defaultChecked={true} isChecked={true} onChange={(e: any) => handleSelecetOneService(e, `name-item-service_selected-${i.id}`)}/> : <Checkbox key={Math.random()} colorScheme="pink" {...register(`name-item-service_selected-${i.id}`)} onChange={(e: any) => handleSelecetOneService(e, `name-item-service_selected-${i.id}`)} /> }

                      </Td>
                      <Td>
                        <Box>

                            <Text fontWeight="bold">{i.service.name}</Text>

                        </Box>
                      </Td>
                      <Td>
                        <Text fontWeight="bold">{FormatMoney(Number(i.company_value))}</Text>
                      </Td>

                    </Tr>
                  ))}
                </Tbody>
              </Table>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => setShowModalProcess(false)}>
              Cancelar
            </Button>

            <Button
                 type="submit"
                 bg="wine.primary"
                 _hover={{ bgColor: 'gray.500' }}
                 color="white"
                 isLoading={formState.isSubmitting}
               >Gerar Ordem de Serviço (Processo)</Button>

          </ModalFooter>
        </ModalContent>
        </Box>
      </Modal>

      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <VStack w="100%" borderRadius={8} bg="white" p="8" textAlign="left">
        <VStack w="100%">
          <Heading size="lg" fontWeight="normal" color="wine.primary" textAlign="left">
                Proposta
          </Heading>

          {/* <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

          <Box
              as="form"

              // onSubmit={handleSubmit(handleSubmitForm)}
              onSubmit={() => {}}
              flex="1"

              bg="white"
              p="8"
            >

            <Select
              label="Status"
              placeholder="Selecionar Status"
              // errors={errors.gender}
              {...register('gender')}
              options={optionsProposalStatus}
              />
          </Box>

          </SimpleGrid> */}
        </VStack>

        {loading ? <Spinner size="sm" ml="4" color="pink.400" /> : (
           <Box
           as="form"
           onSubmit={handleSubmit(handleSubmitForm)}
           flex="1"
           bg="white"
           p="8"
           w="100%"
         >

           <VStack spacing={['6', '8']}>

           <Heading size="md" fontWeight="bold" color="gray.500" >
             Dados Cadastrais
           </Heading>

             <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

               <Box>
                   <Text color="wine.primary" fontWeight={500} align="center">Responsável Técnico</Text>
                   <Text align="center">{proposalData?.technical?.name}</Text>
               </Box>

               <Box>
                   <Text color="wine.primary" fontWeight={500} align="center">Responsável Financeiro</Text>
                   <Text align="center">{proposalData?.financial?.name}</Text>
               </Box>

             </SimpleGrid>

             <Divider />

             <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
             <Box>
                   <Text color="wine.primary" fontWeight={500} align="center">Rede</Text>
                   <Text align="center">{proposalData?.net?.name}</Text>
               </Box>

               <Box>
                   <Text color="wine.primary" fontWeight={500} align="center">Unidade</Text>
                   <Text align="center">{proposalData?.unit?.name}</Text>
               </Box>

             </SimpleGrid>

             <Divider />

           </VStack>

           <Heading size="md" fontWeight="bold" color="gray.500" my="8">
           Items da Proposta
           </Heading>

           {!proposalData?.items?.length && <Text mb="10" color="wine.primary">Você ainda não possui nenhum item de proposta cadastrado.</Text>}

           {proposalData?.items?.map((item, indexItem: number) => (
               <VStack spacing={['9', '9']} mb="5" bg="wine.primary" p="10" key={item.id} borderRadius="8px">
                   <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                         <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Executante</Text>
                         <Text align="center" color="white.primary">{item.executor.name}</Text>
                       </Box>

                       <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Serviço</Text>
                         <Text align="center" color="white.primary">{item.service.name}</Text>
                       </Box>

                       <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Parcelamento</Text>
                         <Text align="center" color="white.primary">{item.installments}x</Text>
                       </Box>

                   </SimpleGrid>

                   <Divider color="white" />

                   <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
                       <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Valor do Prestador</Text>
                         <Text align="center" color="white.primary">{item?.executor_value_formatted}</Text>
                       </Box>

                       <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Locomoção/Passagem</Text>
                         <Text align="center" color="white.primary">{item?.ticket_value_formatted}</Text>
                       </Box>

                       <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Despesas</Text>
                         <Text align="center" color="white.primary">{item?.expenses_value_formatted}</Text>
                       </Box>
                   </SimpleGrid>

                   <Divider color="white" />

                   <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

                      <MoneyInput
                        labelStyle={{ color: '#fff' }}
                        label="Valor Total"
                        placeholder="Preencha o valor"
                        extraFunction={(e: any) => CalculateBDI(item, e)}
                        // errors={errors.name}
                        defaultValue={item?.company_value ? Number(item?.company_value) : null}
                        {...register(`name-item-total_value-${item.id}`)}

                      />

                      <Input
                            labelStyle={{ color: '#fff' }}
                            bg={bdiColors[`name-item-bdi-${item.id}`] || '#fff'}
                            fontWeight={700}
                            label='BDI'
                            isReadOnly

                            {...register(`name-item-bdi-${item.id}`)}
                      />
                 </SimpleGrid>

               </VStack>
           ))}

           <Flex mt={['6', '8']} justify="flex-end">
             <HStack>
                 <Text color="wine.primary" fontSize="19px" fontWeight="bold">Total: {totalProposal}</Text>
             </HStack>
           </Flex>

           <Flex mt={['6', '8']} justify="flex-end">
             <HStack>
             {ProposalStatus === 'CREATED' && <Button
                 type="submit"
                 bg="wine.primary"
                 _hover={{ bgColor: 'gray.500' }}
                 color="white"
                 isLoading={formState.isSubmitting}
               >
                 Salvar
               </Button>}

               {ProposalStatus !== 'CREATED' && <Button
                 type="submit"
                 bg="blue"
                 _hover={{ bgColor: 'gray.500' }}
                 color="white"
                 isLoading={formState.isSubmitting}
               >
                 Gerar Proposta (PDF)
               </Button>}

               <Button
                 as="button"
                 bg="pink.500"
                 _hover={{ bgColor: 'gray.500' }}
                 color="white"
                 onClick={() => setShowModalProcess(true)}
               >
                 Gerar Ordem de Serviço (processo)
               </Button>

             </HStack>
           </Flex>
         </Box>
        )}
        </VStack>

      </Flex>
    </Box>
  )
}

export { ProposalCreate }
