import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'

interface User {

  id: string
  name: string
  email: string
  phone: string
  active: number
  resetPass: number
  created_at: string
}

const UsersList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<User[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState({} as User)
  const toast = useToast()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/users', {
        params: {
          pag
        }
      })

      const normalize = data.data.map((i: User) => {
        // const regExpPhone = new RegExp(/^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/);
        return {
          ...i,
          phone: i?.phone || 'Não informado',
          createdAt: new Date(i.created_at).toLocaleString('pt-BR', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
          })
        }
      })

      setPage(pag)
      setData(normalize)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar users: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    load(page)
  }, [page])

  const hanleDelete = useCallback(async () => {
    const { id } = selectedItem
    try {
      await api.delete(`/users/${id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as User)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as User)
    }
  }, [selectedItem, toast, page, load])

  const closeModal = useCallback(() => {
    setSelectedItem({} as User)
  }, [])

  return (
    <Box>
      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar Usuário"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModal}
          nextFunction={hanleDelete}
        />
      )}
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="blue.500">
              Usuários
              {/* { !loading && isRefetching && <Spinner size="sm" ml="4" color="pink.400" />} */}
            </Heading>
            {/* <SearchBox placeholder="Buscar por nome" /> */}

            {/* <Button
              as="a"
              size="sm"
              fontSize="sm"
              bg="blue.500"
              _hover={{ bgColor: "gray.500" }}
              color="white"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            >
              Criar novo usuário
            </Button> */}

            <Button
              as="a"
              href="/users/create"
              size="sm"
              fontSize="sm"
              bg="green.dark"
              _hover={{ bgColor: 'green.light' }}
              color="white"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            >
              Criar Cliente
            </Button>
          </Flex>

          {loading ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
          ) : error ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista de usuários</Text>
            </Flex>
          ) : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr>
                    {/* <Th px={["4","4","6"]} color="gray.300" w="8">
                                        <Checkbox colorScheme="pink" />
                                    </Th> */}
                    <Th>Usuário</Th>
                    {isWideScreen && <Th>Fone</Th>}
                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((i) => (
                    <Tr key={i.id}>
                      {/* <Td px={["4","4","6"]}>
                                         <Checkbox colorScheme="pink" />
                                     </Td> */}
                      <Td>
                        <Box>
                          <Link color="blue.500">
                            <Text fontWeight="bold">{i.name}</Text>
                          </Link>
                          <Text fontSize="sm" color="gray.300">
                            {i.email}
                          </Text>
                        </Box>
                      </Td>
                      {isWideScreen && <Td color="blue.500">{i.phone}</Td>}
                      <Td>
                        <Flex>
                          {/* {isWideScreen ? (
                            <Button
                              as="button"
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              colorScheme="teal"
                              leftIcon={
                                <Icon as={AiOutlineUser} fontSize="16" />
                              }
                            >
                              Perfil
                            </Button>
                          ) : (
                            <IconButton
                              mr="3"
                              colorScheme="teal"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineUser} size="30" />}
                            />
                          )} */}

                          {/* {isWideScreen ? (
                            <Button
                              as="button"
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              colorScheme="green"
                              leftIcon={
                                <Icon as={RiPencilLine} fontSize="16" />
                              }
                            >
                              Editar
                            </Button>
                          ) : (
                            <IconButton
                              mr="3"
                              colorScheme="green"
                              aria-label="Edit Content"
                              icon={<Icon as={RiPencilLine} size="30" />}
                            />
                          )} */}

                          {/* {isWideScreen ? (
                            <Button
                              as="button"
                              onClick={() => setSelectedItem(i)}
                              size="sm"
                              fontSize="sm"
                              colorScheme="red"
                              leftIcon={
                                <Icon as={AiOutlineDelete} fontSize="16" />
                              }
                            >
                              Excluir
                            </Button>
                          ) : (
                            <IconButton
                              colorScheme="red"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineDelete} size="30" />}
                              onClick={() => setSelectedItem(i)}
                            />
                          )} */}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
            </>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export { UsersList }
