import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { useAuth } from '../Hooks/Auth'

import { BillsToReceiveList } from '../Pages/BillsToReceive'
import { BillsToReceiveCreate } from '../Pages/BillsToReceive/create'
import { CategoriesBlogList } from '../Pages/CategoriesBlog'
import { CategoriesBlogCreate } from '../Pages/CategoriesBlog/create'
import { ClauseProposalList } from '../Pages/ClauseProposal'
import { ClauseProposalCreate } from '../Pages/ClauseProposal/create'
import { CollaboratorsList } from '../Pages/Collaborators'
import { CollaboratorsCreate } from '../Pages/Collaborators/create'
import { Dashboard } from '../Pages/Dashboard'
import { MidiaList } from '../Pages/Midia'
import { MidiaCreate } from '../Pages/Midia/create'
import { NotFoundPage } from '../Pages/NotFound'
import { NotificationsList } from '../Pages/Notifications'
import { PostsBlogList } from '../Pages/PostsBlog'
import { PostsBlogCreate } from '../Pages/PostsBlog/create'
import { PreProposalList } from '../Pages/PreProposal'
import { PreProposalCreate } from '../Pages/PreProposal/create'
import { ProcessList } from '../Pages/Process'
import { ProcessCreate } from '../Pages/Process/create'
import { ProductsList } from '../Pages/Products'
import { ProductsCreate } from '../Pages/Products/create'
import { ProposalList } from '../Pages/Proposal'
import { ProposalCreate } from '../Pages/Proposal/create'
import { ProvidersList } from '../Pages/Providers'
import { ProvidersCreate } from '../Pages/Providers/create'
import { ServicesList } from '../Pages/Services'
import { ServicesCreate } from '../Pages/Services/create'
import { SignIn } from '../Pages/SignIn'
import { TagsBlogList } from '../Pages/TagsBlog'
import { TagsBlogCreate } from '../Pages/TagsBlog/create'
import { TestimonialsList } from '../Pages/Testimonials'
import { TestimonialsCreate } from '../Pages/Testimonials/create'
import { UnitiesList } from '../Pages/Unities'
import { UnitiesCreate } from '../Pages/Unities/create'
import { UsersList } from '../Pages/Users'
import { PropertiesList } from '../Pages/Properties'
import { PropertiesCreate } from '../Pages/Properties/create'
import { ConciergeList } from '../Pages/Concierge'
import { ConciergeCreate } from '../Pages/Concierge/create'
import { UsersCreate } from '../Pages/Users/create'
import { Schedule } from '../Pages/Schedule'
import { ScheduleDetail } from '../Pages/Schedule/show'
import { ImagesBank } from '../Pages/ImagesBank/create'
import { FinancialHousesList } from '../Pages/FinancialHouses'
import { FinancialHousesCreate } from '../Pages/FinancialHouses/create'
import { DocumentsList } from '../Pages/Documents'
import { DocumentsCreate } from '../Pages/Documents/create'
import { ScheduleCreate } from '../Pages/Schedule/create'
import { SpecialDatesList } from '../Pages/SpecialDates'
import { SpecialDatesCreate } from '../Pages/SpecialDates/create'
import { SpecialDatesDetails } from '../Pages/SpecialDates/details'

const AppRoutes: FC = () => {
  const { user } = useAuth()
  console.log('user: ', user)
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/unities" element={<UnitiesList />} />
      <Route path="/unities/create" element={<UnitiesCreate />} />
      <Route
        path="/unities/create/:unit_id"
        element={<UnitiesCreate />}
      />

      <Route path="/providers" element={<ProvidersList />} />
      <Route path="/providers/create" element={<ProvidersCreate />} />
      <Route
        path="/providers/create/:provider_id"
        element={<ProvidersCreate />}
      />

      <Route path="/images/bank" element={<ImagesBank />} />
      {/* <Route path="/bills-pay/create" element={<BillsToPayCreate />} />
      <Route
        path="/bills-pay/create/:id"
        element={<BillsToPayCreate />}
      /> */}

      <Route path="/bills-receive" element={<BillsToReceiveList />} />
      <Route path="/bills-receive/create" element={<BillsToReceiveCreate />} />
      <Route
        path="/bills-receive/create/:id"
        element={<BillsToReceiveCreate />}
      />

      <Route path="/collaborators" element={<CollaboratorsList />} />
      <Route path="/collaborators/create" element={<CollaboratorsCreate />} />
      <Route
        path="/collaborators/create/:collaborator_id"
        element={<CollaboratorsCreate />}
      />

      <Route path="/notifications" element={<NotificationsList />} />

      <Route path="/pre-proposal" element={<PreProposalList />} />
      <Route path="/pre-proposal/create" element={<PreProposalCreate />} />
      <Route
        path="/pre-proposal/create/:proposal_id"
        element={<PreProposalCreate />}
      />

      <Route path="/process" element={<ProcessList />} />
      <Route path="/process/create" element={<ProcessCreate />} />
      <Route
        path="/process/create/:id"
        element={<ProcessCreate />}
      />

      <Route path="/proposal" element={<ProposalList />} />
      <Route path="/proposal/create" element={<ProposalCreate />} />
      <Route
        path="/proposal/create/:id"
        element={<ProposalCreate />}
      />

      <Route path="/blog/posts" element={<PostsBlogList />} />
      <Route path="/blog/posts/create" element={<PostsBlogCreate />} />
      <Route
        path="/blog/posts/create/:post_id"
        element={<PostsBlogCreate />}
      />

      <Route path="/midia" element={<MidiaList />} />
      <Route path="/midia/create" element={<MidiaCreate />} />
      <Route
        path="/midia/create/:slug"
        element={<MidiaCreate />}
      />

      <Route path="/blog/categories" element={<CategoriesBlogList />} />
      <Route path="/blog/categories/create" element={<CategoriesBlogCreate />} />
      <Route
        path="/blog/categories/create/:category_id"
        element={<CategoriesBlogCreate />}
      />

      <Route path="/blog/tags" element={<TagsBlogList />} />
      <Route path="/blog/tags/create" element={<TagsBlogCreate />} />
      <Route
        path="/blog/tags/create/:tag_id"
        element={<TagsBlogCreate />}
      />

      <Route path="/services" element={<ServicesList />} />
      <Route path="/services/create" element={<ServicesCreate />} />
      <Route
        path="/services/create/:service_id"
        element={<ServicesCreate />}
      />

      <Route path="/clause-proposal" element={<ClauseProposalList />} />
      <Route path="/clause-proposal/create" element={<ClauseProposalCreate />} />
      <Route
        path="/clause-proposal/create/:id"
        element={<ClauseProposalCreate />}
      />

      <Route path="/properties" element={<PropertiesList />} />
      <Route path="/properties/create" element={<PropertiesCreate />} />
      <Route
        path="/properties/create/:id"
        element={<PropertiesCreate />}
      />

      <Route path="/financial-houses" element={<FinancialHousesList />} />
      <Route path="/financial-houses/create" element={<FinancialHousesCreate />} />
      <Route
        path="/financial-houses/create/:property_id"
        element={<FinancialHousesCreate />}
      />

      <Route path="/concierge" element={<ConciergeList />} />
      <Route path="/concierge/create" element={<ConciergeCreate />} />
      <Route
        path="/concierge/create/:id"
        element={<ConciergeCreate />}
      />

      <Route path="/documents" element={<DocumentsList />} />
      <Route path="/documents/create" element={<DocumentsCreate />} />
      <Route
        path="/documents/create/:id"
        element={<DocumentsCreate />}
      />

      <Route path="/users" element={<UsersList />} />
      <Route path="/users/create" element={<UsersCreate />} />
      <Route
        path="/users/create/:id"
        element={<UsersCreate />}
      />

      <Route path="/schedule" element={<Schedule />} />
      <Route
        path="/schedule/details/:id"
        element={<ScheduleDetail />}
      />
       <Route
        path="/schedule/create/:id"
        element={<ScheduleCreate />}
      />
      <Route
        path="/schedule/create"
        element={<ScheduleCreate />}
      />

     <Route path="/special-dates" element={<SpecialDatesList />} />

     <Route
        path="/special-dates/create/:id"
        element={<SpecialDatesCreate />}
      />

      <Route
        path="/special-dates/create"
        element={<SpecialDatesCreate />}
      />

      <Route
        path="/special-dates/details/:group_id"
        element={<SpecialDatesDetails />}
      />

{/*
      <Route
        path="/schedule/details/:id"
        element={<ScheduleDetail />}
      />

       */}

      <Route path="/products" element={<ProductsList />} />
      <Route path="/products/create" element={<ProductsCreate />} />
      <Route
        path="/products/create/:slug"
        element={<ProductsCreate />}
      />

      <Route path="/testimonials" element={<TestimonialsList />} />
      <Route path="/testimonials/create" element={<TestimonialsCreate />} />
      <Route
        path="/testimonials/create/:testimonial_id"
        element={<TestimonialsCreate />}
      />

      <Route path="/" element={<SignIn />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes
