const getKeyObjectByValue = (object: any, value: any): string => {
  // eslint-disable-next-line no-restricted-syntax
  for (const chave in object) {
    if (object[chave] === value) {
      return chave
    }
  }
  return '' // Caso o valor não seja encontrado
}

export { getKeyObjectByValue }
