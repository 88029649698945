import { IConciergeItemStatusProps } from '../interfaces/IConciergeItemStatusProps'

const getConciergeStatus = (status: IConciergeItemStatusProps): string => {
  const SCHEDULE_CONCIERE_ITEM_STATUS = {
    CREATED: 'Solicitado',
    CONFIRMED: 'Confirmado',
    WAITING_PAYMENT: 'Aguardando Pagamento',
    PAID: 'Pago',
    CANCELED: 'Cancelado'
  }

  return SCHEDULE_CONCIERE_ITEM_STATUS[status]
}

export { getConciergeStatus }
