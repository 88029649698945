/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  VStack,
  useToast
} from '@chakra-ui/react'
import { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
// import { CKEditor } from '@ckeditor/ckeditor5-react'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { DragAndDrop, DragAndDropExportedProps } from '../../Components/Form/DragAndDrop'
import { Input } from '../../Components/Form/Input'
import { TextArea } from '../../Components/Form/TextArea'
import { Header } from '../../Components/Header'
import { Loader } from '../../Components/Loader'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import IFilesProps from '../../interfaces/IFilesProps'

interface OptionsProps {
  label: string
  value: string
}

interface TagsProps {
  id: string
  tag_id: string
  name: string
}

const createFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório.'),
  description: Yup.string().required('Depoimento é obrigatório.'),
  locale: Yup.string().required('Localidade é obrigatório.')

})

const TestimonialsCreate: FC = () => {
  const [loading, setLoading] = useState(false)

  const params = useParams()
  const location = useLocation()
  const toast = useToast()
  const navigate = useNavigate()
  const [files, setFiles] = useState<IFilesProps[]>()

  const CoverRef = useRef<DragAndDropExportedProps>(null)

  const [postCover, setPostCover] = useState<undefined | string>(undefined)

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      setLoading(true)
      const {
        name, description, locale
      } = values

      console.log('values: ', values)

      const payload = {
        name, description, locale
      }

      try {
        let id = params?.testimonial_id

        if (id) {
          await api.put(`/testimonials/${id}`, payload)
        } else {
          const { data } = await api.post('/testimonials', payload)
          id = data?.testimonial?.id
        }

        const allFiles: Array<RefObject<DragAndDropExportedProps>> = [CoverRef]

        // console.log('allFiles:', allFiles)

        const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

        await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
          const cb = index === normalizeInputsWithContent?.length - 1
            ? () => {
                toast({
                  title: `${params?.testimonial_id ? 'Editado' : 'Cadastrado'} com sucesso`,
                  position: 'top',
                  isClosable: true,
                  status: 'success',
                  variant: 'solid'
                })
                navigate('/testimonials')
                setLoading(false)
              }
            : () => {}
          inputDrag?.current?.execute(`/files/${String(id)}`, cb)
        }))

        if (!normalizeInputsWithContent?.length) {
          toast({
            title: `${params?.testimonial_id ? 'Editado' : 'Cadastrado'} com sucesso`,
            position: 'top',
            isClosable: true,
            status: 'success',
            variant: 'solid'
          })
          navigate('/testimonials')
          setLoading(false)
        }
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        setLoading(false)
      }
    },
    [toast, navigate, params]
  )

  const loadItem = useCallback(async () => {
    try {
      const resp = await api.get(`/testimonials/${params?.testimonial_id}`)
      const data = resp.data

      setValue('name', data?.name)
      setValue('description', data?.description)
      setValue('locale', data?.locale)

      setPostCover(data.files.find((i: any) => i.type === 'testimonials')?.link)

      setFiles(data.files)

      // setInitialCNHCover(data.files.find(i => i.type === 'cnh-ou-identidade')?.link)
      // setInitialWorkWalletCover(data.files.find(i => i.type === 'carteira-de-trabalho')?.link)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue, params])

  useEffect(() => {
    if (params?.testimonial_id) {
      loadItem()
    }
  }, [params])

  return (
    <Box>
      <Header />

       {loading && <Loader />}
      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="green.dark">
            Criar novo Depoimento
          </Heading>
          <Divider my="6" borderColor="green.dark" />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Dados Cadastrais
          </Heading>

          <VStack spacing={['6', '8']}>

          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

            <Input
                label="Nome"
                placeholder="Preencha o nome"
                errors={errors.name}
                {...register('name')}
            />

<Input
                label="Localidade"
                placeholder="Preencha o localidade"
                errors={errors.locale}
                {...register('locale')}
            />

          </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <TextArea

                    label="Depoimento"
                    placeholder="Preencha o depoimento"
                    errors={errors.description}
                    {...register('description')}
            />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <DragAndDrop
                ref={CoverRef}
                label="Foto"
                containerStyle={{ width: '100%' }}
                payload={{
                  name: 'testimonials',
                  type: 'testimonials'
                }}
                defaultCover={postCover}
                id={files?.length ? files.find(i => i.type === 'testimonials')?.id : null}
              />

            </SimpleGrid>

          </VStack>

          <Flex mt={['20', '20']} justify="flex-end" >
            <HStack>
              <Button
                as="a"
                bg="green.teal"
                _hover={{ bgColor: 'gray.500' }}
                color="green.dark"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="green.dark"
                _hover={{ bgColor: 'gray.500' }}
                color="green.light"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export { TestimonialsCreate }
