/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox
} from '@chakra-ui/react'
import { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import * as Yup from 'yup'
import {
  DragAndDrop,
  DragAndDropExportedProps
} from '../../Components/Form/DragAndDrop'
import { Input } from '../../Components/Form/Input'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'

import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { TiDeleteOutline } from 'react-icons/ti'
import { Select } from '../../Components/Form/Select'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { TextArea } from '../../Components/Form/TextArea'
import {
  ITEMS_MULTI,
  ITEMS_TYPE,
  PROPERTIES_STATUS
} from '../../Services/contants'
import IFilesProps from '../../interfaces/IFilesProps'
import { Loader } from '../../Components/Loader'
import { AiOutlineDelete } from 'react-icons/ai'
import { ConciergeItemModal } from '../../Components/Modals/ConciergeItemModal'
import {
  IConciergeProps,
  IHouseProps,
  IItemProps,
  IOptionProps,
  IPriceHouseProps,
  IRulesOptions,
  useConcierge
} from '../../Hooks/Concierge'
import { IPropertiesProps } from '../../interfaces/IPropertiesProps'
import { TablePlaceholder } from '../../Components/TablePlaceholder'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { MoneyInput } from '../../Components/Form/MoneyInput'
import { getCurrencyValue } from '../../utils/getCurrencyValue'
import { ConciergeRulesModal } from '../../Components/Modals/ConciergeRulesModal'

// interface OptionsProps {
//   label: string
//   value: string
// }

// interface OptionProps {
//   id: string
//   name?: string
//   help?: string
// }

interface RulesOptions {
  id: string
  description: string
}

// export interface ItemProps {
//   id: string
//   name?: string
//   multi?: 'YES' | 'NO' | undefined // utilizado quando o tipo for descritivo
//   type?: 'NUMERIC' | 'DESCRIPTION' | 'RANGE' | undefined
//   description?: string
//   min?: number | undefined // utilizado quando o tipo for numérico
//   max?: number | undefined // utilizado quando o tipo for numérico
//   options?: OptionProps[]
//   availability: IHouseProps[]
// }

interface ServiceProps {
  name: string
  items: IItemProps[]
  rules?: RulesOptions[]
  files: IFilesProps[]
}

const multiOptions = [
  {
    label: 'Selecione',
    value: ''
  },
  {
    label: 'Sim',
    value: 'YES'
  },
  {
    label: 'Não',
    value: 'NO'
  }
]

const typeOptions = [
  {
    label: 'Selecione',
    value: ''
  },
  {
    label: 'Campo Numérico',
    value: 'NUMERIC'
  },
  {
    label: 'Campo Descritivo',
    value: 'DESCRIPTION'
  }
]

const ConciergeCreate: FC = () => {
  const [loadingGlobal, setLoadingGlobal] = useState(true)
  const [loading, setLoading] = useState(false)
  const [tmpName, setTmpName] = useState('')
  const [initialCover, setInitialCover] = useState(undefined)
  const params = useParams()

  const [postCover, setPostCover] = useState<undefined | string>(undefined)

  const toast = useToast()
  const CoverRef = useRef<DragAndDropExportedProps>(null)
  const [showConciergeItemModal, setShowConciergeItemModal] = useState < { show: boolean; item?: any } >({
    show: false,
    item: undefined
  })
  const [showConciergeRuleModal, setShowConciergeRuleModal] = useState < { show: boolean; item?: any } >({
    show: false,
    item: undefined
  })
  const [houses, setHouses] = useState<IHouseProps[]>([])
  const { updateConcierge, concierge, updateTempItem } = useConcierge()
  const [selectedItem, setSelectedItem] = useState<IItemProps>(
    {} as IItemProps
  )
  const [selectedRule, setSelectedRule] = useState<IRulesOptions>(
    {} as IRulesOptions
  )
  const [initialData, setInitialData] = useState<IConciergeProps>()

  console.log('concierge: ', concierge)

  const navigate = useNavigate()

  const { register, handleSubmit, formState, setValue, watch, unregister } =
    useForm({
      // resolver: yupResolver(createFormSchema)
    })
  const { errors } = formState

  const handeCreateNewData: SubmitHandler<any> = useCallback(
    async (values) => {
      setLoading(true)
      try {
        const validationResult = true
        if (validationResult) {
          console.log('O payload é válido.')
        } else {
          console.error('O payload é inválido:', validationResult)
          toast({
            title: validationResult,
            position: 'top',
            isClosable: true,
            status: 'error',
            variant: 'solid'
          })
          setLoading(false)
          return
        }

        if (!concierge?.info?.name) {
          toast({
            title: 'Preencha o nome do serviço de concierge',
            position: 'top',
            isClosable: true,
            status: 'error',
            variant: 'solid'
          })
          setLoading(false)
          return
        }

        console.log('concierge: ', concierge)

        let id = params?.id

        if (id) {
          await api.put(`/concierges/${id}`, concierge)
        } else {
          const { data } = await api.post('/concierges', concierge)
          id = data?.concierges?.id
        }

        const allFiles: Array<RefObject<DragAndDropExportedProps>> = [CoverRef]

        const normalizeInputsWithContent: Array<
        RefObject<DragAndDropExportedProps>
        > = allFiles.filter((i) => i.current?.selectedFile)

        await Promise.all(
          normalizeInputsWithContent.map(
            async (
              inputDrag: RefObject<DragAndDropExportedProps>,
              index: number
            ) => {
              const cb =
                index === normalizeInputsWithContent?.length - 1
                  ? () => {
                      toast({
                        title: `${
                          params?.post_id ? 'Editado' : 'Cadastrado'
                        } com sucesso`,
                        position: 'top',
                        isClosable: true,
                        status: 'success',
                        variant: 'solid'
                      })
                      navigate('/concierge')
                      setLoading(false)
                    }
                  : () => {}
              inputDrag?.current?.execute(`/files/${String(id)}`, cb)
            }
          )
        )

        if (!normalizeInputsWithContent?.length) {
          toast({
            title: params?.id
              ? 'Editado com sucesso'
              : 'Cadastrado com sucesso',
            position: 'top',
            isClosable: true,
            status: 'success',
            variant: 'solid'
          })

          navigate('/concierge')
          setLoading(false)
        }
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
      }
    },
    [toast, params, navigate, concierge]
  )

  const loadItem = useCallback(async () => {
    try {
      const resp = await api.get(`/concierges/${params?.id}`, { params: { fromAdmin: true } })
      const data: IConciergeProps = resp.data

      setInitialData(resp.data)
      updateConcierge(resp.data)
      setValue('name', data?.info?.name)
      setTmpName(resp.data?.info?.name)

      setLoadingGlobal(false)

      resp?.data?.info?.prices?.map((i: any) => {
        setValue(`price-house-${i?.house?.id}`, i?.price)
      })

      if (data?.info?.files?.length) {
        setPostCover(data?.info?.files[0]?.link)
      }
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setLoadingGlobal(false)
    }
  }, [toast, setValue, params, updateConcierge])

  useEffect(() => {
    if (params.id) {
      loadItem()
    } else {
      setLoadingGlobal(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const loadHouses = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get('/properties', {
        params: {
          pag: 1,
          active: PROPERTIES_STATUS.ACTIVE,
          all: true
        }
      })

      const normalize: IHouseProps[] = data.data.map(
        (house: IPropertiesProps) => {
          return {
            id: house.id,
            name: house.name
          }
        }
      )

      setHouses(normalize)
    } catch (error) {
      console.log('erro ao pegar lista: ', error)
    } finally {
      setLoading(false)
    }
  }, [])

  const handleInitialHouses = useCallback(() => {
    // caso seja um concierge novo, colocar todas as casas default como true
    if (!params?.id) {
      // const currentConcierge: IConciergeProps = {
      //   info: {
      //     availability: houses,
      //     need_time: 'YES',
      //     prices: houses.map((i: IHouseProps) => {
      //       return {
      //         house: {
      //           id: i.id,
      //           name: i.name
      //         },
      //         price: 0
      //       }
      //     })
      //   }
      // }
      const currentConcierge: IConciergeProps = {
        info: {
          availability: [],
          need_time: 'NO',
          prices: []
        }
      }
      updateConcierge(currentConcierge)
      setInitialData(currentConcierge)
    }
  }, [updateConcierge, params])

  useEffect(() => {
    if (houses.length && !params?.id) {
      handleInitialHouses()
    }
  }, [houses])

  useEffect(() => {
    loadHouses()
  }, [])

  const handleChangeName = useCallback(() => {
    const current = { ...concierge }

    if (current.info) {
      current.info.name = tmpName // Removed optional chaining here

      updateConcierge(current)
    }
  }, [updateConcierge, concierge, tmpName])

  useEffect(() => {
    handleChangeName()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpName])

  const handleSelectHouse = useCallback(
    ({ houseId, name }: { houseId: string; name: string }) => {
      const currentConcierge: IConciergeProps = { ...concierge }

      const exists = currentConcierge?.info?.availability?.find(
        (i: IHouseProps) => i.id === houseId
      )

      if (exists) {
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        if (currentConcierge.info && currentConcierge.info.availability) {
          currentConcierge.info.availability =
            currentConcierge.info.availability?.filter(
              (i: IHouseProps) => i.id !== houseId
            )
          currentConcierge.info.prices = currentConcierge.info.prices?.filter(
            (i: IPriceHouseProps) => i.house.id !== houseId
          )
          updateConcierge(currentConcierge)
        }
      } else {
        const payloadHouse = { id: houseId, name }
        if (
          currentConcierge.info &&
          currentConcierge.info.availability &&
          currentConcierge.info.prices
        ) {
          currentConcierge.info.availability = [
            ...currentConcierge.info.availability,
            payloadHouse
          ]
          currentConcierge.info.prices = [
            ...currentConcierge.info.prices,
            { house: payloadHouse, price: 0 }
          ]
          updateConcierge(currentConcierge)
        }
      }
    },
    [concierge, updateConcierge]
  )

  const hanleDeleteConciergeItem = useCallback(async () => {
    if (!selectedItem || !selectedItem?.id) return

    try {
      const current = { ...concierge }

      current.items = current?.items?.filter(
        (item) => item.id !== selectedItem.id
      )

      updateConcierge(current)
      setSelectedItem({} as IItemProps)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as IItemProps)
    }
  }, [selectedItem, toast, concierge, updateConcierge])

  const closeItemConciergeModal = useCallback(() => {
    setSelectedItem({} as IItemProps)
  }, [])

  const closeRuleConciergeModal = useCallback(() => {
    setSelectedRule({} as IRulesOptions)
  }, [])

  const handleChangeValue = useCallback(
    (house: IHouseProps, value: string) => {
      const normalizeValue = getCurrencyValue(value)

      const currentConcierge = { ...concierge }

      // const filteredAvailability = existsOption?.availability?.filter((i: IHouseProps) => i.id !== houseId)

      // const currentTempItem: IItemProps = { ...tempItem }
      const optionIndex = currentConcierge.info?.prices?.findIndex(
        (i) => i.house.id === house.id
      )

      if (optionIndex !== -1 && currentConcierge.info?.prices) {
        if (optionIndex === undefined) return
        // Check if options is defined before accessing it
        const option = currentConcierge.info?.prices[optionIndex]
        if (option) {
          // Check if option is defined before accessing it
          option.price = normalizeValue
        }
      }

      updateConcierge(currentConcierge)

      // updateTempItem(currentTempItem)
    },
    [concierge, updateConcierge]
  )

  const handleSelectHours = useCallback(
    (value: boolean) => {
      const currentConcierge = { ...concierge }

      if (currentConcierge.info) {
        if (value) {
          currentConcierge.info.need_time = 'YES'
        } else {
          currentConcierge.info.need_time = 'NO'
        }
      }

      updateConcierge(currentConcierge)
    },
    [concierge, updateConcierge]
  )

  const hanleDeleteConciergeRule = useCallback(async () => {
    if (!selectedRule || !selectedRule?.id) return

    try {
      const current = { ...concierge }

      current.rules = current?.rules?.filter(
        (rule) => rule.id !== selectedRule.id
      )

      updateConcierge(current)
      setSelectedRule({} as IRulesOptions)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedRule({} as IRulesOptions)
    }
  }, [selectedRule, toast, concierge, updateConcierge])

  return (
    <Box>
      <Header />
      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar Item de Serviço de Concierge"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeRuleConciergeModal}
          nextFunction={hanleDeleteConciergeItem}
        />
      )}

{!!selectedRule?.id && (
        <AlertDialogCustom
          title="Deletar Regra de Serviço de Concierge"
          isOpen={!!selectedRule?.id}
          cancelFunction={closeItemConciergeModal}
          nextFunction={hanleDeleteConciergeRule}
        />
)}

      {loading && <Loader />}

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        {showConciergeRuleModal.show && (
          <ConciergeRulesModal
            data={showConciergeRuleModal.item}
            onClose={() => {
              setShowConciergeRuleModal({ item: undefined, show: false })
            }}
          />
        )}

        {showConciergeItemModal.show && (
          <ConciergeItemModal
            data={showConciergeItemModal.item}
            houses={concierge?.info?.availability ?? []}
            onClose={() => {
              updateTempItem({} as IItemProps)
              setShowConciergeItemModal({ item: undefined, show: false })
            }}
          />
        )}

      {loadingGlobal ? <Loader /> : (
         <Box
         as="form"
         flex="1"
         borderRadius={8}
         bg="white"
         p="8"
         onSubmit={handleSubmit(handeCreateNewData)}
       >
         <Heading size="lg" fontWeight="normal" color="green.dark">
           Criar novo Serviço de Concierge: {tmpName}
         </Heading>

         <Tabs variant="enclosed" mt="30px">
           <TabList>
             <Tab>Dados</Tab>
             <Tab>Itens</Tab>
             <Tab>Regulamento</Tab>
           </TabList>

           <TabPanels>
             <TabPanel mt="20px">
               <Tabs variant="soft-rounded">
                 <TabList>
                   <Tab>Informações</Tab>
                   <Tab>Disponibilidade</Tab>
                   <Tab>Tabela de preço base</Tab>
                 </TabList>
                 <TabPanels>
                   <TabPanel mt="30px">
                     <SimpleGrid
                       spacing={['6', '9']}
                       w="100%"
                       minChildWidth="240px"
                     >
                       <DragAndDrop
                         ref={CoverRef}
                         label="Capa do Serviço"
                         containerStyle={{ width: '100%' }}
                         payload={{
                           name: 'cover',
                           type: 'concierge-cover'
                         }}
                         defaultCover={postCover}
                         id={initialData?.info?.files?.[0]?.id ?? null}
                       />

                       <Box>
                         <Input
                           label="Nome"
                           placeholder="Preencha o nome do Serviço (Ex: Massagem)"
                           // errors={errors.name}
                           {...register('name')}
                           onChange={(e) => setTmpName(e.target.value)}
                         />
                         <Checkbox
                           color="green.dark"
                           value="yes"
                           name=""
                           defaultChecked={initialData?.info?.need_time === 'YES'}
                           colorScheme="whatsapp"
                           style={{ marginTop: 50, padding: 5, color: '#000' }}
                           onChange={(e) =>
                             handleSelectHours(e.target.checked)
                           }
                         >
                           Este serviço necessita agendamento de horário?
                         </Checkbox>
                       </Box>
                     </SimpleGrid>
                   </TabPanel>
                   <TabPanel>
                     <Text color="green.teal" fontSize={18}>
                       Em quais casas este <b>SERVIÇO</b> de concierge estará
                       disponível?
                     </Text>

                     <Divider
                       borderColor="#000"
                       backgroundColor="#000"
                       color="#000"
                       mb="20px"
                       mt="15px"
                     />
                     <SimpleGrid
                       spacing={['12', '12']}
                       w="100%"
                       minChildWidth="150px"
                     >
                       {houses.map((house) => (
                         <Checkbox
                           key={house.id}
                           color="green.dark"
                           value={house.id}
                           name={`availability-house-${house.id}`}
                            defaultChecked={!!initialData?.info?.availability?.find(i => i.id === house.id)}
                          // defaultChecked={!!concierge?.info?.availability?.find(i => i.id === house.id)}
                           colorScheme="whatsapp"
                           style={{ padding: 5, color: '#000' }}
                           onChange={(e) =>
                             handleSelectHouse({
                               houseId: house.id,
                               name: house.name
                             })
                           }
                         >
                           {house.name}
                         </Checkbox>
                       ))}
                     </SimpleGrid>
                   </TabPanel>

                   <TabPanel>
                     <Table mt="30px">
                       <Thead>
                         <Tr>
                           <Th
                             textAlign="left"
                             backgroundColor="green.dark"
                             color="white"
                           >
                             Nome
                           </Th>
                           <Th backgroundColor="green.dark" color="white">
                             Preço Base
                           </Th>
                         </Tr>
                       </Thead>
                       <Tbody>
                         {!concierge?.info?.availability?.length && (
                           <TablePlaceholder title="Você não marcou nenhuma casa com disponibilidade para este serviço de concierge." />
                         )}
                         {concierge?.info?.availability?.map((house) => (
                           <Tr key={house.id}>
                             <Td>
                               <Text fontWeight="bold" color="green.dark">
                                 {house.name}
                               </Text>
                             </Td>
                             <Td>
                               <MoneyInput
                                 label="Preço"
                                 placeholder="Preencha o valor"
                                 // extraFunction={(e: any) => CalculateBDI(item, e)}
                                 // errors={errors.name}
                                  defaultValue={Number(concierge?.info?.prices?.find(i => i.house.id === house.id)?.price) ?? 0}

                                 {...register(`price-house-${house.id}`)}

                                 onChange={(e: any) =>
                                   handleChangeValue(house, e.target.value)
                                 }
                               />
                             </Td>
                           </Tr>
                         ))}
                       </Tbody>
                     </Table>
                   </TabPanel>
                 </TabPanels>
               </Tabs>
             </TabPanel>

             <TabPanel>
               <Flex justifyContent="flex-end">
                 <Button
                   onClick={() =>
                     setShowConciergeItemModal({
                       show: true,
                       item: undefined
                     })
                   }
                   size="sm"
                   fontSize="sm"
                   bg="green.dark"
                   _hover={{ bgColor: 'green.light' }}
                   color="white"
                   leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                 >
                   Criar novo Item
                 </Button>
               </Flex>
               <Table mt="30px">
                 <Thead>
                   <Tr>
                     <Th
                       textAlign="left"
                       backgroundColor="green.dark"
                       color="white"
                     >
                       Nome
                     </Th>
                     <Th backgroundColor="green.dark" color="white" w="8"></Th>
                   </Tr>
                 </Thead>

                 {concierge?.items?.length
                   ? (
                   <Tbody>
                     {concierge?.items?.map((i) => (
                       <Tr key={i.id}>
                         <Td>
                           <Text fontWeight="bold" color="green.dark">
                             {i.name}
                           </Text>
                         </Td>

                         <Td>
                           <Flex>
                             <Button
                               as="a"
                               onClick={() =>
                                 setShowConciergeItemModal({
                                   show: true,
                                   item: i
                                 })
                              }
                               size="sm"
                               fontSize="sm"
                               mr="2"
                               bg="green.dark"
                               color="green.light"
                               leftIcon={
                                 <Icon as={RiPencilLine} fontSize="16" />
                               }
                             >
                               Editar
                             </Button>

                             <Button
                               as="button"
                               onClick={() => setSelectedItem(i)}
                               size="sm"
                               fontSize="sm"
                               bg="green.teal"
                               color="green.dark"
                               leftIcon={
                                 <Icon as={AiOutlineDelete} fontSize="16" />
                               }
                             >
                               Excluir
                             </Button>
                           </Flex>
                         </Td>
                       </Tr>
                     ))}
                   </Tbody>
                     )
                   : (
                   <TablePlaceholder title="Você ainda não possui nenhum item de concierge cadastrado para este serviço." />
                     )}
               </Table>
             </TabPanel>

             <TabPanel>
              <Flex justifyContent="flex-end">
                  <Button
                    onClick={() =>
                      setShowConciergeRuleModal({
                        show: true,
                        item: undefined
                      })
                    }
                    size="sm"
                    fontSize="sm"
                    bg="green.dark"
                    _hover={{ bgColor: 'green.light' }}
                    color="white"
                    leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                  >
                    Criar nova regra
                  </Button>
              </Flex>
              <Table mt="30px">
                 <Thead>
                   <Tr>
                     <Th
                       textAlign="left"
                       backgroundColor="green.dark"
                       color="white"
                     >
                       Regra
                     </Th>
                     <Th backgroundColor="green.dark" color="white" w="8"></Th>
                   </Tr>
                 </Thead>

                 {concierge?.rules?.length
                   ? (
                   <Tbody>
                     {concierge?.rules?.map((i) => (
                       <Tr key={i.id}>
                         <Td>
                           <Text fontWeight="bold" color="green.dark">
                             {i.description}
                           </Text>
                         </Td>

                         <Td>
                           <Flex>
                             <Button
                               as="a"
                               onClick={() =>
                                 setShowConciergeRuleModal({
                                   show: true,
                                   item: i
                                 })
                              }
                               size="sm"
                               fontSize="sm"
                               mr="2"
                               bg="green.dark"
                               color="green.light"
                               leftIcon={
                                 <Icon as={RiPencilLine} fontSize="16" />
                               }
                             >
                               Editar
                             </Button>

                             <Button
                               as="button"
                               onClick={() => setSelectedRule(i)}
                               size="sm"
                               fontSize="sm"
                               bg="green.teal"
                               color="green.dark"
                               leftIcon={
                                 <Icon as={AiOutlineDelete} fontSize="16" />
                               }
                             >
                               Excluir
                             </Button>
                           </Flex>
                         </Td>
                       </Tr>
                     ))}
                   </Tbody>
                     )
                   : (
                   <TablePlaceholder title="Você ainda não possui nenhuma regra cadastrada para este serviço." />
                     )}
               </Table>
             </TabPanel>
           </TabPanels>
         </Tabs>

         {/* <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
             <DragAndDrop
               ref={CoverRef}
               label="Capa do Serviço"
               containerStyle={{ width: '100%' }}
               payload={{
                 name: 'cover',
                 type: 'concierge-cover'
               }}
               defaultCover={postCover}
               id={files?.length ? files.find(i => i.type === 'concierge-cover')?.id : null}
             />

             <Box />
             </SimpleGrid>

           <VStack spacing={['9', '9']} mb="5" mt="40px">
             <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
               <Input
                 label="Nome"
                 placeholder="Preencha o nome do Serviço (Ex: Massagem)"
                 // errors={errors.name}
                 {...register('name')}
               />
             </SimpleGrid>

           </VStack>

             <Heading size="md" fontWeight="normal" color="green.teal" my="6" mt="0">
               Opcionais
             </Heading>

             {!items?.length && <Text mb="10" color="black.primary">Você ainda não possui nenhum opcional cadastrado para este serviço.</Text>}

             {items.map((item, indexItem: number) => (

                   <VStack spacing={['9', '9']} mb="5" bg="green.light" p="10" key={item.id} borderRadius="8px">
                             <Flex justifyContent="flex-end" w="100%">
                           <Button
                             ml="5"
                             as="button"
                             size="sm"
                             fontSize="md"
                             bg="red"
                             width="200px"
                             height="30px"
                             _hover={{ bgColor: 'gray.500' }}
                             color="white"
                             leftIcon={<Icon as={TiDeleteOutline} fontSize="30" color="white" margin="0" marginInlineEnd={0} />}
                             onClick={() => handleDeleteItem(item.id)}
                             >
                               Excluir todo item
                           </Button>
                           </Flex>

                     <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

                     <Input
                       label="Nome"
                       placeholder="Ex: Tipo de Massagem"
                       // errors={errors.name}
                       {...register(`name-item-${item.id}`)}
                       defaultValue={item?.name}

                     />

                     {/* <TextArea
                       label="Descrição"
                       placeholder="Preencha a descrição"
                       {...register('description')}
                     /> */}
         {/* <Input
                       label="Descrição"
                       placeholder="Descrição ou observação (Ex: Mediante à consulta)"

                       {...register(`description-item-${item.id}`)}
                       helperText="Opcional"
                       defaultValue={item?.description}
                     />

                     </SimpleGrid>

                     <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

                       <Select options={typeOptions} label='Tipo de opcional' {...register(`type-item-${item.id}`)} style={{ backgroundColor: '#fff' }} extraFunction={(e) => handleChangeSelectType(e?.target?.value, item.id)} defaultValue={item?.type} />

                       {!!(watch(`type-item-${item.id}`) === 'NUMERIC') && (
                         <>
                           <Input
                             label="Mínimo"
                             placeholder="Mínimo do campo numérco"

                             {...register(`min-item-${item.id}`)}
                             defaultValue={item?.min}

                           />

                         <Input
                             label="Máximo"
                             placeholder="Máximo do campo numérco"

                             {...register(`max-item-${item.id}`)}
                             defaultValue={item?.max}

                           />
                         </>
                       )}

                       {!!(watch(`type-item-${item.id}`) !== 'NUMERIC') && (
                         <Select options={multiOptions} label='O usuário pode selecionar mais de uma opção?' {...register(`multi-item-${item.id}`)} style={{ backgroundColor: '#fff' }} isDisabled={!!(watch(`type-item-${item.id}`) === 'NUMERIC')} defaultValue={item?.multi} />
                       )}

                     </SimpleGrid>

                     {item?.options?.length && <Divider borderColor="#000" backgroundColor="#000" color="#000" />}

                     {!!(watch(`type-item-${item.id}`) !== 'NUMERIC') && (
                           <SimpleGrid spacing={['12', '12']} w="100%" >

                           {item?.options?.map((option, index) => (
                             <>
                               <Flex key={option.id} alignItems="flex-end" w="100%">

                                 <Input
                                   label="Opção"
                                   placeholder="Preencha a opção (Ex: Massagem Shiatsu)"

                                   {...register(`name-option-${option.id}-item-${item.id}`)}
                                   defaultValue={option?.name}

                                 />

                                 <Button
                                     ml="5"
                                     as="button"
                                     size="sm"
                                     fontSize="md"
                                     bg="green.teal"
                                     width="200px"
                                     height="47px"
                                     _hover={{ bgColor: 'gray.500' }}
                                     color="white"
                                     leftIcon={<Icon as={TiDeleteOutline} fontSize="30" color="white" margin="0" marginInlineEnd={0} />}
                                     onClick={() => handleDeleteOption(option, item.id)}
                                     >
                                       Excluir Opção
                                   </Button>

                               </Flex>

                               <Flex>

                               <TextArea

                                 label="Texto de ajuda"
                                 placeholder="Preencha um texto de ajuda os esclarecimentos ao usuário"

                                 {...register(`help-option-${option.id}-item-${item.id}`)}
                                 minHeight={300}
                                 _focus={{ backgroundColor: '#fff' }}
                                 _hover={{ backgroundColor: '#fff' }}
                                 defaultValue={option?.help}
                                 />

                               </Flex>

                               {((item?.options) != null) && (index !== (item?.options?.length - 1)) && <Divider borderColor="#000" backgroundColor="#000" color="#000" />}
                             </>
                           ))}
                         </SimpleGrid>
                     )}

                     {!!(watch(`type-item-${item.id}`) !== 'NUMERIC') && <Button
                       width="100%"
                       as="button"
                       size="lg"
                       fontSize="sm"
                       bg="green.dark"
                       mb="10"
                       _hover={{ bgColor: 'green.teal' }}
                       color="green.light"
                       leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                       onClick={() => handleNewOption(item.id)}
                       >
                       Adicionar Opção
                     </Button>}

                   </VStack>

             ))} */}

         {/* <Button
               as="button"
               size="lg"
               fontSize="sm"
               bg="green.dark"
               mb="10"
               _hover={{ bgColor: 'green.teal' }}
               color="green.light"
               leftIcon={<Icon as={RiAddLine} fontSize="20" />}
               onClick={handleNewItem}
               >
               Adicionar Opcional
             </Button>

           <Divider borderColor="#000" backgroundColor="#000" color="#000" marginBottom="30px" />

           <Heading size="md" fontWeight="normal" color="green.teal" my="6" mt="0">
             Regulamento
           </Heading>

           {!rules?.length && <Text mb="10" color="black.primary">Você ainda não possui nenhum regulamento cadastrado para este serviço.</Text>}

           {rules.map((rule) => (
             <VStack spacing={['9', '9']} mb="5" bg="green.light" p="10" key={rule.id} borderRadius="8px">
               <Flex key={rule.id} justifyContent="flex-end" w="100%">
                   <Button
                       ml="5"
                       as="button"
                       size="sm"
                       fontSize="md"
                       bg="green.teal"
                       width="200px"
                       height="37px"
                       _hover={{ bgColor: 'gray.500' }}
                       color="white"
                       leftIcon={<Icon as={TiDeleteOutline} fontSize="30" color="white" margin="0" marginInlineEnd={0} />}
                       onClick={() => handleDeleteRule(rule.id)}
                       >
                         Excluir Opção
                   </Button>
               </Flex>

               <SimpleGrid spacing={['12', '12']} w="100%" >
                 <Flex>
                   <TextArea
                     label="Regra"
                     placeholder="Ex: A duração deste serviço é de até 4 horas"
                     // errors={errors.short_description}
                     {...register(`description-rule-${rule.id}`)}
                     minHeight={300}
                     _focus={{ backgroundColor: '#fff' }}
                     _hover={{ backgroundColor: '#fff' }}
                     defaultValue={rule?.description}
                   />
                 </Flex>
               </SimpleGrid>
             </VStack>
           ))}

           <Button
             as="button"
             size="lg"
             fontSize="sm"
             bg="green.dark"
             mb="10"
             _hover={{ bgColor: 'green.teal' }}
             color="green.light"
             leftIcon={<Icon as={RiAddLine} fontSize="20" />}
             onClick={handleNewRule}
             >
             Adicionar Item de Regulamento
           </Button>

           <Heading size="md" fontWeight="normal" color="green.teal" my="6" mt="0">
             Tabela de preços
           </Heading> */}

         <Flex mt={['6', '8']} justify="flex-end">
           <HStack>
             {loading
               ? (
               <Spinner />
                 )
               : (
               <>
                 <Button
                   as="a"
                   href="/concierge"
                   bg="green.teal"
                   _hover={{ bgColor: 'gray.500' }}
                   color="white"
                   cursor="pointer"
                 >
                   Cancelar
                 </Button>

                 <Button
                   type="submit"
                   bg="green.dark"
                   _hover={{ bgColor: 'green.teal' }}
                   color="green.light"
                   isLoading={formState.isSubmitting}
                 >
                   Salvar
                 </Button>
               </>
                 )}
           </HStack>
         </Flex>
       </Box>
      )}

      </Flex>
    </Box>
  )
}

export { ConciergeCreate }
