/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  color,
  useToast
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format } from 'date-fns'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Loader } from '../../Components/Loader'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { USERS_STATUS } from '../../Services/contants'
import IClientsProps from '../../interfaces/IClientsProps'
import IOptionsProps from '../../interfaces/IOptionsProps'
import { IPropertiesProps } from '../../interfaces/IPropertiesProps'
import { FindKeyWithPrefix } from '../../utils/findKeyWithPrefix'
import { IQuotasProperty } from '../../interfaces/IQuotasProps'
import { MoneyInput } from '../../Components/Form/MoneyInput'
import { getCurrencyValue } from '../../utils/getCurrencyValue'
import ColorPicker from '../../Components/ColorPicker'

const createFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  quotas: Yup.string().required('Número de cotas é obrigatório')

})

const updateFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  login: Yup.string().required('Login é obrigatório'),
  password: Yup.string(),
  password_confirmation: Yup.string().oneOf(
    [null, Yup.ref('password')],
    'As senhas devem ser iguais'
  ),
  gym_id: Yup.string().required('Selecione uma unidade')
})

const PropertiesCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [loadingGlobal, setLoadingGlobal] = useState(true)
  const [createdAt, setCreatedAt] = useState('')

  const params = useParams()

  const toast = useToast()
  const navigation = useNavigate()
  const [propertyData, setPropertyData] = useState<IPropertiesProps>()
  const [clientsOptions, setClientsOptions] = useState<IOptionsProps[]>([])
  const { register, handleSubmit, formState, setValue, unregister } = useForm({
    resolver: yupResolver(createFormSchema)
  })
  const [colorSelected, setColorSelected] = useState('')
  const { errors } = formState

  const inflationOptions = [
    {
      value: '',
      label: 'Selecionar Índice'
    },
    {
      value: 'IGPM',
      label: 'IGPM'
    },
    {
      value: 'IPCA',
      label: 'IPCA'
    }
  ]

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values): Promise<void> => {
      setLoading(true)
      const {
        name,
        quotas
      } = values

      const quotasClients = FindKeyWithPrefix(values, 'quota-')

      const items = quotasClients?.map((i: any) => {
        const getId = i.key.split('quota-')

        const price = values[`price-${getId[1]}`]
        const inflation_index = values[`inflation_index-${getId[1]}`]

        return {
          id: getId[1],
          user_id: i.value || null,
          price: price ? getCurrencyValue(values[`price-${getId[1]}`]) : '',
          inflation_index
        }
      })

      const payload = {
        name,
        quotas,
        quotas_details: items,
        color: colorSelected

      }

      console.log('payload: ', payload)

      let id = params?.id

      try {
        if (id) {
          await api.put(`/properties/${id}`, payload)
        } else {
          const { data } = await api.post('/properties', payload)
          id = data?.properties?.id
        }

        toast({
          title: `${id ? 'Editado' : 'Cadastrado'} com sucesso`,
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })

        setLoading(false)
        navigation('/properties')
      } catch (error) {
        console.log('error: ', error)
        setLoading(false)
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    },
    [toast, params, navigation, colorSelected]
  )

  const loadItem = useCallback(async () => {
    try {
      const { id } = params
      const resp = await api.get(`/properties/${String(id)}`, {
        params: {
          quotas: true
        }
      })
      const data: IPropertiesProps = resp.data

      setValue('name', data.name)
      setValue('quotas', data.quotas)
      setColorSelected(data?.color || '#000')

      setPropertyData(data)
      const creationDate = format(new Date(data.created_at), 'dd/MM/yyyy')
      setCreatedAt(creationDate)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue, params])

  const loadClients = useCallback(async () => {
    try {
      const resp = await api.get('/users', {
        params: {
          pag: 1,
          all: true,
          active: USERS_STATUS.ACTIVE
        }
      })
      const data: any = resp.data

      const normalize = data?.data?.map((client: IClientsProps) => {
        return {
          value: client.id,
          label: client.name
        }
      })

      const options = [
        {
          value: '',
          label: 'Selecionar Cliente'
        },
        ...normalize
      ]

      console.log('normalize: ', normalize)

      setClientsOptions(options)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoadingGlobal(false)
    }
  }, [toast])

  useEffect(() => {
    const load = async () => {
      if (params?.id) {
        await loadItem()
        await loadClients()
      } else {
        setLoadingGlobal(false)
      }
    }

    load()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const handleSelectColor = useCallback((color: string) => {
    setColorSelected(color)
  }, [])

  return (
    <Box>
      <Header />
      {loading && <Loader />}
      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="green.dark">
           {params?.id ? 'Editar Casa' : 'Criar nova Casa'}
          </Heading>
          <Divider my="6" borderColor="green.dark" />

          <Heading size="md" fontWeight="bold" color="green.teal" my="8">
            Dados Cadastrais

            {createdAt && <Text size="sm" fontSize="14">Data de inclusão: {createdAt}</Text> }
          </Heading>

          <VStack spacing={['6', '8']}>
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <Input
                label="Nome"
                placeholder="Preencha o nome"
                errors={errors.name}
                {...register('name')}
              />

              <Input
                label="Quantidade de Cotas"
                placeholder="Preencha as cotas"
                isDisabled={!!params?.id}
                errors={errors.quotas}
                {...register('quotas')}
              />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <ColorPicker onSubmit={handleSelectColor} color={colorSelected} />

            </SimpleGrid>

          </VStack>

          {params?.id && !loadingGlobal && (
            <>
                 <Heading size="md" fontWeight="bold" color="green.teal" my="8">
                  Cotas
                </Heading>

                <VStack>
                  {propertyData?.quotas_details?.map(quota => (
                    <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" key={quota.id}>
                    <Select label={quota.name} {...register(`quota-${quota.id}`)} options={clientsOptions}
                     defaultValue={quota?.user_id}
                      />

                      <MoneyInput

                      label="Preço negociado"
                      placeholder="Preencha o valor"
                      // extraFunction={(e: any) => CalculateBDI(item, e)}
                      // errors={errors.name}
                      defaultValue={quota?.price ? Number(quota?.price) : null}
                      {...register(`price-${quota.id}`)}

                    />

                    <Select label="Índice inflacionário da venda" {...register(`inflation_index-${quota.id}`)} options={inflationOptions}
                     defaultValue={quota?.inflation_index}
                      />
                    </SimpleGrid>
                  ))}

                </VStack>
            </>
          )}

          <VStack spacing={['6', '8']}>

            {loading && (
              <Flex justify="center">
                <Spinner />
              </Flex>
            )}

          </VStack>

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="green.teal"
                _hover={{ bgColor: 'green.dark' }}
                color="white"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="green.dark"
                _hover={{ bgColor: 'green.light' }}
                color="white"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export { PropertiesCreate }
