/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  VStack,
  useToast
} from '@chakra-ui/react'
import { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
// import { CKEditor } from '@ckeditor/ckeditor5-react'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { DragAndDrop, DragAndDropExportedProps } from '../../Components/Form/DragAndDrop'
import { Input } from '../../Components/Form/Input'
import { TextArea } from '../../Components/Form/TextArea'
import { Header } from '../../Components/Header'
import { Loader } from '../../Components/Loader'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import IFilesProps from '../../interfaces/IFilesProps'
import { MidiaProps } from '.'

interface OptionsProps {
  label: string
  value: string
}

interface TagsProps {
  id: string
  tag_id: string
  name: string
}

const createFormSchema = Yup.object().shape({
  title: Yup.string().required('Título é obrigatório.'),
  link: Yup.string().required('Link é obrigatório.'),
  short_description: Yup.string().required('Descrição curta é obrigatória.'),
  author: Yup.string()
})

const MidiaCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState<MidiaProps>({} as MidiaProps)
  const params = useParams()
  const location = useLocation()
  const toast = useToast()
  const navigate = useNavigate()
  const [files, setFiles] = useState<IFilesProps[]>()
  const [postDescription, setPostDescription] = useState('')
  const CoverRef = useRef<DragAndDropExportedProps>(null)

  const [postCover, setPostCover] = useState<undefined | string>(undefined)

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      setLoading(true)
      const {
        title,
        link,
        short_description,
        author
      } = values

      console.log('values: ', values)

      const payload = {
        title,
        link,
        short_description,
        author,
        description: postDescription
      }

      console.log('payload: ', payload)

      console.log('post dentro: ', post)
      try {
        let id = post?.id || null
        console.log('id: ', id)
        console.log('post id: ', post?.id)

        if (id) {
          await api.put(`/midia/${id}`, payload)
        } else {
          const { data } = await api.post('/midia', payload)
          id = data?.post?.id
        }

        const allFiles: Array<RefObject<DragAndDropExportedProps>> = [CoverRef]

        // console.log('allFiles:', allFiles)

        const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

        console.log('normalizeInputsWithContent:', normalizeInputsWithContent)

        await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
          const cb = index === normalizeInputsWithContent?.length - 1
            ? () => {
                toast({
                  title: `${params?.slug ? 'Editado' : 'Cadastrado'} com sucesso`,
                  position: 'top',
                  isClosable: true,
                  status: 'success',
                  variant: 'solid'
                })
                navigate('/midia')
                setLoading(false)
              }
            : () => {}
          inputDrag?.current?.execute(`/files/${String(id)}`, cb)
        }))

        if (!normalizeInputsWithContent?.length) {
          toast({
            title: `${params?.slug ? 'Editado' : 'Cadastrado'} com sucesso`,
            position: 'top',
            isClosable: true,
            status: 'success',
            variant: 'solid'
          })
          navigate('/midia')
          setLoading(false)
        }
      } catch (error) {
        toast({
          title: error?.response?.data?.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        setLoading(false)
      }
    },
    [toast, navigate, params, postDescription, post]
  )

  const loadItem = useCallback(async () => {
    try {
      const resp = await api.get(`/midia/${params?.slug}`)
      const data = resp.data
      console.log('data: ', data)

      setValue('title', data.title)
      setValue('link', data.link)
      setValue('author', data.author)
      setPost(data)

      setValue('short_description', data.short_description)
      setPostDescription(data.description)

      // setPostCover(data.files.find((i: any) => i.type === 'midia')?.link)

      // setFiles(data.files)

      // setInitialCNHCover(data.files.find(i => i.type === 'cnh-ou-identidade')?.link)
      // setInitialWorkWalletCover(data.files.find(i => i.type === 'carteira-de-trabalho')?.link)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue, params])

  useEffect(() => {
    if (params?.slug) {
      loadItem()
    }
  }, [params])

  return (
    <Box>
      <Header />

       {loading && <Loader />}
      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="green.dark">
            Criar nova Mídia
          </Heading>
          <Divider my="6" borderColor="green.dark" />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Dados Cadastrais
          </Heading>

          <VStack spacing={['6', '8']}>

          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

            <Input
                label="Link"
                placeholder="Preencha o link"
                errors={errors.link}
                {...register('link')}
            />

            <Input
                label="Veículo de Imprensa"
                placeholder="Preencher o veículo de imprensa"
                errors={errors.author}
                {...register('author')}
            />

          </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <Input
                    label="Título"
                    placeholder="Preencha o Título do Post"
                    errors={errors.title}
                    {...register('title')}
                />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <TextArea

                    label="Descrição Curta"
                    placeholder="Preencha a Descrição (chamada curta)"
                    errors={errors.short_description}
                    {...register('short_description')}
            />

            </SimpleGrid>

            {/* <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <DragAndDrop
                ref={CoverRef}
                label="Capa da Matéria"
                containerStyle={{ width: '100%' }}
                payload={{
                  name: 'midia',
                  type: 'midia'
                }}
                defaultCover={postCover}
                id={files?.length ? files.find(i => i.type === 'midia')?.id : null}
              />

            </SimpleGrid> */}

            <SimpleGrid spacing={['6', '9']} w="100%" >
              <ReactQuill
                theme="snow"
                value={postDescription}
                onChange={setPostDescription}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['link', 'image'],
                    ['clean']
                  ]
                }}
                formats={[
                  'header',
                  'bold', 'italic', 'underline', 'strike', 'blockquote',
                  'list', 'bullet', 'indent',
                  'link', 'image'
                ]}

                style={{ minHeight: 300 }} />

              </SimpleGrid>

          </VStack>

          <Flex mt={['20', '20']} justify="flex-end" >
            <HStack>
              <Button
                as="a"
                bg="green.teal"
                _hover={{ bgColor: 'gray.500' }}
                color="green.dark"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="green.dark"
                _hover={{ bgColor: 'gray.500' }}
                color="green.light"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export { MidiaCreate }
