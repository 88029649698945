/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import api from '../../Services/api'
import ISchedulesProps from '../../interfaces/ISchedulesProps'
import {
  Box, Button, Flex, Heading, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spinner, Tab, TabList, TabPanel, TabPanels, Table, Tabs, Tbody, Td, Text, Th, Thead, Tr, VStack, Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  useToast,
  theme

} from '@chakra-ui/react'
import { Pagination } from '../Pagination'
import { RiAddFill, RiEye2Fill, RiEyeFill } from 'react-icons/ri'
import { AiOutlineDelete } from 'react-icons/ai'
import { format, parseISO } from 'date-fns'
import { FormatMoney } from '../../utils/formatMoney'
import IScheduleConciergeProps, { IScheduleConciergeOptionsProps } from '../../interfaces/IScheduleConciergeProps'
import { Select } from '../Form/Select'
import { getConciergeStatus } from '../../utils/getConciergeStatus'
import { SCHEDULE_CONCIERE_ITEM_STATUS } from '../../Services/contants'
import IScheduleConciergeItemDTO from '../../interfaces/IScheduleConciergeItemDTO'
import IScheduleConciergePaymentProps from '../../interfaces/IScheduleConciergePaymentProps'
import { AlertDialogCustom } from '../AlertDialog'
import IPaymentDetailsProps, { TPaymentStatus } from '../../interfaces/IPaymentDetailsProps'

interface Props {
  appointment_id: string
  onClose: () => void
  reloadFunction: () => void
}

const statusOptions = [
  {
    label: 'Solicitado',
    value: 'CREATED'
  },
  // {
  //   label: 'Confirmado',
  //   value: 'CONFIRMED'
  // },
  {
    label: 'Pago',
    value: 'PAID'
  },
  {
    label: 'Cancelado',
    value: 'CANCELED'
  }
]

const ModalAppointmentDetails: FC<Props> = ({ appointment_id, onClose, reloadFunction }) => {
  const [error, setError] = useState(false)
  const [data, setData] = useState<ISchedulesProps>({} as ISchedulesProps)
  const [loading, setLoading] = useState(false)
  const [showAppointment, setShowAppointment] = useState(true)
  const [showNewPayment, setShowNewPayment] = useState(false)
  const [paymentDetailsId, setPaymentDetailsId] = useState('')
  const [scheduleItemData, setScheduleItemData] = useState<IScheduleConciergeItemDTO[]>([])
  const [conciergeSelected, setConciergeSelected] = useState<IScheduleConciergeItemDTO[]>([])
  const [schedulePayments, setSchedulePayments] = useState<IScheduleConciergePaymentProps[]>([])
  const [paymentDetail, setPaymentDetail] = useState<IPaymentDetailsProps>()
  const [selectedItem, setSelectedItem] = useState('')
  const [selectedItemConfirmation, setSelectedItemConfirmation] = useState('')
  const [selectedItemRemove, setSelectedItemRemove] = useState('')
  const toast = useToast()

  const handleBackNewPayment = useCallback(() => {
    setShowNewPayment(false)
    setShowAppointment(true)
    setConciergeSelected([])
    setPaymentDetailsId('')
  }, [])

  const handleSelectConciergeItem = useCallback((item: IScheduleConciergeItemDTO) => {
    const exists = conciergeSelected.find(i => i.concierge.id === item.concierge.id)

    if (exists != null) {
      const filtered = conciergeSelected.filter(i => i.concierge.id !== item.concierge.id)
      setConciergeSelected(filtered)
    } else {
      setConciergeSelected(prev => [...prev, item])
    }
  }, [conciergeSelected])

  const loadSchedule = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get(`/schedules/${appointment_id}`)
      console.log('data: ', data)

      const normalize = {
        ...data,
        date_initial: format(parseISO(data.checkIn), 'dd/MM/yyyy'),
        date_final: format(parseISO(data.checkOut), 'dd/MM/yyyy'),
        services: data.services.map((item: any) => {
          return {
            ...item,
            dates: item.dates.map((date: any) => format(parseISO(date), 'dd/MM/yyyy'))
          }
        })
      }

      setData(normalize)
    } catch (error) {
      console.log('erro ao pegar reserva: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [appointment_id])

  const loadOpenedServices = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get(`/concierges-services/${appointment_id}`, {
        params: {
          status: SCHEDULE_CONCIERE_ITEM_STATUS.CREATED
        }
      })
      console.log('data: ', data)

      setScheduleItemData(data)
    } catch (error) {
      console.log('erro ao pegar reserva: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [appointment_id])

  const loadPayments = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get('/concierge-payment', {
        params: {
          schedule_id: appointment_id
        }
      })
      console.log('data: ', data)

      setSchedulePayments(data)
    } catch (error) {
      console.log('erro ao pegar os paagmentos: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [appointment_id])

  useEffect(() => {
    loadSchedule()
    loadOpenedServices()
    loadPayments()
  }, [])

  const normalizePrice = useCallback((conciergeItem: IScheduleConciergeProps) => {
    const sumAmountItems: string[] = []

    const subItensSelected = conciergeItem.options

    subItensSelected?.forEach(item => {
      sumAmountItems.push(item.price)
    })

    const sumValues = sumAmountItems
      .map(Number)
      .reduce((acc, val) => acc + val, 0)
    const basePriceMoreDays = Object.keys(conciergeItem.dates).length * Number(conciergeItem.price)
    return basePriceMoreDays + sumValues
  }, [])

  const normalizePriceScheduleConciergeItem = useCallback((conciergeItem: IScheduleConciergeItemDTO) => {
    const sumAmountItems: string[] = []

    const subItensSelected = conciergeItem.options

    subItensSelected?.forEach(item => {
      sumAmountItems.push(String(item.price))
    })

    const sumValues = sumAmountItems
      .map(Number)
      .reduce((acc, val) => acc + val, 0)

    const basePriceMoreDays = Object.keys(conciergeItem.conciergeItem.dates).length * Number(conciergeItem.conciergeItem.price)
    return basePriceMoreDays + sumValues
  }, [])

  const normalizeNewPaymentPrice = useMemo(() => {
    const sumAmountOptions: string[] = []
    const sumAmountBaseValueAndDates: string[] = []

    conciergeSelected?.forEach(selected => {
      sumAmountOptions.push(String(Number(selected.conciergeItem.price) * selected.conciergeItem.dates?.length))
      selected.options.forEach(item => {
        sumAmountOptions.push(String(item.price))
      })
    })

    // conciergeSelected?.forEach(selected => {
    //   sumAmountBaseValueAndDates.push(String(selected.conciergeItem.price))
    //   selected.options.forEach(item => {
    //     sumAmountOptions.push(String(item.price))
    //   })
    // })

    const sumValues = sumAmountOptions
      .map(Number)
      .reduce((acc, val) => acc + val, 0)

    // const basePriceMoreDays = Object.keys(conciergeItem.price).length * Number(conciergeItem.price)

    return sumValues
  }, [conciergeSelected])

  const handleChangeStatus = useCallback((value: any, concierge: any) => {
    console.log('handleChangeStatus value: ', value)
    console.log('handleChangeStatus concierge: ', concierge)
    if (value === '') return

    if (value !== concierge.status) {
      console.log('troucar')
    }
  }, [])

  const handleShowNewAppointment = useCallback(() => {
    setShowAppointment(false)
    setShowNewPayment(true)
    setPaymentDetailsId('')
  }, [])

  const showPayment = useCallback(async (id: string) => {
    setLoading(true)
    try {
      const { data } = await api.get(`/concierge-payment/${id}`)
      console.log('data: ', data)

      setPaymentDetail(data)
    } catch (error) {
      console.log('erro ao pegar os pagmentos: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  const normalizeStatus = (status: TPaymentStatus): string => {
    const type = {
      REQUESTED: 'Solicitado',
      PAID: 'Pago',
      PROCESSING: 'Pagamento informado pelo usuário'
    }

    return type[status]
  }

  const handleNewPayment = useCallback(async () => {
    try {
      if (!conciergeSelected?.length) {
        toast({
          title: 'Selecione pelo menos 1 serviço de concierge',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        return
      }

      const payload = {
        price: normalizeNewPaymentPrice,
        schedule_concierges_item: conciergeSelected.map(item => {
          const sumAmountItems: string[] = []

          const subItensSelected = item.options

          subItensSelected?.forEach(i => {
            sumAmountItems.push(String(i.price) || '0')
          })

          const sumValues = sumAmountItems
            .map(Number)
            .reduce((acc, val) => acc + val, 0)
          const basePriceMoreDays = Object.keys(item.conciergeItem.dates).length * Number(item.conciergeItem.price)
          const total = basePriceMoreDays + sumValues

          return {
            schedule_concierges_item: item.conciergeItem.id,
            price: total
          }
        })
      }

      await api.post(`/concierge-payment/${appointment_id}`, payload)

      toast({
        title: 'Pagamento criado',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })

      loadOpenedServices()
      setShowAppointment(true)
      setShowNewPayment(false)
      setConciergeSelected([])
      loadSchedule()

      loadPayments()

      console.log('payload: ', payload)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, conciergeSelected, normalizeNewPaymentPrice, appointment_id, loadPayments, loadOpenedServices, loadSchedule])

  const hanleDelete = useCallback(async () => {
    try {
      await api.delete(`/concierge-payment/${selectedItem}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem('')

      loadPayments()
      loadOpenedServices()
      loadSchedule()
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem('')
    }
  }, [selectedItem, toast, loadPayments, loadOpenedServices, loadSchedule])

  const closeModal = useCallback(() => {
    setSelectedItem('')
    setSelectedItemConfirmation('')
    setSelectedItemRemove('')
  }, [])

  const handleConfirmPayment = useCallback(async () => {
    try {
      await api.put(`/concierge-payment/${paymentDetailsId}`)

      toast({
        title: 'Pagamento confirmado',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })

      loadOpenedServices()
      setShowAppointment(true)
      setShowNewPayment(false)
      setConciergeSelected([])
      setPaymentDetailsId('')
      setPaymentDetail({} as IPaymentDetailsProps)

      closeModal()

      loadSchedule()
      loadPayments()
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, paymentDetailsId, loadOpenedServices, loadPayments, loadSchedule, closeModal])

  const getTitles = useMemo(() => {
    if (showAppointment) {
      return 'Detalhes do Agendamento'
    }

    if (showNewPayment) {
      return 'Novo Pagamento'
    }

    if (paymentDetailsId !== '') {
      return 'Detalhes do Pagamento'
    }
  }, [showAppointment, showNewPayment, paymentDetailsId])

  useEffect(() => {
    if (paymentDetailsId) {
      showPayment(paymentDetailsId)
    }
  }, [paymentDetailsId])

  const handlePaymentDetails = useCallback((id: string) => {
    setPaymentDetailsId(id)
    setShowAppointment(false)
    setShowNewPayment(false)
  }, [])

  const hanleDeleteSchedule = useCallback(async () => {
    try {
      await api.delete(`/schedules/${selectedItemRemove}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItemRemove('')

      loadPayments()
      loadOpenedServices()
      loadSchedule()
      reloadFunction()
      onClose()
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItemRemove('')
    }
  }, [selectedItemRemove, toast, loadPayments, loadOpenedServices, loadSchedule, onClose, reloadFunction])

  return (

    <>

        {!!selectedItemRemove && (
          <AlertDialogCustom
            title="Você tem certeza?"
            isOpen={!!selectedItemRemove}
            cancelFunction={closeModal}
            nextFunction={hanleDeleteSchedule}
          />
        )}

        {!!selectedItem && (
          <AlertDialogCustom
            title="Deletar Pagamento?"
            isOpen={!!selectedItem}
            cancelFunction={closeModal}
            nextFunction={hanleDelete}
          />
        )}

{ !!selectedItemConfirmation && (
          <AlertDialogCustom
            title="Confirmar Pagamento?"
            isOpen={!!selectedItemConfirmation}
            cancelFunction={closeModal}
            nextFunction={handleConfirmPayment}
          />
)}

        {!selectedItem && !selectedItemConfirmation && (
          <Modal isOpen onClose={onClose} size="6xl">
          <ModalOverlay />
          <Box

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"

        >
          <ModalContent>
            <ModalHeader mb="0" pb="0">{getTitles}</ModalHeader>

            <ModalCloseButton />

            <ModalBody>
              {showAppointment && (
                  <Box flex="1" borderRadius={8} bg="white" p="8">

                  { loading ? (
                  <Flex justify="center">
                  <Spinner />
                  </Flex>
                  ) : error ? (
                  <Flex justify="center">
                  <Text>Erro ao buscar os dados deste agendamento.</Text>
                  </Flex>
                  ) : (
                  <>

                  <Box flex="1" borderRadius={8} bg="white">
                      <Flex mb="8" justify="space-between" align="center">
                          <Heading fontSize="25px" fontWeight="bold" color="gray.500">
                          Dados da Reserva
                          </Heading>
                      </Flex>
                  </Box>

                  <VStack spacing={['12', '12']} mb="50px">
                  <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px" >
                      <VStack>

                      <Box width="200px" height="80px">
                          <Text fontSize="21px" color="green.teal" fontWeight="bold">Casa</Text>
                          <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.property?.name}</Text>
                      </Box>

                      <Box width="200px" height="80px">
                          <Text fontSize="21px" color="green.teal" fontWeight="bold">Sócio</Text>
                          <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.user?.name}</Text>
                          <Text fontSize="16px" color="green.dark" fontWeight="400">{data?.user?.phone}</Text>
                      </Box>

                      </VStack>

                      <VStack>

                      <Box width="200px" height="80px">
                          <Text fontSize="21px" color="green.teal" fontWeight="bold">Check In</Text>
                          <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.date_initial}</Text>
                      </Box>

                      <Box width="200px" height="80px">
                          <Text fontSize="21px" color="green.teal" fontWeight="bold" align="left">Cota</Text>
                          <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.quota?.name}</Text>
                      </Box>

                      </VStack>

                      <VStack>

                      <Box width="250px" height="80px">
                          <Text fontSize="21px" color="green.teal" fontWeight="bold">Check Out</Text>
                          <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.date_final}</Text>
                      </Box>

                      <Box width="250px" height="80px">
                          <Text fontSize="21px" color="green.teal" fontWeight="bold">Tipo de Reserva</Text>
                          <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.type?.name}</Text>
                      </Box>

                      </VStack>

                  </SimpleGrid>

                  </VStack>

                  <Box flex="1" mt="10" mb="8" borderRadius={8} bg="white">
                  <Flex justify="space-between" align="center">
                  <Heading fontSize="25px" fontWeight="bold" color="gray.500">
                  Informações
                  </Heading>
                  </Flex>
                  </Box>

                  <Tabs variant='enclosed'>
                  <TabList>
                  <Tab>Serviços de concierge solicitados</Tab>
                  <Tab>Histórico de Pagamentos</Tab>

                  </TabList>

                  <TabPanels>
                  <TabPanel>
                      <Accordion allowMultiple mt="30px">

                      { data?.services?.map(i => (
                          <AccordionItem key={i.id}>

                              <AccordionButton>
                                  <Box as='span' flex='1' textAlign='left'>
                                  {i.concierge?.name}
                                  </Box>
                                  <AccordionIcon />
                              </AccordionButton>

                              <AccordionPanel pb={4}>
                              <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px" mt="30px" >
                                  <Box width="400px" height="80px">
                                      <Text fontSize="21px" color="green.teal" fontWeight="bold">Status Atual</Text>
                                      <Text fontSize="18px" color="green.dark" fontWeight="bold">{getConciergeStatus(i.status)}</Text>
                                  </Box>

                              {/* <Select name='status' options={statusOptions} onChange={e => handleChangeStatus(e.target.value, i)} label='Mudança de status' /> */}
                              </SimpleGrid>

                              <Table colorScheme="blue" mt="20px">
                                      <Thead>
                                          <Tr>

                                              <Th textAlign="left" backgroundColor="green.light" color="black" fontWeight="700">Nome</Th>
                                              <Th w={8} textAlign="center" backgroundColor="green.light" color="black" fontWeight="700">Valor</Th>

                                          </Tr>
                                      </Thead>
                                      <Tbody>
                                      <Tr bg="green.dark">
                                          <Td>

                                              <Text fontWeight="700" color="white" >DATAS SELECIONADAS</Text>

                                          </Td>

                                          <Td>

                                          </Td>
                                      </Tr>

                                      {i?.dates?.map(date => (
                                          <Tr bg="gray.100" key={date}>
                                          <Td>

                                              <Text fontWeight="700" color="black" ml="15px">{date}</Text>

                                          </Td>
                                          <Td> <Text fontWeight="700" color="black" textAlign="center" >{i.price && i.price !== '0.00' ? FormatMoney(Number(i?.price)) : '--'}</Text></Td>
                                      </Tr>
                                      ))}

                                      <Tr bg="green.dark">
                                          <Td>

                                              <Text fontWeight="700" color="white">HORÁRIO</Text>

                                          </Td>

                                          <Td>

                                          </Td>
                                      </Tr>

                                          <Tr bg="gray.100" >
                                          <Td>

                                              <Text fontWeight="700" color="black" ml="15px">{i?.hour}</Text>

                                          </Td>

                                          <Td>
                                          <Text fontWeight="700" color="black" textAlign="center" >--</Text>
                                          </Td>
                                      </Tr>

                                      {i?.options?.map(option => (
                                          <>
                                              <Tr bg="green.dark">
                                                  <Td>

                                                      <Text fontWeight="700" color="white">{option?.question?.name.toUpperCase()}</Text>

                                                  </Td>

                                                  <Td>

                                                  </Td>
                                              </Tr>

                                              <Tr bg="gray.100" >
                                                  <Td>

                                                      <Text fontWeight="700" color="black" ml="15px">{option?.numeric_value ?? option?.answer?.name}</Text>

                                                  </Td>

                                                  <Td>
                                                      <Text fontWeight="700" color="black" ml="15px" fontSize="16px">{FormatMoney(Number(option?.price))}</Text>

                                                  </Td>
                                              </Tr>

                                          </>

                                      ))}

                                      <Tr bg="green.light" >
                                          <Td>

                                              <Text fontWeight="700" color="black" ml="15px">TOTAL DO SERVIÇO</Text>

                                          </Td>

                                          <Td>
                                          <Text fontWeight="700" color="black" textAlign="center" fontSize="18px" >{FormatMoney(normalizePrice(i))}</Text>
                                          </Td>
                                      </Tr>

                                      </Tbody>
                              </Table>

                              </AccordionPanel>
                          </AccordionItem>
                      ))}

                      </Accordion>

                  </TabPanel>

                  <TabPanel>
                      <>
                      <Flex w="100%" justifyContent="flex-end">
                          <Button
                              onClick={handleShowNewAppointment}
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              bg="green.dark"
                              colorScheme="wine"
                              leftIcon={
                              <Icon as={RiAddFill} fontSize="16" />
                              }
                          >
                              Novo Pagamento
                          </Button>
                      </Flex>
                          <Table colorScheme="blue" mt='30px'>
                              <Thead>
                                  <Tr>
                                      <Th>Valor</Th>

                                      <Th>Status</Th>

                                      <Th w="8"></Th>
                                  </Tr>
                              </Thead>
                              <Tbody>

                                  { schedulePayments.map(i => (
                                      <Tr key={i.id}>
                                         <Td>
                                              <Text fontWeight="bold">{FormatMoney(Number(i?.price))}</Text>
                                          </Td>
                                          <Td>
                                              <Box><Text fontWeight="bold" color="green.dark">{normalizeStatus(i?.status)}</Text></Box>
                                          </Td>

                                          {/* <Td>
                                              <Text fontWeight="bold">{i?.status_class.name}</Text>
                                          </Td> */}

                                          <Td>
                                          <Flex gap='10px'>
                                          <Button as="button" onClick={() => handlePaymentDetails(i?.id)} size="sm" color={theme.colors.white} fontSize="sm" bg='green.teal' leftIcon={<Icon as={RiEyeFill} color={theme.colors.white} fontSize="16" />}>
                                              Detalhes
                                          </Button>

                                          <Button as="button" onClick={() => setSelectedItem(i?.id)} size="sm" fontSize="sm" colorScheme="red" leftIcon={<Icon as={AiOutlineDelete} fontSize="16" />}>
                                              Excluir
                                          </Button>
                                          </Flex>
                                          </Td>
                                      </Tr>

                                  ))}
                              </Tbody>
                          </Table>

                          {/* <Pagination
                              totalCountOfRegisters={totalClasses}
                              currentPage={pageClasses}
                              onPageChange={setPageClasses}
                          /> */}
                      </>
                  </TabPanel>

                  </TabPanels>
                  </Tabs>
                  </>
                  )}

                  </Box>
              )}

              {showNewPayment && (
                  <Box mt='20px'>
                      <Text fontSize="18px" color="green.dark" fontWeight="bold">Selecione quais serviços irão fazer parte deste pagamento:</Text>

                      <Box mt='20px'>
                        {!scheduleItemData?.length && <Text fontSize="16px" color="green.dark" fontWeight="400">Não existem serviços pendentes de pagamento.</Text>}
                          {scheduleItemData?.map(item => (
                              <Flex key={item.concierge.id}>
                                  <Checkbox
                                      key={item.concierge.id}
                                      color="green.dark"
                                      value={item.concierge.id}
                                      name={`concierge-id-${item.concierge.id}`}
                                      style={{ padding: 5, color: '#000', cursor: 'pointer' }}
                                      onChange={(e) =>
                                        handleSelectConciergeItem(item)
                                      }
                                  >
                                      {item.concierge.name} - {normalizePriceScheduleConciergeItem(item)}
                                  </Checkbox>
                                  <Text></Text>
                              </Flex>
                          ))}
                      </Box>

                  </Box>
              )}

              {paymentDetailsId !== '' && paymentDetail?.id && selectedItemConfirmation === '' && (
                <Box mt='20px'>
                  <Text fontSize="18px" color="green.dark" fontWeight="bold"><b>Status:</b> {normalizeStatus(paymentDetail.status)}</Text>

                  <Box mt='20px'>
                    <Text fontSize={21}><b>Valor:</b> {FormatMoney(Number(paymentDetail.price))}</Text>
                    <Text my='20px' fontSize='19px'><b>Serviços de concierge pagos neste pagamento:</b></Text>
                      {paymentDetail.items.map(item => <Text key={item.id} fontSize='18px'>{item.concierge.name} - {FormatMoney(Number(item.price))}</Text>)}
                  </Box>

                </Box>
              )}
            </ModalBody>

            <ModalFooter>

              <Flex justifyContent='space-between' w='100%'>
                {(!showNewPayment || paymentDetailsId !== '') && <Box />}
                {showNewPayment && !scheduleItemData?.length && <Box />}
                {showNewPayment && !!scheduleItemData?.length && <Text>Total do pagamento: {FormatMoney(normalizeNewPaymentPrice)}</Text>}

                  <Flex alignItems='center'>
                      {showNewPayment && (
                          <Button
                          type="button"
                          onClick={handleBackNewPayment}
                          _hover={{ bgColor: 'green.teal' }}
                          mr='10px'
                          >
                          Voltar
                          </Button>
                      )}

                      <Button
                          as="button"
                          onClick={() => {
                            console.log('appointment_id: ', appointment_id)
                            setSelectedItemRemove(appointment_id)
                            setSelectedItem('')
                          }}
                          size="sm"
                          fontSize="sm"
                          colorScheme="red"
                          leftIcon={
                            <Icon as={AiOutlineDelete} fontSize="16" />
                          }
                        >
                        Excluir Agendamento
                      </Button>

                      {showAppointment && (
                          <Button variant='ghost' mr={3} onClick={onClose}>
                          Fechar
                        </Button>
                      )}

                      {showNewPayment && !!scheduleItemData?.length && (
                          <Button
                          type="button"
                          bg="green.dark"
                          _hover={{ bgColor: 'green.teal' }}
                          color="green.light"
                          onClick={handleNewPayment}

                          >
                          Salvar
                          </Button>
                      )}

                      {paymentDetailsId !== '' && (
                        <>

                          <Button
                          type="button"
                          onClick={handleBackNewPayment}
                          _hover={{ bgColor: 'green.teal' }}
                          mr='10px'
                          >
                          Voltar
                          </Button>

                         <Button
                         type="button"
                         bg="green.dark"
                         _hover={{ bgColor: 'green.teal' }}
                         color="green.light"
                         onClick={() => setSelectedItemConfirmation(paymentDetailsId)}

                         >
                         Confirmar Pagamento
                         </Button>
                         </>
                      )}
                  </Flex>
              </Flex>

            </ModalFooter>

          </ModalContent>
          </Box>
        </Modal>
        )}

    </>

  )
}

export { ModalAppointmentDetails }
