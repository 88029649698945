import React, { FC } from "react";

import { AuthProvider } from "./Auth";
import { SideBarProvider } from "./SideBarContext";
import { ConciergeProvider } from './Concierge'

const AppProvider: FC<{ children: React.ReactNode }> = ({ children }) => (
  <AuthProvider>
    <SideBarProvider><ConciergeProvider>{children}</ConciergeProvider></SideBarProvider>
  </AuthProvider>
);

export default AppProvider;
