const getFileExtension = (file: File): string => {
  // Obtém o nome do arquivo
  const fileName = file.name

  // Procura pelo último ponto no nome do arquivo
  const lastDotIndex = fileName.lastIndexOf('.')

  // Se não houver ponto no nome do arquivo, retorna uma string vazia
  if (lastDotIndex === -1) {
    return ''
  }

  // Retorna a substring após o último ponto, que é a extensão
  return fileName.slice(lastDotIndex + 1)
}

export { getFileExtension }
