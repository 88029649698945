/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable import/extensions */
/* eslint-disable multiline-ternary */
import { Box, Button, Flex, Heading, Icon, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { RiAddLine, RiFilter2Fill } from 'react-icons/ri'
import { CalendarSchedule } from '../../Components/CalendarSchedule'
import { Header } from '../../Components/Header'
import { FilterScheduleModal } from '../../Components/Modals/FilterScheduleModal'
import { Sidebar } from '../../Components/Sidebar'
import { IPropertiesProps } from '../../interfaces/IPropertiesProps'
import ISchedulesProps from '../../interfaces/ISchedulesProps'
import api from '../../Services/api'
import { SCHEDULES_STATUS } from '../../Services/contants'

const Schedule = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<ISchedulesProps[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [houses, setHouses] = useState<IPropertiesProps[]>([])
  const [showModalFilter, setShowModalFilter] = useState(false)
  const [houseFiltered, setHouseFiltered] = useState<string[]>([])

  const load = useCallback(async (pag: number, properties?: string[]) => {
    setLoading(true)
    try {
      const { data } = await api.get('/schedules', {
        params: {
          pag,
          all: true,
          property_id: properties,
          status: [SCHEDULES_STATUS.CREATED, SCHEDULES_STATUS.USED]
        }
      })

      const normalize = data?.data?.map((schedule: ISchedulesProps) => {
        return {
          ...schedule,
          date_initial: format(new Date(schedule.date_initial), 'yyyy-MM-dd'),
          date_final: format(new Date(schedule.date_final), 'yyyy-MM-dd')
        //   created_at: format(new Date(schedule.created_at), 'dd/MM/yyyy')
        }
      })

      console.log('normalize: ', normalize)

      setPage(pag)
      setData(normalize)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar reservas: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  const loadProperties = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get('/properties', {
        params: {
          pag: 1,
          all: true
        }
      })

      setHouses(data?.data)
      setHouseFiltered(data?.data?.map((i: any) => i.id))
    } catch (error) {
      console.log('erro ao pegar reservas: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    load(page)
    loadProperties()
  }, [page])

  const legend = useMemo(() => {
    if (!data?.length) return [] // Retorna vazio se não houver dados

    const colors = data.reduce((acc: Array<{ name: string; color: string }>, appointment: ISchedulesProps) => {
      const { name, color } = appointment.property

      // Verifica se o item já existe no acumulador
      if (!acc.some(item => item.name === name && item.color === color)) {
        acc.push({ name, color })
      }

      return acc
    }, []) // Inicia o acumulador como um array vazio com o tipo correto

    return colors
  }, [data])

  const handleApplyFilters = useCallback(async (housesSelected: any) => {
    console.log('housesSelected: ', housesSelected)
    setHouseFiltered(housesSelected)
    load(1, housesSelected)
  }, [load])

  return (
    <Box>
        <Header />

        {showModalFilter && <FilterScheduleModal open={showModalFilter} setOpen={() => setShowModalFilter(false)} houses={houses} onClose={() => setShowModalFilter(false)} onSubmit={handleApplyFilters} houseFiltered={houseFiltered} />}

        <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
            <Sidebar />

            <Box flex="1" borderRadius={8} bg="white" p="8">
                    <Flex mb="8" justify="space-between" align="center">
                    <Heading size="lg" fontWeight="normal" color="green.dark">
                    Agenda

                    </Heading>

                    <Flex alignItems='center' gap='20px'>
                      <Button
                          onClick={() => setShowModalFilter(true)}
                          size="sm"
                          fontSize="sm"
                          bg="green.dark"
                          _hover={{ bgColor: 'green.light' }}
                          color="white"
                          leftIcon={<Icon as={RiFilter2Fill} fontSize="20" />}
                        >
                          Filtros
                      </Button>

                      <Button
                        as="a"
                        href="/schedule/create"
                        size="sm"
                        fontSize="sm"
                        bg="green.dark"
                        _hover={{ bgColor: 'green.light' }}
                        color="white"
                        leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                        >
                        Novo Agendamento
                      </Button>
                    </Flex>
                </Flex>

                {!!data?.length && (
                   <Box>
                   <Text fontSize='19px' fontWeight='600'>Legendas:</Text>
                   <Flex mt='15px' mb='30px'>
                     {legend?.map(item => (
                       <Flex key={item.name} gap='10px' mr='20px'>
                         <Box width='20px' height='20px' borderRadius='100%' backgroundColor={item.color} />
                         <Text>{item.name}</Text>
                       </Flex>
                     ))}
                   </Flex>
                 </Box>
                )}

                    <CalendarSchedule schedule={data} reloadFunction={load} />

            </Box>
        </Flex>
    </Box>

  )
}

export { Schedule }
