/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  InputGroup,
  InputLeftAddon,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TextArea } from '../../Components/Form/TextArea'
import { RiAddLine, RiCloseFill } from 'react-icons/ri'
import { SelectWithSearch } from '../../Components/Form/SelectWithSearch'
import { Loader } from '../../Components/Loader'
import { removeNonNumeric } from '../../utils/removeNonNumeric'
import { FindKeyWithPrefix } from '../../utils/findKeyWithPrefix'
import { FindKeyIncluded } from '../../utils/findKeyIncluded'

interface INetProps {

  'id': string
  'name': string
  'razao_social': string
  'internal_code': string
  'cnpj': string
}
interface contactProps {
  'name': string
  'email': string
  'phone': string
  'department': string
}
interface createUnitProps {
  'net_id': string
  'cod': string
  'name': string
  'cep': string
  'street': string
  'number': string
  'complement': string
  'neighborhood': string
  'city': string
  'state': string
  'cnpj': string
  'sub_prefecture': string
  'obs': string
  'area_ground': string
  'area_building': string
  'iptu': string
  'zoning': string
  'contacts': contactProps[]

}

interface OptionsProps {
  label: string
  value: string
}

interface CepProps {
  cep: string
  logradouro: string
  complemento?: string
  bairro: string
  localidade?: string
  uf: string
}

const createUnitFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  cod: Yup.string().required('Código da unidade é obrigatório'),

  cep: Yup.string().required('Cep é obrigatório'),
  street: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a Rua')
    }),
  number: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha o número')
    }),
  neighborhood: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a bairro')
    }),
  city: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a cidade')
    }),
  state: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha o Estado')
    })
})

const updateUserFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  login: Yup.string().required('Login é obrigatório'),
  password: Yup.string(),
  password_confirmation: Yup.string().oneOf(
    [null, Yup.ref('password')],
    'As senhas devem ser iguais'
  ),
  gym_id: Yup.string().required('Selecione uma unidade')
})

const UnitiesCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [optionsNets, setOptionsNets] = useState<OptionsProps[]>([])
  const [selectedNet, setSelectedNet] = useState<INetProps>({} as INetProps)
  const [allNets, setAllNets] = useState<INetProps[]>([])
  const [showAddressComponent, setShowAddressComponent] = useState(false)

  const [fakeCep, setFakeCep] = useState('')
  const [isSP, setIsSP] = useState(false)
  const params = useParams()
  const location = useLocation()
  const toast = useToast()
  const navigate = useNavigate()

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createUnitFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      if (!selectedNet?.id) {
        toast({
          title: 'Selecione uma Empresa(rede)',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      const {
        name,
        cod,
        street,
        cep,
        number,
        complement,
        neighborhood,
        city,
        state,
        cnpj,
        obs,
        area_ground,
        area_building,
        iptu,
        zoning
      } = values

      const payload = {
        net_id: selectedNet.id,
        name,
        cod,
        street,
        cep,
        number,
        complement,
        neighborhood,
        city,
        state,
        cnpj,
        obs,
        area_ground,
        area_building,
        iptu,
        zoning

      }

      const id = params?.unit_id

      try {
        id
          ? await api.put(`/units/${id}`, payload)
          : await api.post('/units', payload)

        toast({
          title: `${id ? 'Editado' : 'Cadastrado'} com sucesso`,
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
        navigate('/unities')
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    },
    [toast, params?.unit_id, navigate, selectedNet]
  )

  const loadItem = useCallback(async (unit_id: string) => {
    try {
      const { data } = await api.get(`/units/${unit_id}`)

      const { address, immobile, net } = data
      const { cep, street, number, complement, neighborhood, city, state } = address
      const { area_ground, area_building, iptu, zoning } = immobile

      console.log('data: ', data)
      setValue('name', data?.name || '')
      setValue('cod', data?.cod || '')
      setValue('cep', cep || '')
      setValue('street', street || '')
      setValue('number', number || '')
      setValue('complement', complement || '')
      setValue('neighborhood', neighborhood || '')
      setValue('city', city || '')
      setValue('state', state || '')
      // setValue('cnpj', data?.cnpj || '')
      // setValue('obs', data?.obs || '')
      setValue('area_ground', area_ground || '')
      setValue('area_building', area_building || '')
      setValue('iptu', iptu || '')
      setValue('zoning', zoning || '')

      const makeNet: INetProps = {
        id: net.id,
        name: net.name,
        razao_social: net.razao_social,
        cnpj: net.cnpj,
        internal_code: net.internal_code
      }

      setSelectedNet(makeNet)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue])

  const loadNets = useCallback(async () => {
    try {
      const resp = await api.get('/nets', {
        params: {
          pag: 1,
          all: true
        }
      })
      const data: any[] = resp.data.data

      const options = data.map((i: any) => ({
        label: i.name,
        value: i.id
      }))

      setAllNets(resp.data.data)
      setOptionsNets(options)
    } catch (error) {
      console.log('error gymm: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  useEffect(() => {
    void loadNets()
  }, [])

  useEffect(() => {
    if (params?.unit_id) {
      loadItem(params?.unit_id)
    }
  }, [params])

  const handleSearchCep = useCallback(
    async (e: any) => {
      const cep = removeNonNumeric(e?.target?.value)

      if (Number(cep.length) === 8) {
        setLoading(true)

        try {
          const response = await api.get(`/cep/${cep}`)
          const { bairro, logradouro, uf, complemento, localidade }: CepProps =
            response.data

          if (uf === 'SP') {
            setIsSP(true)
          }

          setShowAddressComponent(true)

          setValue('street', logradouro)
          setValue('cep', cep)
          setValue('neighborhood', bairro)
          setValue('state', uf)
          setValue('city', localidade)

          if (complemento) {
            setValue('complement', complemento)
          }
        } catch (error) {
          console.log('error users cep: ', error)
          toast({
            title: error.response.data.message,
            position: 'top',
            isClosable: true,
            status: 'error',
            variant: 'solid'
          })
        } finally {
          setLoading(false)
        }
      }
    },
    [setValue, toast]
  )

  const handleSelectNet = useCallback((net_id: string) => {
    const findNet = allNets.find(i => i.id === net_id)

    if (findNet != null) {
      setSelectedNet(findNet)
    }
  }, [allNets])

  return (
    <Box>
      <Header />
      {loading && <Loader />}

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
            onSubmit={handleSubmit(handleSubmitForm)}
          // onSubmit={() => {}}
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="wine.primary">
            Criar nova Unidade
          </Heading>
          <Divider my="6" borderColor="wine.primary" />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Dados Cadastrais

            <Text size="sm" fontSize="14">Data de inclusão: {format(new Date(), 'dd/MM/yyyy ')}</Text>
          </Heading>

          <VStack spacing={['12', '12']}>
            <SimpleGrid spacing={['3', '3']} w="100%" minChildWidth="240px">
              <SelectWithSearch
                label="Empresa"
                title={selectedNet?.id ? 'Trocar Empresa' : 'Selecionar Empresa'}
                // errors={errors.gym_id}
                options={optionsNets}
                extraFunction={handleSelectNet}
                {...register('net_id')}

              />

              {selectedNet?.id && (
                <>
                <Heading size="md" fontWeight="normal" color="wine.primary">
                  Cliente:
                  <Text size="sm" fontWeight="bold" fontSize="16">{selectedNet?.name}</Text>
                </Heading>

                <Heading size="md" fontWeight="normal" color="wine.primary">
                  CNPJ:
                  <Text size="sm" fontWeight="bold" fontSize="16">{selectedNet?.cnpj}</Text>
                </Heading>
                </>
              )}

            </SimpleGrid>

            <SimpleGrid spacing={['6', '6']} w="100%" minChildWidth="240px">

            <InputGroup>

              <Input
                label="Código Unidade"
                placeholder="Preencha o código"
                errors={errors.cod}
                leftAddon={!!selectedNet?.internal_code}
                leftAddonText={selectedNet?.internal_code ? `${selectedNet?.internal_code}-` : ''}
                {...register('cod')}
              />

              </InputGroup>

              <Input
                label="Nome da Unidade"
                placeholder="Preencha o nome"
                errors={errors.name}
                {...register('name')}
              />

            </SimpleGrid>

          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
              Dados de Endereço
          </Heading>

          <VStack spacing={['6', '8']}>
          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              {/* <Select name="gender" label="Gênero" placeholder="Selecionar Gênero" errors={errors.gender} {...register('gender')} options={optionsGender} /> */}

              <InputMaskCustom
                type="text"
                label="CEP"
                errors={errors.cep}
                {...register('cep')}
                placeholder="Digite o CEP"
                mask="99999-999"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
               onChange={handleSearchCep}
              />

              <Input
                label="Endereço"
                placeholder="Preencha o Endereço"
                errors={errors.street}
                {...register('street')}
              />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            <Input
                label="Número"
                placeholder="Preencha o Número"
                errors={errors.number}
                {...register('number')}
              />

              <Input
                label="Complemento"
                placeholder="Preencha o complemento"
                errors={errors.complement}
                {...register('complement')}
              />

              <Input
                label="Bairro"
                placeholder="Preencha o bairro"
                errors={errors.neighborhood}
                {...register('neighborhood')}
              />

              <Input
                label="Cidade"
                placeholder="Preencha a Cidade"
                 errors={errors.city}
                {...register('city')}
              />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            <Input
                label="UF"
                placeholder="Preencha o UF"
                errors={errors.state}
                {...register('state')}
              />

              <InputMaskCustom
                type="text"
                label="CNPJ"
                errors={errors.cnpj}
                {...register('cnpj')}
                placeholder="Digite o CNPJ"
                mask="99.999.999/9999-99"
              />

            { isSP && <Select
                label="Sub. Prefeitura"
                placeholder="Selecionar Sub. Prefeitura"
                errors={errors.sub_prefecture}
                {...register('sub_prefecture')}
                options={optionsNets}
              />}

            </SimpleGrid>
          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
              Dados adicionais
          </Heading>

          <VStack spacing={['6', '8']}>
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <TextArea

                label="Observações sobre o Imóvel"
                {...register('obs')}
              />
            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

<Input
                label="Área do Terreno"
                placeholder="Preencha a área"
                errors={errors.area_ground}
                {...register('area_ground')}
              />

<Input
                label="Área construída"
                placeholder="Preencha a área"
                errors={errors.area_building}
                {...register('area_building')}
              />
            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            <Input
                label="IPTU"
                placeholder="Preencha o IPTU"
                errors={errors.iptu}
                {...register('iptu')}
              />

<Input
                label="Zoneamento"
                placeholder="Preencha o zoneamento"
                errors={errors.zoning}
                {...register('zoning')}
              />

            </SimpleGrid>

            {loading && (
              <Flex justify="center">
                <Spinner />
              </Flex>
            )}

          </VStack>

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="pink.500"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="wine.primary"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export { UnitiesCreate }
