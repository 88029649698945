/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { forwardRef, ForwardRefRenderFunction } from 'react'
import ReactDatePicker, { DatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ptBR from 'date-fns/locale/pt-BR' // Importa o idioma PT-BR do date-fns
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  InputLeftAddon,
  Input as ChakraInput,
  Text
} from '@chakra-ui/react'

interface Props extends Omit<any, 'onChange'> {
  name: string
  label?: string
  errors?: any
  helperText?: string
  leftAddon?: boolean
  leftAddonText?: string
  onChange?: (date: Date | null) => void
}

const BaseDatePicker: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    name,
    label,
    errors = null,
    helperText,
    leftAddon,
    leftAddonText,
    onChange,
    ...rest
  },
  ref
) => (
  <FormControl id={name} isInvalid={!(errors == null)}>
    {!!label && <FormLabel>{label}</FormLabel>}

    {leftAddon ? (
      <InputGroup>
        <InputLeftAddon bgColor="green.dark" color="white">
          <Text>{leftAddonText}</Text>
        </InputLeftAddon>
        <ReactDatePicker
          {...rest}
          selected={rest.selected}
          locale={ptBR} // Configura o idioma para PT-BR
          dateFormat="dd/MM/yyyy" // Define o formato de exibição
          onChange={(date) => {
            if (!Array.isArray(date)) {
              onChange?.(date)
            }
          }}
          customInput={
            <ChakraInput
              ref={ref}
              bg="white"
              variant="filled"
              borderColor="green.dark"
              _placeholder={{ color: 'gray.300' }}
              _focus={{ borderColor: 'green.light' }}
              borderWidth="1px"
            />
          }
        />
      </InputGroup>
    ) : (
      <ReactDatePicker
        {...rest}
        selected={rest.selected}
        locale={ptBR} // Configura o idioma para PT-BR
        dateFormat="dd/MM/yyyy" // Define o formato de exibição
        onChange={(date) => {
          if (!Array.isArray(date)) {
            onChange?.(date)
          }
        }}
        customInput={
          <ChakraInput
            ref={ref}
            bg="white"
            variant="filled"
            borderWidth="1px"
            borderColor="green.dark"
            _placeholder={{ color: 'gray.300' }}
            _focus={{ borderColor: 'green.light' }}
          />
        }
      />
    )}

    {helperText && <FormHelperText>{helperText}</FormHelperText>}
    {!(errors == null) && <FormErrorMessage>{errors.message}</FormErrorMessage>}
  </FormControl>
)

export const DatePicker = forwardRef(BaseDatePicker)
