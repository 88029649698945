/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
  useTab,
  IconButton,
  useBreakpointValue
} from '@chakra-ui/react'
import { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import * as Yup from 'yup'
import {
  DragAndDrop,
  DragAndDropExportedProps
} from '../../Components/Form/DragAndDrop'
import { Input } from '../../Components/Form/Input'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'

import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { TiDeleteOutline } from 'react-icons/ti'
import { Select } from '../../Components/Form/Select'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { TextArea } from '../../Components/Form/TextArea'
import {
  ITEMS_MULTI,
  ITEMS_TYPE,
  PROPERTIES_STATUS
} from '../../Services/contants'
import IFilesProps from '../../interfaces/IFilesProps'
import { Loader } from '../../Components/Loader'
import { AiOutlineDelete } from 'react-icons/ai'
import { ConciergeItemModal } from '../../Components/Modals/ConciergeItemModal'
import {
  IConciergeProps,
  IHouseProps,
  IItemProps,
  IPriceHouseProps,
  useConcierge
} from '../../Hooks/Concierge'
import { IPropertiesProps } from '../../interfaces/IPropertiesProps'
import { TablePlaceholder } from '../../Components/TablePlaceholder'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { MoneyInput } from '../../Components/Form/MoneyInput'
import { getCurrencyValue } from '../../utils/getCurrencyValue'
import { SelectWithSearch } from '../../Components/Form/SelectWithSearch'
import { ALL } from 'dns'
import { InputModalSearch } from '../../Components/InputModalSearch'
import { generateYearOptions } from '../../utils/generateYearOptions'
import { DataProps } from '.'
import { IntervalDatesModal } from '../../Components/Modals/IntervalDatesModal'
import { generateDatesBetweenIntervals } from '../../utils/generateDatesBetweenIntervals'
import { error } from 'console'
import IHouseGroupSpecialDatesProps from '../../interfaces/IHouseGroupSpecialDates'
import { ModalSelectHouseGroupSpecialDates } from '../../Components/Modals/ModalSelectHouseGroupSpecialDates'

interface OptionsProps {
  label: string
  value: string
}

interface IIntervalDatesProps { from: 'string'; to: 'string' }

const SpecialDatesDetails: FC = () => {
  const [loading, setLoading] = useState(false)

  const [globalLoading, setGlobalLoading] = useState(false)
  const params = useParams()

  const [cover, setCover] = useState<undefined | string>(undefined)

  const toast = useToast()
  const CoverRef = useRef<DragAndDropExportedProps>(null)
  const [showConciergeItemModal, setShowConciergeItemModal] = useState({
    show: false,
    item: ''
  })
  const [houses, setHouses] = useState<IHouseProps[]>([])
  const { updateConcierge, concierge } = useConcierge()

  const [selectedUser, setSelectedUser] = useState<any>({} as any)

  const DragRef = useRef<DragAndDropExportedProps>(null)
  const [datesInterval, setDatesInterval] = useState<IIntervalDatesProps[]>([])
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [showModalHouse, setShowModaHouse] = useState(false)
  const [data, setData] = useState<IHouseGroupSpecialDatesProps>({} as IHouseGroupSpecialDatesProps)

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const yearOptions = generateYearOptions()

  const navigate = useNavigate()

  const { register, handleSubmit, formState, setValue, watch, unregister, getValues } =
    useForm({
      // resolver: yupResolver(createFormSchema)
    })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      const fields = getValues()
      console.log('fields: ', fields)

      if (!watch('name')) {
        toast({
          title: 'Por favor, preencha o nome do grupo.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      try {
        setLoading(true)

        const payload = {
          name: watch('name'),
          year: watch('year'),
          between_dates: datesInterval,
          dates: generateDatesBetweenIntervals(datesInterval)
        }

        console.log('payload: ', payload)

        await api.post('/group-special-dates', payload)

        toast({
          title: 'Grupo de datas especiais cadastrado com sucesso',
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })

        navigate('/special-dates')
      } catch (error: any) {
        console.log('error: ', error)
        toast({

          title: error?.response?.data?.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      } finally {
        setLoading(false)
      }
    },
    [toast, getValues, navigate, watch, datesInterval]
  )

  const loadItem = useCallback(async () => {
    try {
      const { data } = await api.get(`/house-group-special-dates/${params?.group_id}`)

      setData(data)

      setValue('name', data?.name)
      setValue('year', data?.year)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
      setGlobalLoading(false)
    }
  }, [toast, params, setValue])

  const handleAddHouse = useCallback(async (houses: string[]) => {
    if (!houses?.length) return
    try {
      setLoading(true)
      await api.post('/house-group-special-dates', { group_id: params?.group_id, houses })

      loadItem()
      toast({
        title: 'Casas adicionadas ao grupo',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [params, toast, loadItem])

  useEffect(() => {
    const initialLoad = async () => {
      if (params?.group_id) {
        await loadItem()
      }
    }

    initialLoad()
  }, [params])

  const hanleDelete = useCallback(async () => {
    const { house_id } = selectedItem
    try {
      await api.delete(`/house-group-special-dates/${params?.group_id}/${house_id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as any)
      loadItem()
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as any)
    }
  }, [selectedItem, toast, params, loadItem])

  const closeModal = useCallback(() => {
    setSelectedItem({} as any)
  }, [])

  return (
    <Box>
      <Header />

      {!!selectedItem?.house_id && (
        <AlertDialogCustom
          title="Remover essa casa deste grupo?"
          isOpen={!!selectedItem?.house_id}
          cancelFunction={closeModal}
          nextFunction={hanleDelete}
        />
      )}

      {showModalHouse && <ModalSelectHouseGroupSpecialDates onClose={() => setShowModaHouse(false)} onSubmit={handleAddHouse} alreadySelected={data?.houses?.map(i => i.house_id)} />}

      {loading && !globalLoading && <Loader />}

      {globalLoading ? <Loader /> : (
         <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
         <Sidebar />

         {showConciergeItemModal.show && (
           <ConciergeItemModal
             houses={concierge?.info?.availability ?? []}
             onClose={() => setShowConciergeItemModal({ item: '', show: false })}
           />
         )}

         <Box
           as="form"
           flex="1"
           borderRadius={8}
           bg="white"
           p="8"
           onSubmit={handleSubmit(handleSubmitForm)}
         >
           <Heading size="lg" fontWeight="normal" color="green.dark">
             Datas Especiais - {data?.name}
           </Heading>

           <Box mt='30px'>

             <SimpleGrid
               spacing={['6', '9']}
               w="100%"
               minChildWidth="240px"
             >
               <Input label='Nome do Grupo' placeholder='Ex: Casas de Praia - SP' {...register('name')} isReadOnly />

               <Input label='Ano de referência' {...register('year')} isReadOnly />

             </SimpleGrid>

           </Box>

           <Flex mb="8" justify="space-between" alignItems="center" mt="20">
            <Heading size="lg" fontWeight="normal" color="green.dark" >
              Casas que fazem parte deste grupo:
              {/* { !loading && isRefetching && <Spinner size="sm" ml="4" color="pink.400" />} */}
            </Heading>
            {/* <SearchBox placeholder="Buscar por nome" /> */}

            <Button

              size="sm"
              fontSize="sm"
              bg="green.dark"
              _hover={{ bgColor: 'gray.500' }}
              color="#fff"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
              onClick={() => setShowModaHouse(true)}
            >
              Adicionar Casa
            </Button>

            {/* <NextLink href="/users/create" passHref>
                            <Button as="a" size="sm" fontSize="sm" colorScheme="blue" leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
                                Criar Usuário
                            </Button>
                        </NextLink> */}
          </Flex>

           {data?.houses?.length ? (
             <Table colorScheme="blue">
             <Thead>
               <Tr>
                 {/* <Th px={["4","4","6"]} color="gray.300" w="8">
                                     <Checkbox colorScheme="pink" />
                                 </Th> */}
                 <Th>Casa</Th>

                 <Th w="8"></Th>
               </Tr>
             </Thead>
             <Tbody>
               {data?.houses?.map((i, index: number) => (
                 <Tr key={i?.id}>
                   {/* <Td px={["4","4","6"]}>
                                      <Checkbox colorScheme="pink" />
                                  </Td> */}
                   <Td>
                     <Box>
                      <Text fontWeight="bold">{i?.house?.name}</Text>
                     </Box>
                   </Td>

                   {/* <Td>
                     <Box>
                       <Link color="blue.500">
                         <Text fontWeight="bold">{i.name}</Text>
                       </Link>
                     </Box>
                   </Td> */}

                   <Td>
                     <Flex>

                     {isWideScreen
                       ? (
                            <Button
                              as="button"
                              onClick={() => setSelectedItem(i)}

                              size="sm"
                              fontSize="sm"
                              bg="green.teal"
                              color="green.dark"
                              // colorScheme="red"
                              leftIcon={
                                <Icon as={AiOutlineDelete} fontSize="16" />
                              }
                            >
                              Excluir
                            </Button>
                         )
                       : (
                            <IconButton
                              colorScheme="red"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineDelete} size="30" />}
                              // onClick={() => setSelectedItem(i)}
                              onClick={() => {}}
                            />
                         )}
                     </Flex>
                   </Td>
                 </Tr>
               ))}
             </Tbody>
           </Table>
           ) : (
            <Text mt="20px"> Você ainda não possui nenhuma casa cadastrada neste grupo</Text>
           )}

           {/* <Flex justify="flex-end" mt="20">
             <HStack>
               {loading
                 ? (
                 <Spinner />
                   )
                 : (
                 <>
                   <Button
                     as="a"
                     href="/special-dates"
                     bg="green.teal"
                     _hover={{ bgColor: 'gray.500' }}
                     color="white"
                     cursor="pointer"
                   >
                     Cancelar
                   </Button>

                   <Button
                     type="submit"
                     bg="green.dark"
                     _hover={{ bgColor: 'green.teal' }}
                     color="green.light"
                     isLoading={formState.isSubmitting}
                   >
                     Salvar
                   </Button>
                 </>
                   )}
             </HStack>
           </Flex> */}
         </Box>
       </Flex>
      )}

    </Box>
  )
}

export { SpecialDatesDetails }
