/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiEye2Fill, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { format } from 'date-fns'
import { Loader } from '../../Components/Loader'
import { NotificationsProps, NOTIFICATIONS_TYPE_LABEL, NotificationsType } from './Interfaces/NotificationsProps'
import { NOTIFICATIONS_STATUS, NOTIFICATIONS_TYPE } from '../../Services/contants'

interface Props extends NotificationsProps {
  typeLabel: string
}

const NotificationsList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState({} as Props)
  const [tabIndex, setTabIndex] = useState(0)

  const [page, setPage] = useState(1)
  const [data, setData] = useState<Props[]>([])
  const [total, setTotal] = useState(10)

  const [pageDeleted, setPageDeleted] = useState(1)
  const [dataDeleted, setDataDeleted] = useState<Props[]>([])
  const [totalDeleted, setTotalDeleted] = useState(10)

  const [error, setError] = useState(false)
  const toast = useToast()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const updateViewed = useCallback(async (notification_id: string) => {
    try {
      await api.patch(`/notifications/${notification_id}`)
    } catch (error) {
      console.log('erro ao atualizar status da notificação: ', error)
      setError(true)
    }
  }, [])

  const loadNewsNotifications = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/notifications', {
        params: {
          pag,
          viewed: ['YES', 'NO']
        }
      })

      const normalize: any = await Promise.all(data?.data?.map(async (i: any) => {
        // if (i?.viewed === 0 && i?.type === NOTIFICATIONS_TYPE.info) {
        //   await updateViewed(i.id)
        // }

        return {
          ...i,
          typeLabel: NOTIFICATIONS_TYPE_LABEL[i.type],
          created_at: format(new Date(i.created_at), 'dd/MM/yyyy HH:mm')
        }
      }))

      setPage(pag)
      setData(normalize)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar notificações: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
    // setData(fakeData)
  }, [])

  const loadDeletedNotifications = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/notifications', {
        params: {
          pag,
          deleted: true
        }
      })

      const normalize: any = await Promise.all(data?.data?.map(async (i: any) => {
        if (i?.viewed === 0 && i?.type === NOTIFICATIONS_TYPE.info) {
          await updateViewed(i.id)
        }

        return {
          ...i,
          typeLabel: NOTIFICATIONS_TYPE_LABEL[i.type],
          formattedDate: format(new Date(i.created_at), 'dd/MM/yyyy HH:mm')
        }
      }))

      setPageDeleted(pag)
      setDataDeleted(normalize)
      setTotalDeleted(data.total)
    } catch (error) {
      console.log('erro ao pegar notificações: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
    // setData(fakeData)
  }, [updateViewed])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadNewsNotifications(page)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    // loadDeletedNotifications(pageDeleted)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDeleted])

  const MakeActions = (type: NotificationsType, item: NotificationsProps) => {
    // if (type === 'pre-proposal-approvement-boss-sector') {
    //   return <Button
    //   href={`/pre-proposal/create/${item?.params?.proposal_id}?notification_id=${item?.id}&action=${item?.type}`}
    //     as="a"
    //     size="sm"
    //     fontSize="sm"
    //     mr="2"
    //     bg="wine.200"
    //     colorScheme="wine"
    //     height="40px"
    //     leftIcon={
    //       <Icon as={RiEye2Fill} fontSize="16" />
    //     }
    //   >
    //   Visualizar
    // </Button>
    // }

    // if (type === 'pre-proposal-updated-boss-sector') {
    //   return <Button
    //   href={`/pre-proposal/create/${item?.params?.proposal_id}?notification_id=${item?.id}&action=${item?.type}`}
    //     as="a"
    //     size="sm"
    //     fontSize="sm"
    //     mr="2"
    //     bg="wine.200"
    //     colorScheme="wine"
    //     height="40px"
    //     leftIcon={
    //       <Icon as={RiEye2Fill} fontSize="16" />
    //     }
    //   >
    //   Visualizar
    // </Button>
    // }

    // if (type === 'provider-create-boss-sector') {
    //   return <Button
    //   href={`/providers/create/${item?.params?.provider_id}?notification_id=${item?.id}&action=${item?.type}`}
    //     as="a"
    //     size="sm"
    //     fontSize="sm"
    //     mr="2"
    //     bg="wine.200"
    //     colorScheme="wine"
    //     height="40px"
    //     leftIcon={
    //       <Icon as={RiEye2Fill} fontSize="16" />
    //     }
    //   >
    //   Visualizar
    // </Button>
    // }
    return <Button
      href='#'
        as="a"
        size="sm"
        fontSize="sm"
        mr="2"
        bg="wine.200"
        colorScheme="wine"
        height="40px"
        leftIcon={
          <Icon as={RiEye2Fill} fontSize="16" />
        }
      >
      Visualizar
    </Button>
  }

  const closeModal = useCallback(() => {
    setSelectedItem({} as Props)
  }, [])

  const hanleDelete = useCallback(async () => {
    const { id } = selectedItem
    try {
      await api.delete(`/notifications/${id}`)

      toast({
        title: 'Sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as Props)
      loadNewsNotifications(page)
      loadDeletedNotifications(pageDeleted)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as Props)
    }
  }, [selectedItem, toast, page, loadNewsNotifications, loadDeletedNotifications, pageDeleted])

  return (
    <Box>

      <Header />

      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Marcar nofiticação como visualizada"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModal}
          nextFunction={hanleDelete}
        />
      )}

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="green.dark">
              Notificações
            </Heading>

          </Flex>

          {loading
            ? (
            <Flex justify="center">
              <Loader />
            </Flex>
              )
            : error
              ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista de notificações</Text>
            </Flex>
                )
              : (
                  <Tabs variant='enclosed' mt="10" index={tabIndex} onChange={e => setTabIndex(e)}>
                    <TabList>
                      <Tab>NÃO VISUALIZADAS</Tab>
                      <Tab>VISUALIZADAS</Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                          <Table colorScheme="blue">
                            <Thead>
                              <Tr>
                                <Th textAlign="center">Notificação</Th>
                                <Th textAlign="center">Data</Th>
                                <Th textAlign="center">Tipo</Th>
                                <Th textAlign="center">Ações</Th>

                              </Tr>
                            </Thead>
                            <Tbody>
                              {data.map((i) => (
                                <Tr key={i.id} >

                                  <Td>
                                      <Link color="wine.500" textAlign="center">
                                        <Text fontWeight="bold" color="green.dark">{i?.description}</Text>
                                      </Link>
                                  </Td>

                                  <Td>
                                      <Link color="wine.500" textAlign="center">
                                        <Text fontSize="12px" fontWeight="bold" color="green.dark">{i?.created_at}</Text>
                                      </Link>
                                  </Td>

                                  <Td>
                                      <Link color="wine.500" textAlign="center">
                                        <Text fontWeight="bold" color="green.dark">{i.typeLabel}</Text>
                                      </Link>
                                  </Td>

                                  <Td>
                                    <Flex>

                                  {/* {MakeActions(i.type, i)} */}
                                      <IconButton
                                        color="green.dark"
                                        aria-label="Edit Content"
                                        icon={<Icon as={AiOutlineDelete} size="30" />}
                                        onClick={() => setSelectedItem(i)}
                                      />

                                    </Flex>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>

                          <Pagination
                            totalCountOfRegisters={total}
                            currentPage={page}
                            onPageChange={setPage}
                          />
                      </TabPanel>

                      <TabPanel>
                          <Table colorScheme="blue">
                            <Thead>
                              <Tr>
                                <Th textAlign="center">Notificação</Th>
                                <Th textAlign="center">Data</Th>
                                <Th textAlign="center">Tipo</Th>
                                <Th textAlign="center">Ações</Th>

                              </Tr>
                            </Thead>
                            <Tbody>
                              {dataDeleted.map((i) => (
                                <Tr key={i.id} >

                                  <Td>
                                      <Link color="wine.500" textAlign="center">
                                        <Text fontWeight="bold" color="#993b58">{i?.description}</Text>
                                      </Link>
                                  </Td>

                                  <Td>
                                      <Link color="wine.500" textAlign="center">
                                        <Text fontSize="12px" fontWeight="bold" color="#993b58">{i?.created_at}</Text>
                                      </Link>
                                  </Td>

                                  <Td>
                                      <Link color="wine.500" textAlign="center">
                                        <Text fontWeight="bold" color="#993b58">{i.typeLabel}</Text>
                                      </Link>
                                  </Td>

                                  <Td>
                                    <Flex>

                                  {isWideScreen
                                    ? MakeActions(i.type, i)
                                    : (
                                        <IconButton
                                          mr="3"
                                          colorScheme="green"
                                          aria-label="Edit Content"
                                          icon={<Icon as={RiPencilLine} size="30" />}
                                        />
                                      )}
                                    </Flex>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>

                          <Pagination
                            totalCountOfRegisters={totalDeleted}
                            currentPage={pageDeleted}
                            onPageChange={setPageDeleted}
                          />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                )}
        </Box>
      </Flex>
    </Box>
  )
}

export { NotificationsList }
