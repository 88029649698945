import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { PROPERTIES_STATUS } from '../../Services/contants'
import { IPropertiesProps } from '../../interfaces/IPropertiesProps'

// const fakeData = [
//   {
//     id: '1',
//     cod: '322',
//     company_name: 'Mc Donalds',
//     unity_quantity: '350',
//     process_quantity: '1.590'
//   },
//   {
//     id: '2',
//     cod: '523',
//     company_name: 'Subway',
//     unity_quantity: '150',
//     process_quantity: '990'
//   },
//   {
//     id: '3',
//     cod: '754',
//     company_name: 'Madero',
//     unity_quantity: '60',
//     process_quantity: '55'
//   }
// ]

const PropertiesList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<IPropertiesProps[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState({} as IPropertiesProps)
  const toast = useToast()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/properties', {
        params: {
          pag,
          active: PROPERTIES_STATUS.ACTIVE
        }
      })

      setPage(pag)
      setData(data.data)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar lista: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    load(page)
  }, [page])

  const hanleDelete = useCallback(async () => {
    const { id } = selectedItem
    try {
      await api.delete(`/properties/${id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as IPropertiesProps)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as IPropertiesProps)
    }
  }, [selectedItem, toast, page, load])

  const closeModal = useCallback(() => {
    setSelectedItem({} as IPropertiesProps)
  }, [])

  return (
    <Box>
      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar Casa"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModal}
          nextFunction={hanleDelete}
        />
      )}
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="green.dark">
              Casas
              {/* { !loading && isRefetching && <Spinner size="sm" ml="4" color="pink.400" />} */}
            </Heading>
            <SearchBox placeholder="Buscar por nome da casa" />

            <Button
              as="a"
              href="/properties/create"
              size="sm"
              fontSize="sm"
              bg="green.dark"
              _hover={{ bgColor: 'green.light' }}
              color="white"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            >
              Criar nova Casa
            </Button>

          </Flex>

          {loading
            ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
              )
            : error
              ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista</Text>
            </Flex>
                )
              : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr>

                    <Th textAlign="center">Nome</Th>
                    <Th textAlign="center">Cotas</Th>

                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((i) => (
                    <Tr key={i.id}>

                      <Td>
                          <Link color="green.dark" textAlign="center">
                            <Text fontWeight="bold">{i.name}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="green.dark" textAlign="center">
                            <Text fontWeight="bold">{i.quotas}</Text>
                          </Link>
                      </Td>

                      <Td>
                        <Flex>

                          {isWideScreen
                            ? (

                            <Button

                              as="a"
                              href={`/properties/create/${i.id}`}
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              bg="green.dark"

                              color='green.light'
                              leftIcon={
                                <Icon as={RiPencilLine} fontSize="16" />
                              }
                            >
                              Editar
                            </Button>
                              )
                            : (
                            <IconButton
                              mr="3"
                              colorScheme="green"
                              aria-label="Edit Content"
                              icon={<Icon as={RiPencilLine} size="30" />}
                            />
                              )}

                          {isWideScreen
                            ? (
                            <Button
                              as="button"
                              onClick={() => setSelectedItem(i)}
                              size="sm"
                              fontSize="sm"

                              bg="green.teal"
                              leftIcon={
                                <Icon as={AiOutlineDelete} fontSize="16" />
                              }
                            >
                              Excluir
                            </Button>
                              )
                            : (
                            <IconButton
                              colorScheme="red"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineDelete} size="30" />}
                              onClick={() => setSelectedItem(i)}
                            />
                              )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
            </>
                )}
        </Box>
      </Flex>
    </Box>
  )
}

export { PropertiesList }
