import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  Button,
  Box,
  Checkbox
} from '@chakra-ui/react'
import { IPropertiesProps } from '../../../interfaces/IPropertiesProps'
import { IHouseProps, IItemProps, IPriceHouseProps } from '../../../Hooks/Concierge'

interface IFilterScheduleModalProps {
  open: boolean
  setOpen: (state: boolean) => void
  houses: IPropertiesProps[]
  onSubmit: (data?: any) => Promise<void>
  onClose: () => void
  houseFiltered: string[]
}

const FilterScheduleModal: FC<IFilterScheduleModalProps> = ({ open, setOpen, houses, onSubmit, onClose, houseFiltered }) => {
  const [tempItem, setTempItem] = useState<string[]>([])

  const onClick = useCallback(async () => {
    await onSubmit(tempItem)
    onClose()
  }, [onSubmit, onClose, tempItem])

  const handleSelectHouse = useCallback(
    (house: string) => {
      const exists = tempItem.find(i => i === house)

      if (exists != null) {
        setTempItem(prev => prev?.filter(i => i !== house))
        return
      }

      setTempItem(prev => [...prev, house])
    },
    [tempItem]
  )

  useEffect(() => {
    setTempItem(houseFiltered)
  }, [])

  return (
    <Drawer isOpen={open} placement="right" onClose={() => setOpen(false)} size='md'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Agenda - Filtros</DrawerHeader>
        <DrawerBody>
          <p>
          Visualizar agendamentos de quais casas?
          </p>

          <Box mt='20px'>
          {houses.map((house) => (
            <Box w='100%' key={house.id}>
            <Checkbox
                key={house.id}
                color="green.dark"
                value={house.id}
                defaultChecked={houseFiltered?.includes(house.id)}
                name={`availability-house-${house.id}`}
                style={{ padding: 5, color: '#000' }}
                onChange={(e) =>
                  handleSelectHouse(house.id)
                }
            >
                {house.name}
            </Checkbox>
            </Box>
          ))}
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={() => setOpen(false)}>
            Cancelar
          </Button>
          <Button backgroundColor='green.dark' color='white' onClick={onClick}>Aplicar Filtros</Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export { FilterScheduleModal }
