const FindKeyWithPrefix = (obj: any, prefix: string): any => {
  const filteredArray: Array<{ key: string; value: string }> = []

  Object.entries(obj).forEach(([key, value]) => {
    if (key.startsWith(prefix)) {
      filteredArray.push({ key, value: value as string })
    }
  })

  return filteredArray
}

export { FindKeyWithPrefix }
