/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import IScheduleConciergeProps from '../../../interfaces/IScheduleConciergeProps'

interface Props {
  data: IScheduleConciergeProps
  onClose: () => void
}
const ModalConciergeScheduleDetails: FC<Props> = ({ data, onClose }) => {
  return (
        <Modal isOpen onClose={onClose} size="4xl" isCentered>
            <ModalOverlay backgroundColor="rgba(0,0,0,0.8)" />
            <ModalContent>
            <ModalHeader>Seviço Solicitado - {data?.concierge?.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                {/* <Text fontWeight={700} fontSize="21px" color="green.dark" mb="50px">Detalhes</Text> */}
                {data?.options?.map(option => (
                    <Box key={option?.id} mb="25px">
                        <Text fontWeight={700} fontSize="18px" color="green.dark">{option?.question?.name}</Text>
                        <Text fontWeight={500} fontSize="16px" color="green.teal">{option?.numeric_value || option?.answer?.name}</Text>
                    </Box>
                ))}

            </ModalBody>

            <ModalFooter>
                <Button backgroundColor="green.teal" mr={3} onClick={onClose}>
                Fechar
                </Button>
                {/* <Button variant='ghost'>Secondary Action</Button> */}
            </ModalFooter>
            </ModalContent>
      </Modal>
  )
}

export { ModalConciergeScheduleDetails }
