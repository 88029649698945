import React, { FC, useCallback } from 'react'
import { Flex, Button, Stack, Image, Text } from '@chakra-ui/react'

import * as Yup from 'yup'
import { useForm, SubmitHandler, UseFormHandleSubmit } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Input } from '../../Components/Form/Input'
import { useAuth } from '../../Hooks/Auth'

import Brand from '../../Assets/Logo/logo.svg'

interface SignInProps {
  login: string
  password: string
}

const SignInSchemaValidation = Yup.object().shape({
  login: Yup.string().required('Login obrigatório'),
  password: Yup.string().required('Senha obrigatória')
})

const SignIn: FC = () => {
  const { signIn } = useAuth()
  // const { handleSubmit } = useFormContext<SignInProps>();
  const { handleSubmit, register, formState } = useForm({
    // resolver: yupResolver(SignInSchemaValidation)
  })

  const handleSignIn: SubmitHandler<any> = useCallback(
    async (values) => {
      const { login, password } = values
      await signIn({ login, password })
    },
    [signIn]
  )

  const onError = (errors: any, e: any) => console.log(errors, e)

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center" bg="green.light">
      <Flex
        as="form"
        w="100%"
        maxWidth={360}
        p="8"
        flexDir="column"
        borderRadius={16}
        onSubmit={handleSubmit(handleSignIn)}
        bg="green.dark"
      >
        <Stack spacing="8" color="white">
          <Image src={Brand} alt="Brand" w="90%" m="auto" mb="4" />
          {/* <Text color="wine.primary" fontWeight="bold" fontSize="4xl" margin="auto">PROJECTO</Text> */}
          <Input
          labelStyle={{ color: '#d6d643' }}
            placeholder="Preencha seu Login"
            type="text"
            label="Login"
            {...register('login')}
          />
          <Input
            labelStyle={{ color: '#d6d643' }}
            placeholder="Preencha sua senha"
            type="password"
            label="Senha"
            {...register('password')}
          />
        </Stack>
        <Button
          type="submit"
          size="lg"
          mt="10"
          bg="green.light"
          color="green.dark"
          _hover={{ bgColor: 'green.teal' }}
          isLoading={formState.isSubmitting}
        >
          Entrar
        </Button>
      </Flex>
    </Flex>
  )
}

export { SignIn }
