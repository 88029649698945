import { eachDayOfInterval, format, parseISO } from 'date-fns'
import { normalizeDate } from './normalizeDate'

interface DateInterval {
  from: string
  to: string
}

const generateDatesBetweenIntervals = (intervals: DateInterval[]): string[] => {
  console.log('intervals: ', intervals)
  const allDates: string[] = []

  intervals.forEach((interval) => {
    const parsedFrom = parseISO(normalizeDate(interval.from))
    const parsedTo = parseISO(normalizeDate(interval.to))

    const dates = eachDayOfInterval({ start: parsedFrom, end: parsedTo }).map((date) =>
      format(date, 'yyyy-MM-dd')
    )

    allDates.push(...dates)
  })

  // Remove duplicatas ao final usando um Set
  const uniqueDates = Array.from(new Set(allDates))

  return uniqueDates
}

export { generateDatesBetweenIntervals }
