/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable import/extensions */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, Button, Flex, HStack, Heading, Icon, IconButton, SimpleGrid, Spinner, Tab, TabList, TabPanel, TabPanels, Table, Tabs, Tbody, Td, Text, Th, Thead, Tr, VStack, useBreakpointValue, useToast } from '@chakra-ui/react'
import { RiEye2Fill, RiPencilLine } from 'react-icons/ri'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'

import { AlertDialogCustom } from '../../Components/AlertDialog'

import { format } from 'date-fns'

import api from '../../Services/api'
import { useParams } from 'react-router-dom'
import { AiOutlineDelete } from 'react-icons/ai'
import { Pagination } from '../../Components/Pagination'
import ISchedulesProps from '../../interfaces/ISchedulesProps'
import IScheduleConciergeProps from '../../interfaces/IScheduleConciergeProps'
import { ModalConciergeScheduleDetails } from '../../Components/Modals/ModalConciergeScheduleDetails'

interface User {
  index: number
  canceled: number
  status: {
    index: number
    name: string
  }
  user: {
    name: string
  }
}

interface Props {
  index: number
  gym_id: string
  name: string
  teacher: string
  hour: string
  day_of_week: number
  limit: number
  credit_loss: number
  users: User[]
}

interface Users {
  name: string
  id: string
}

const ScheduleDetail: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pagePlans, setPagePlans] = useState(1)
  const [totalPlans, setTotalPlans] = useState(0)

  const [pageClasses, setPageClasses] = useState(1)
  const [totalClasses, setTotalClasses] = useState(0)

  const [dataUser, setDataUser] = useState<any>({} as any)
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<ISchedulesProps>({} as ISchedulesProps)
  const [conciergeSelected, setConciergeSelected] = useState<IScheduleConciergeProps>({} as IScheduleConciergeProps)
  const [dataClasses, setDataClasses] = useState<any[]>([])
  const [selectedItem, setSelectedItem] = useState({} as any)
  const [selectedItemPlans, setSelectedItemPlans] = useState({} as any)
  const [selectedItemClasses, setSelectedItemClasses] = useState({} as any)
  const toast = useToast()
  const params = useParams()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const loadSchedule = useCallback(async () => {
    try {
      const { data } = await api.get(`/schedules/${params?.id}`)
      console.log('data: ', data)

      const normalize = {
        ...data
        // date_initial: format(new Date(data?.date_initial), 'dd/MM/yyyy'),
        // date_final: format(new Date(data?.date_final), 'dd/MM/yyyy')
      }

      setData(normalize)
    } catch (error) {
      console.log('erro ao pegar reserva: ', error)
      setError(true)
    }
  }, [params])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadSchedule()
  }, [])

  const closeModal = useCallback(() => {
    setSelectedItem({} as any)
    setSelectedItemPlans({} as any)
    setSelectedItemClasses({} as any)
  }, [])

  const handleDeletePlanHistory = useCallback(async () => {
    try {
      await api.delete(`/plan-user?plan_user_id=${selectedItemPlans?.id}`)

      toast({
        title: 'Excluído com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })

      closeModal()
    } catch (error) {
      console.log('error: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, selectedItemPlans, closeModal])

  const handleDeleteClassesHistory = useCallback(async () => {
    try {
      await api.delete(`/class/${selectedItemClasses?.index}`)

      toast({
        title: 'Excluído com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })

      closeModal()
    } catch (error) {
      console.log('error: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, selectedItemClasses, closeModal])

  const handleDelete = useCallback(async () => {
    try {
      await api.patch(`/plan-user?user_id=${params?.id}`)

      toast({
        title: 'Desativado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      closeModal()
      loadSchedule()
    } catch (error) {
      console.log('error: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      closeModal()
    }
  }, [toast, loadSchedule, params, closeModal])

  const handleOpenConciergeDetails = useCallback((concierge: IScheduleConciergeProps) => {
    setConciergeSelected(concierge)
  }, [])

  const handleNewPayment = useCallback(() => {

  }, [])

  return (
      <Box>
          <Header />

          {conciergeSelected?.id && <ModalConciergeScheduleDetails data={conciergeSelected} onClose={() => setConciergeSelected({} as IScheduleConciergeProps)}/>}

          {!!selectedItem?.id && <AlertDialogCustom title="Desativar Plano" isOpen={!!selectedItem?.id} cancelFunction={closeModal} nextFunction={handleDelete} /> }

          {!!selectedItemPlans?.id && <AlertDialogCustom title="Excluir Histórico" description="Você tem certeza que deseja deletar este plano do histórico do aluno?" isOpen={!!selectedItemPlans?.id} cancelFunction={closeModal} nextFunction={handleDeletePlanHistory} /> }

          {!!selectedItemClasses?.index && <AlertDialogCustom title="Excluir Histórico" description="Você tem certeza que deseja deletar esta aula do histórico do aluno?" isOpen={!!selectedItemClasses?.index} cancelFunction={closeModal} nextFunction={handleDeleteClassesHistory} /> }

          <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
              <Sidebar />

              <Box flex="1" borderRadius={8} bg="white" p="8">

              { loading ? (
                  <Flex justify="center">
                      <Spinner />
                  </Flex>
              ) : error ? (
                  <Flex justify="center">
                      <Text>Erro ao carregar o perfil do usuário.</Text>
                  </Flex>
              ) : (
                  <>

                  <Box flex="1" borderRadius={8} bg="white">
                      <Flex mb="8" justify="space-between" align="center">
                      <Heading fontSize="25px" fontWeight="bold" color="gray.500">
                         Dados da Reserva
                      </Heading>
                      </Flex>
                  </Box>

                      <VStack spacing={['12', '12']} mb="50px">
                          <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px" >
                                  <VStack>
                                    <Box width="200px" height="80px">
                                      <Text fontSize="21px" color="green.teal" fontWeight="bold">Check In</Text>
                                      <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.date_initial}</Text>
                                    </Box>

                                    <Box width="200px" height="80px">
                                      <Text fontSize="21px" color="green.teal" fontWeight="bold">Check Out</Text>
                                      <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.date_final}</Text>
                                    </Box>
                                  </VStack>

                                  <VStack>
                                    <Box width="250px" height="80px">
                                      <Text fontSize="21px" color="green.teal" fontWeight="bold">Propriedade</Text>
                                      <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.property?.name}</Text>
                                    </Box>

                                    <Box width="250px" height="80px">
                                      <Text fontSize="21px" color="green.teal" fontWeight="bold">Tipo de Reserva</Text>
                                      <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.typeLabel}</Text>
                                    </Box>

                                  </VStack>

                                  <VStack>
                                    <Box width="200px" height="80px">
                                      <Text fontSize="21px" color="green.teal" fontWeight="bold">Sócio</Text>
                                      <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.user?.name}</Text>
                                      <Text fontSize="16px" color="green.dark" fontWeight="400">{data?.user?.phone}</Text>
                                    </Box>

                                    <Box width="200px" height="80px">
                                      <Text fontSize="21px" color="green.teal" fontWeight="bold">Cota</Text>
                                      <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.quota?.name}</Text>
                                    </Box>

                                  </VStack>

                          </SimpleGrid>

                      </VStack>

                      {/* <Box flex="1" borderRadius={8} bg="white">
                          <Flex justify="space-between" align="center">
                              <Heading size="lg" fontWeight="normal" color="blue.500">
                              Ações
                              </Heading>
                          </Flex>
                      </Box>

                      <Flex justifyContent="flex-start" mt="5">
                      <HStack>
                          {dataUser?.plan && <Button onClick={() => setSelectedItem(dataUser?.plan)} as="button" size="sm" fontSize="sm" colorScheme="red" leftIcon={<Icon as={RiPencilLine} fontSize="16" />}>Desativar plano atual</Button> }

                          {dataUser?.plan &&
                          <Button as="a" href={`/user-plan/change-validate-plan?user_id=${params?.id}`}>

                                  <Button as="button" size="sm" fontSize="sm" colorScheme="blue" leftIcon={<Icon as={RiPencilLine} fontSize="16" />}>Editar Vencimento do plano Atual</Button>

                          </Button>
}

                          <Button as="a" href={`/user-plan/add-or-remove-class?user_id=${params?.id}`}>

                              <Button as="button" size="sm" fontSize="sm" colorScheme="purple" leftIcon={<Icon as={RiPencilLine} fontSize="16" />}>Adicionar / Remover - Aula avulsa</Button>

                          </Button>

                          <Button as="a" href={`/users/create?user_id=${params?.id}`}>

                                  <Button as="button" size="sm" fontSize="sm" colorScheme="teal" leftIcon={<Icon as={RiPencilLine} fontSize="16" />}>Ver dados cadastrais</Button>

                          </Button>
                          </HStack>
                      </Flex> */}

                      <Box flex="1" mt="10" mb="8" borderRadius={8} bg="white">
                      <Flex justify="space-between" align="center">
                          <Heading fontSize="25px" fontWeight="bold" color="gray.500">
                          Informações
                          </Heading>
                      </Flex>
                      </Box>

                      <Tabs>
                          <TabList>
                              <Tab>Serviços de concierge solicitados</Tab>
                              <Tab>Histórico de Pagamentos</Tab>

                          </TabList>

                          <TabPanels>
                              <TabPanel>
                              <>
                          <Table colorScheme="blue">
                              <Thead>
                                  <Tr>
                                      {/* <Th px={["4","4","6"]} color="gray.300" w="8">
                                          <Checkbox colorScheme="pink" />
                                      </Th> */}
                                      <Th>Serviço</Th>
                                      {/* { isWideScreen && <Th>Data</Th> } */}
                                      { isWideScreen && <Th>Status</Th> }
                                      {isWideScreen && <Th>Ações</Th> }
                                      <Th w="8"></Th>
                                  </Tr>
                              </Thead>
                              <Tbody>

                                  { data?.services?.map(i => (
                                      <Tr key={i.id}>
                                          <Td>
                                              <Box><Text fontWeight="bold" color="blue.500">{i?.concierge?.name}</Text></Box>
                                          </Td>

                                          { isWideScreen && (
                                              <Td>
                                              <Text fontWeight="bold">{i?.status}</Text>
                                              </Td>
                                          )}

                                          <Td>
                                          <Button
                                              onClick={() => handleOpenConciergeDetails(i)}
                                              size="sm"
                                              fontSize="sm"
                                              mr="2"
                                              bg="green.teal"
                                              colorScheme="wine"
                                              leftIcon={
                                                <Icon as={RiEye2Fill} fontSize="16" />
                                              }
                                            >
                                            Visualizar
                                          </Button>
                                          </Td>
                                      <Flex>

                                  {/* {i.status === 0 && (
                                    isWideScreen
                                      ? (

                                      <Button as="button" onClick={() => setSelectedItemPlans(i)} size="sm" fontSize="sm" colorScheme="red" leftIcon={<Icon as={AiOutlineDelete} fontSize="16" />}>
                                          Excluir
                                      </Button>

                                        )
                                      : (
                                      <IconButton colorScheme="red" aria-label="Edit Content" icon={<Icon as={AiOutlineDelete} size="30" />} onClick={() => setSelectedItemPlans(i)}/>
                                        )
                                  )} */}

                                  </Flex>

                                  </Tr>

                                  ))}

                              </Tbody>
                          </Table>

                          <Pagination
                              totalCountOfRegisters={totalPlans}
                              currentPage={pagePlans}
                              onPageChange={setPagePlans}
                          />
                  </>
                              </TabPanel>

                              <TabPanel>
                                  <>
                                  <Flex w="100%" justifyContent="flex-end">
                                    <Button
                                              onClick={handleNewPayment}
                                              size="sm"
                                              fontSize="sm"
                                              mr="2"
                                              bg="green.teal"
                                              colorScheme="wine"
                                              leftIcon={
                                                <Icon as={RiEye2Fill} fontSize="16" />
                                              }
                                            >
                                            Adicionar
                                          </Button>
                                  </Flex>
                                      <Table colorScheme="blue">
                                          <Thead>
                                              <Tr>
                                                  <Th>Tipo</Th>
                                                  { isWideScreen && <Th>Data</Th> }
                                                  <Th>Valor</Th>
                                                  { isWideScreen && <Th>Status</Th> }

                                                  <Th w="8"></Th>
                                              </Tr>
                                          </Thead>
                                          <Tbody>

                                              { dataClasses.map(i => (
                                                  <Tr key={i.index}>
                                                      <Td>
                                                          <Box><Text fontWeight="bold" color="blue.500">{i?.date} - {i?.hour?.hour}</Text></Box>
                                                      </Td>
                                                      { isWideScreen && (
                                                      <Td>
                                                          <Text fontWeight="bold">{i?.hour?.name} - {i?.hour?.teacher}</Text>
                                                      </Td>
                                                      )}

                                                  { isWideScreen && (
                                                      <Td>
                                                          <Text fontWeight="bold">{i?.status_class.name}</Text>
                                                      </Td>
                                                  )}

                                                  <Td>
                                                      <Flex>
                                                          {isWideScreen
                                                            ? (

                                                              <Button as="button" onClick={() => setSelectedItemClasses(i)} size="sm" fontSize="sm" colorScheme="red" leftIcon={<Icon as={AiOutlineDelete} fontSize="16" />}>
                                                                  Excluir
                                                              </Button>

                                                              )
                                                            : (
                                                              <IconButton colorScheme="red" aria-label="Edit Content" icon={<Icon as={AiOutlineDelete} size="30" />} onClick={() => setSelectedItemClasses(i)}/>
                                                              )}
                                                      </Flex>
                                                  </Td>
                                              </Tr>

                                              ))}
                                          </Tbody>
                                      </Table>

                                      <Pagination
                                          totalCountOfRegisters={totalClasses}
                                          currentPage={pageClasses}
                                          onPageChange={setPageClasses}
                                      />
                                  </>
                              </TabPanel>

                          </TabPanels>
                      </Tabs>
                  </>
              )}

              </Box>
          </Flex>
      </Box>
  )
}

export { ScheduleDetail }
