import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

interface Props {
    title: string
}

const TablePlaceholder : FC<Props> = ({ title }) => {
    return (
        <Flex flex={1} alignItems="center" justifyContent="center" padding={50}>
            <Text>{title}</Text>
        </Flex>
    )
}

export { TablePlaceholder }