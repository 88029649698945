import { Flex, Input, Icon } from '@chakra-ui/react'
import { RiSearch2Line } from 'react-icons/ri'

interface Props {
  placeholder?: string
  onChangeFunction?: (data?: any) => any
}
export function SearchBox ({ placeholder, onChangeFunction }: Props) {
  return (
    <Flex
      as="label"
      flex="1"
      py="4"
      px="8"
      ml="6"
      maxWidth={400}
      alignSelf="center"
      color="white"
      position="relative"
      bg="green.dark"
      borderRadius="full"
    >
      <Input
        name="search"
        color="white"
        variant="unstyled"
        px="4"
        mr="4"
        placeholder={placeholder}
        _placeholder={{ color: 'white' }}
        onChange={onChangeFunction}
      />

      <Icon as={RiSearch2Line} fontSize="20" />
    </Flex>
  )
}
