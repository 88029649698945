/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  Text,
  useToast
} from '@chakra-ui/react'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  DragAndDropExportedProps
} from '../../Components/Form/DragAndDrop'
import { Input } from '../../Components/Form/Input'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'

import 'react-quill/dist/quill.snow.css'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { InputModalSearch } from '../../Components/InputModalSearch'
import { Loader } from '../../Components/Loader'
import { ConciergeItemModal } from '../../Components/Modals/ConciergeItemModal'
import {
  IHouseProps,
  IItemProps,
  useConcierge
} from '../../Hooks/Concierge'
import IFilesProps from '../../interfaces/IFilesProps'
import { normalizeDate } from '../../utils/normalizeDate'
import { isBefore, isValid } from 'date-fns'
import { removeNonNumeric } from '../../utils/removeNonNumeric'

interface OptionsProps {
  label: string
  value: string
}

interface OptionProps {
  id: string
  name?: string
  help?: string
}

interface RulesOptions {
  id: string
  description: string
}

interface ItemProps {
  id: string
  name?: string
  multi?: 'YES' | 'NO' | undefined // utilizado quando o tipo for descritivo
  type?: 'NUMERIC' | 'DESCRIPTION' | 'RANGE' | undefined
  description?: string
  min?: number | undefined // utilizado quando o tipo for numérico
  max?: number | undefined // utilizado quando o tipo for numérico
  options?: OptionProps[]
  availability: IHouseProps[]
}

interface ServiceProps {
  name: string
  items: ItemProps[]
  rules?: RulesOptions[]
  files: IFilesProps[]
}

const typeOptions = [
  {
    label: 'Selecione',
    value: ''
  },
  {
    label: 'Para todos os usuários da plataforma',
    value: 'ALL'
  },
  {
    label: 'Para um usuário em específico',
    value: 'USER'
  },
  {
    label: 'Para sócios de uma casa',
    value: 'HOUSE'
  }
]

export enum TDocumentType {
  ALL = 'ALL',
  USER = 'USER',
  HOUSE = 'HOUSE'
}

const ScheduleCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [tmpName, setTmpName] = useState('')
  const [initialCover, setInitialCover] = useState(undefined)
  const params = useParams()
  const [playlist, setPlaylist] = useState<any>({})
  const [serviceData, setServiceData] = useState<ServiceProps>(
    {} as ServiceProps
  )
  const [items, setItems] = useState<ItemProps[]>([])
  const [rules, setRules] = useState<RulesOptions[]>([])
  const [options, setOptions] = useState<OptionProps[]>([])
  const [postCover, setPostCover] = useState<undefined | string>(undefined)
  const [files, setFiles] = useState<IFilesProps[]>()
  const toast = useToast()
  const CoverRef = useRef<DragAndDropExportedProps>(null)
  const [showConciergeItemModal, setShowConciergeItemModal] = useState({
    show: false,
    item: ''
  })
  const [houses, setHouses] = useState<IHouseProps[]>([])
  const { updateConcierge, concierge } = useConcierge()
  const [selectedItem, setSelectedItem] = useState<IItemProps>(
    {} as IItemProps
  )
  const [typeDocument, setTypeDocument] = useState<TDocumentType | undefined>(undefined)
  const [optionsUsers, setOptionsUsers] = useState<OptionsProps[]>([])
  const [selectedUser, setSelectedUser] = useState<any>({} as any)
  const [allUsers, setAllUsers] = useState<any[]>([])
  const [allHouses, setAllHouses] = useState<any[]>([])
  const [optionsHouses, setOptionsHouses] = useState<OptionsProps[]>([])
  const [selectedHouse, setSelectedHouse] = useState()
  const [properties, setProperties] = useState<OptionsProps[]>([])
  const [typeAppointmentSelected, setTypeAppointmentSelected] = useState<any>({} as any)
  const DragRef = useRef<DragAndDropExportedProps>(null)

  const optionsTypeAppointment = [
    {
      value: '',
      label: 'Selecionar Tipo de Reserva'
    },
    {
      value: '1',
      label: 'Reserva para uso pessoal'
    },
    {
      value: '2',
      label: 'Reserva para locação'
    }
  ]

  const navigate = useNavigate()

  const { register, handleSubmit, formState, setValue, watch, unregister, getValues } =
      useForm({
        // resolver: yupResolver(createFormSchema)
      })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      console.log('selectedHouse: ', selectedHouse)
      const fields = getValues()

      if (!values?.date_initial || !values?.date_final) {
        toast({
          title: 'Por favor, preencha a data de Check-in e Check-out',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      if (removeNonNumeric(values?.date_initial)?.length < 8) {
        toast({
          title: 'Date de check-in inválida',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      if (removeNonNumeric(values?.date_final)?.length < 8) {
        toast({
          title: 'Date de check-out inválida',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      if (!typeAppointmentSelected) {
        toast({
          title: 'Por favor, selecione o tipo de reserva',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      if (!selectedUser?.id) {
        toast({
          title: 'Por favor, selecione o usuário.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      if (!selectedHouse) {
        toast({
          title: 'Por favor, selecione a casa.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      const checkInIsValid = isValid(new Date(normalizeDate(values?.date_initial)))
      const checkOutIsValid = isValid(new Date(normalizeDate(values?.date_final)))
      console.log('checkInIsValid: ', checkInIsValid)
      console.log('normalizeDate: ', normalizeDate(values?.date_initial))

      if (!checkInIsValid) {
        toast({
          title: 'Data para check-in inválida',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      if (!checkOutIsValid) {
        toast({
          title: 'Data para check-out inválida',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      if (isBefore(new Date(normalizeDate(values?.date_final)), new Date(normalizeDate(values?.date_initial)))) {
        toast({
          title: 'A data de check-out não pode ser maior que a data de check-in',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      try {
        setLoading(true)

        const payload = {
          property_id: selectedHouse,
          date_initial: normalizeDate(values?.date_initial),
          date_final: normalizeDate(values?.date_final),
          type: typeAppointmentSelected
        }

        console.log('payload: ', payload)

        await api.post('/schedules', payload, {
          params: {
            user_id: selectedUser?.id
          }
        })

        toast({
          title: 'Agendamento Adicionado',
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })

        navigate('/schedule')
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      } finally {
        setLoading(false)
      }
    },
    [toast, getValues, selectedUser, navigate, typeAppointmentSelected, selectedHouse]
  )

  //   const loadItem = useCallback(async () => {
  //     try {
  //       const resp = await api.get(`/concierges/${params?.id}`)
  //       const data: ServiceProps = resp.data
  //       console.log('loadItem: ', resp.data)

  //       setValue('name', data.name)
  //       setServiceData(data)
  //       setItems(data?.items)

  //       setPostCover(
  //         data?.files?.find((i: any) => i.type === 'concierge-cover')?.link
  //       )
  //       setFiles(data.files)

  //       console.log('data.files: ', data.files)
  //       data?.items?.forEach((item) => {
  //         setValue(`type-item-${item.id}`, item.type)
  //         setValue(`multi-item-${item.id}`, item.multi)
  //         setValue(`min-item-${item.id}`, item.min)
  //         setValue(`max-item-${item.id}`, item.max)
  //         setValue(`description-item-${item.id}`, item.description)

  //         item.options?.forEach((option) => {
  //           setValue(`name-option-${option.id}-item-${item.id}`, option.name)
  //           setValue(`help-option-${option.id}-item-${item.id}`, option.help)
  //         })
  //       })

  //       if (data?.rules?.length) {
  //         setRules(data?.rules)
  //         data?.rules?.map((rule) =>
  //           setValue(`description-rule-${rule.id}`, rule.description)
  //         )
  //       }
  //     } catch (error) {
  //       console.log('error edit: ', error)
  //       toast({
  //         title: error.response.data.message,
  //         position: 'top',
  //         isClosable: true,
  //         status: 'error',
  //         variant: 'solid'
  //       })
  //     }
  //   }, [toast, setValue, params])

  //   useEffect(() => {
  //     if (params.id) {
  //       loadItem()
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [params])

  //   const loadHouses = useCallback(async () => {
  //     setLoading(true)
  //     try {
  //       const { data } = await api.get('/properties', {
  //         params: {
  //           pag: 1,
  //           active: PROPERTIES_STATUS.ACTIVE,
  //           all: true
  //         }
  //       })
  //       console.log('houses: ', data)

  //       const normalize: OptionsProps[] = data.data.map(
  //         (house: IPropertiesProps) => {
  //           return {
  //             value: house.id,
  //             label: house.name
  //           }
  //         }
  //       )

  //       setAllHouses(data.data)
  //       setOptionsHouses(normalize)
  //     } catch (error) {
  //       console.log('erro ao pegar lista: ', error)
  //     } finally {
  //       setLoading(false)
  //     }
  //   }, [])

  //   const handleInitialHouses = useCallback(() => {
  //     const currentConcierge: IConciergeProps = {
  //       info: {
  //         availability: houses,
  //         need_time: 'YES',
  //         prices: houses.map((i: IHouseProps) => {
  //           return {
  //             house: {
  //               id: i.id,
  //               name: i.name
  //             },
  //             price: 0
  //           }
  //         })
  //       }
  //     }

  //     updateConcierge(currentConcierge)
  //   }, [updateConcierge, houses])

  //   useEffect(() => {
  //     if (houses.length) {
  //       handleInitialHouses()
  //     }
  //   }, [houses])

  //   useEffect(() => {
  //     loadHouses()
  //   }, [])

  const handleChangeName = useCallback(() => {
    const current = { ...concierge }

    if (current.info) {
      current.info.name = tmpName // Removed optional chaining here

      updateConcierge(current)
    }
  }, [updateConcierge, concierge, tmpName])

  useEffect(() => {
    handleChangeName()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpName])

  const handleChangeSelectHouse = (value: any): void => {
    console.log('handleChangeSelectHouse value: ', value?.target?.value)
    setSelectedHouse(value?.target?.value)
    // const val = value?.target?.value
    // setTypeDocument(val)

    // if (val === 'ALL') {
    //   setSelectedHouse(undefined)
    //   setSelectedUser(undefined)
    // }
  }

  const handleChangeTypeAppointment = (value: any): void => {
    console.log('handleChangeTypeAppointment value: ', value?.target?.value)
    setTypeAppointmentSelected(value?.target?.value)
    // const val = value?.target?.value
    // setTypeDocument(val)

    // if (val === 'ALL') {
    //   setSelectedHouse(undefined)
    //   setSelectedUser(undefined)
    // }
  }

  const handleSearchUsers = useCallback(async (e: string) => {
    try {
      const resp = await api.get('/users', {
        params: {
          pag: 1,
          all: true,
          name: e
        }
      })
      const data: any[] = resp.data.data

      const options = data.map((i: any) => ({
        label: i.name,
        value: i.id
      }))

      setOptionsUsers(options)
    } catch (error) {
      console.log('error gymm: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const loadQuotas = useCallback(async (user_id: string) => {
    setLoading(true)
    try {
      const { data } = await api.get('/quotas', {
        params: {
          pag: 1,
          user_id,
          all: true
        }
      })

      const normalize: OptionsProps[] = data.data.map(
        (i: any) => {
          return {
            label: i?.property?.name,
            value: i?.property?.id
          }
        }
      )

      if (!normalize?.length) {
        toast({
          title: 'Este usuário não possui nenhuma cota de casa atrelada.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      setProperties([{
        label: 'Selecionar Casa',
        value: ''
      }, ...normalize])

    //   setAllHouses(data.data)
    //   setOptionsHouses(normalize)
    } catch (error) {
      console.log('erro ao pegar lista: ', error)
    } finally {
      setLoading(false)
    }
  }, [toast])

  const handleSelecUsers = useCallback(async (user_id: string) => {
    const findUsers = allUsers.find(i => i.id === user_id)

    if (findUsers != null) {
      setSelectedUser(findUsers)
      setValue('user_id', findUsers?.cnpj || '')
      //   setSelectedHouse(undefined)
      loadQuotas(findUsers?.id)
    }
  }, [allUsers, setValue, loadQuotas])

  const loadUsers = useCallback(async () => {
    try {
      const resp = await api.get('/users', {
        params: {
          pag: 1,
          all: true
        }
      })
      const data: any[] = resp.data.data

      const options = data.map((i: any) => ({
        label: i.name,
        value: i.id
      }))

      setAllUsers(resp.data.data)
      setOptionsUsers(options)
    } catch (error) {
      console.log('error gymm: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  useEffect(() => {
    void loadUsers()
    // void loadHouses()
  }, [])

  return (
      <Box>
        <Header />

        {loading && <Loader />}
        <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
          <Sidebar />

          {showConciergeItemModal.show && (
            <ConciergeItemModal
              houses={concierge?.info?.availability ?? []}
              onClose={() => setShowConciergeItemModal({ item: '', show: false })}
            />
          )}

          <Box
            as="form"
            flex="1"
            borderRadius={8}
            bg="white"
            p="8"
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            <Heading size="lg" fontWeight="normal" color="green.dark">
              Criar novo Agendamento
            </Heading>

            <Box mt='30px'>

            <SimpleGrid spacing={['9', '9']} w="100%" minChildWidth="240px" mt='30px'>
                    <InputMaskCustom
                        type="text"
                        label="Data de Check-in"
                        errors={errors.date_initial}
                        {...register('date_initial')}
                        placeholder="Digite data de Check-in"
                        mask="99/99/9999"
                    />

                    <InputMaskCustom
                        type="text"
                        label="Data de Check-in"
                        errors={errors.date_final}
                        {...register('date_final')}
                        placeholder="Digite data de Check-Out"
                        mask="99/99/9999"
                    />

<Select
                    options={optionsTypeAppointment}
                    label='Tipo de Reserva'
                    {...register('type')}
                    style={{ backgroundColor: '#fff' }}
                    onChange={handleChangeTypeAppointment}
                    // extraFunction={handleChangeSelectHouse}
                    // defaultValue={item?.type}
                />

                </SimpleGrid>

                <SimpleGrid
                   spacing={['6', '9']}
                   w="100%"
                   minChildWidth="240px"
                   mt='30px'
                 >

                <InputModalSearch title="Usuário" searchFunction={handleSearchUsers} data={optionsUsers} extraFunction={handleSelecUsers} placeholderButton={selectedUser?.id ? 'Trocar Usuário' : 'Selecionar Usuário'} />

                <Input {...register('user_id')} value={selectedUser?.id} isReadOnly visibility="hidden" />

                {selectedUser?.id && (
                  <>
                  <Heading size="md" fontWeight="normal" color="green.dark">
                    Cliente:
                    <Text size="sm" fontWeight="bold" fontSize="16" color="green.dark">{selectedUser?.name}</Text>
                  </Heading>

                  </>
                )}
                </SimpleGrid>

                {selectedUser?.id && (
                    <SimpleGrid
                        spacing={['6', '9']}
                        w="100%"
                        minChildWidth="240px"
                        mt='30px'
                    >

                    <Select
                        options={properties}
                        label='Casa'
                        {...register('property_id')}
                        style={{ backgroundColor: '#fff' }}
                        onChange={handleChangeSelectHouse}
                        // extraFunction={handleChangeSelectHouse}
                        // defaultValue={item?.type}
                    />

                    </SimpleGrid>
                )}

            </Box>

            <Flex mt={['6', '8']} justify="flex-end">
              <HStack>
                {loading
                  ? (
                  <Spinner />
                    )
                  : (
                  <>
                    <Button
                      as="a"
                      href="/concierge"
                      bg="green.teal"
                      _hover={{ bgColor: 'gray.500' }}
                      color="white"
                      cursor="pointer"
                    >
                      Cancelar
                    </Button>

                    <Button
                      type="submit"
                      bg="green.dark"
                      _hover={{ bgColor: 'green.teal' }}
                      color="green.light"
                      isLoading={formState.isSubmitting}
                    >
                      Salvar
                    </Button>
                  </>
                    )}
              </HStack>
            </Flex>
          </Box>
        </Flex>
      </Box>
  )
}

export { ScheduleCreate }
