import { removeNonNumeric } from './removeNonNumeric'

const getCurrencyValue = (value: string): any => {
  console.log('getCurrencyValue value: ', value)
  // Remove caracteres não numéricos
  const valorNumerico = value.replace(/[^0-9,-]/g, '')

  // Substitui vírgulas por pontos para formatos decimais
  const valorFormatado = valorNumerico.replace(',', '.')

  // Converte a string para número
  const numero = parseFloat(valorFormatado)

  // Verifica se o número é válido
  if (isNaN(numero)) {
    return 0
  }

  // Retorna o número
  return numero.toFixed(2)
}

export { getCurrencyValue }
