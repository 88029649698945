import React, { FC, useCallback, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Input,
  Flex,
  Text
} from '@chakra-ui/react'

interface IColorPickerProps {
  onSubmit: (color: string) => void
  color?: string
}

const ColorPicker: FC<IColorPickerProps> = ({ onSubmit, color }) => {
  const onChange = useCallback((colorSelected: string) => {
    onSubmit(colorSelected)
  }, [onSubmit])

  return (
    <Box>
      <Popover>
        <PopoverTrigger>
          <Flex w='100%' alignItems='center' justifyContent='space-between'>
            <Button bgColor='green.dark' color="white" _hover={{ opacity: 0.8 }}>
              Selecione a Cor
            </Button>

            {color && <Flex alignItems='center' gap='20px'>
              <Text fontSize='19px'>Cor Selecionada:</Text>
              <Box width='25px' height='25px' backgroundColor={color} borderRadius='100%' />
            </Flex>}

          </Flex>

        </PopoverTrigger>
        <PopoverContent width="auto">
          <PopoverArrow />
          <PopoverBody>
            <HexColorPicker color={color} onChange={onChange} />
            <Input
              mt={2}
              value={color}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Cor em HEX"
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export default ColorPicker
