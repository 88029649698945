import { Stack } from '@chakra-ui/react'
import { BsNewspaper } from 'react-icons/bs'
import { FiUsers } from 'react-icons/fi'
import { MdOutlineBusinessCenter } from 'react-icons/md'
import {
  RiContactsLine,
  RiDashboardLine
} from 'react-icons/ri'
import { Can } from '../Can'
import { NavLink } from './NavLink'
import { NavSection } from './NavSection'

export function NavSidebar () {
  return (
    <Stack spacing="12" align="flex-start" mb="40">
      {/* <NavSection title="GERAL">
        <Can permissions={['all.list']}>
          <NavLink href="/dashboard" icon={RiDashboardLine}>
            Dashboard
          </NavLink>
        </Can>

      </NavSection> */}

      {/* <Can permissions={['all.list']}>
        <NavSection title="HOSPITALIDADE">

        <NavLink href="/orders" icon={BsNewspaper}>
            Pedidos
          </NavLink>

        <Can permissions={['all.list']}>
          <NavLink href="/services" icon={MdOutlineBusinessCenter}>
            Serviços
          </NavLink>
        </Can>

        <Can permissions={['all.list']}>
          <NavLink href="/collaborators" icon={FiUsers}>
            Colaboradores
          </NavLink>
        </Can>

        <Can permissions={['all.list']}>
          <NavLink href="/clients" icon={FiUsers}>
            Clientes
          </NavLink>
        </Can>

        </NavSection>
      </Can> */}

      <Can permissions={['all.list']}>
          <NavSection title="SITE">
            {/* <Can permissions={['collaborators.list', 'collaborators.create', 'collaborators.all']}> */}
              {/* <NavLink href="/collaborators" icon={RiContactsLine}>
                Colaboradores
              </NavLink> */}
            {/* </Can> */}

            <NavLink href="/testimonials" icon={MdOutlineBusinessCenter}>
            Depoimentos
          </NavLink>

            <NavLink href="/midia" icon={BsNewspaper}>
            Mídia
          </NavLink>

          </NavSection>

          <NavSection title="BLOG">

          <NavLink href="/blog/categories" icon={MdOutlineBusinessCenter}>
            Categorias
          </NavLink>

          <NavLink href="/blog/tags" icon={MdOutlineBusinessCenter}>
            Tags
          </NavLink>

          <NavLink href="/images/bank" icon={BsNewspaper}>
            Banco de Imagens
          </NavLink>

          <NavLink href="/blog/posts" icon={BsNewspaper}>
            Posts
          </NavLink>

        </NavSection>

        <NavSection title="APP">
          <NavLink href="/properties" icon={BsNewspaper}>
            Casas
          </NavLink>

          <NavLink href="/concierge" icon={BsNewspaper}>
            Concierge
          </NavLink>

          <NavLink href="/users" icon={BsNewspaper}>
            Clientes
          </NavLink>

          <NavLink href="/documents" icon={BsNewspaper}>
            Documentos
          </NavLink>

          <NavLink href="/schedule" icon={BsNewspaper}>
            Agenda
          </NavLink>

          <NavLink href="/special-dates" icon={BsNewspaper}>
            Datas Especiais
          </NavLink>
        </NavSection>

        <NavSection title="FINANCEIRO">
          <NavLink href="/financial-houses" icon={BsNewspaper}>
            Financeiro das Casas
          </NavLink>

          <NavLink href="/financial-users" icon={BsNewspaper}>
            Financeiro dos Usuários
          </NavLink>

        </NavSection>
      </Can>
    </Stack>
  )
}
